import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Input from "../../../ui/components/Input/Input";
import {useTranslation} from "react-i18next";
import cl from './AccountForm.module.css';
import AccountFormUsersGrid from "./components/AccountFormUsersGrid/AccountFormUsersGrid";

const AccountForm = ({
                         number,
                         homeOwner,
                         section,
                         subSection,
                         street,
                         apartment,
                         entrance,
                         building,
                         isGroup,
                         isCommercial,
                         isBlocked,
                         users,
                         onUserDelete,
                         crmId
                     }) => {
    const {t} = useTranslation();

    return (
        <Container fluid>
            <Row>
                <Col>
                    <div className='input-container'>
                        <label className='label'>
                            {t("account.form.number")}
                        </label>
                        <Input name='number'
                               type='text'
                               placeholder={t("account.form.number")}
                               disabled={true}
                               autoComplete='off'
                               value={number}/>
                    </div>

                    <div className='input-container'>
                        <label className='label'>
                            {t("account.form.crm-id")}
                        </label>
                        <Input name='crmId'
                               type='text'
                               placeholder={t("account.form.crm-id")}
                               disabled={true}
                               autoComplete='off'
                               value={crmId}/>
                    </div>

                    <div className='input-container checkbox'>
                        <Input name='isBlocked'
                               type='checkbox'
                               disabled={true}
                               checked={isBlocked}/>
                        <label className='label'>
                            {t("account.form.blocked")}
                        </label>
                    </div>

                    <div className='input-container checkbox'>
                        <Input name='isGroup'
                               type='checkbox'
                               disabled={true}
                               checked={isGroup}/>
                        <label className='label'>
                            {t("account.form.is-group")}
                        </label>
                    </div>

                    <div className='input-container checkbox'>
                        <Input name='isCommercial'
                               type='checkbox'
                               disabled={true}
                               checked={isCommercial}/>
                        <label className='label'>
                            {t("account.form.is-commercial")}
                        </label>
                    </div>
                </Col>
                <Col>
                    <div className={cl.header}>
                        {t("account.form.address")}
                    </div>

                    <div className='input-container'>
                        <label className='label'>
                            {t("account.form.home-owner")}
                        </label>
                        <Input name='homeOwner'
                               type='text'
                               placeholder={t("account.form.home-owner")}
                               disabled={true}
                               autoComplete='off'
                               value={homeOwner}/>
                    </div>

                    <div className='input-container'>
                        <label className='label'>
                            {t("account.form.section")}
                        </label>
                        <Input name='section'
                               type='text'
                               placeholder={t("account.form.section")}
                               disabled={true}
                               autoComplete='off'
                               value={section}/>
                    </div>

                    <div className='input-container'>
                        <label className='label'>
                            {t("account.form.sub-section")}
                        </label>
                        <Input name='subSection'
                               type='text'
                               placeholder={t("account.form.sub-section")}
                               disabled={true}
                               autoComplete='off'
                               value={subSection}/>
                    </div>

                    <div className='input-container'>
                        <label className='label'>
                            {t("account.form.street")}
                        </label>
                        <Input name='street'
                               type='text'
                               placeholder={t("account.form.street")}
                               disabled={true}
                               autoComplete='off'
                               value={street}/>
                    </div>

                    <div className='input-container'>
                        <label className='label'>
                            {t("account.form.building")}
                        </label>
                        <Input name='building'
                               type='text'
                               placeholder={t("account.form.building")}
                               disabled={true}
                               autoComplete='off'
                               value={building}/>
                    </div>

                    <div className='input-container'>
                        <label className='label'>
                            {t("account.form.entrance")}
                        </label>
                        <Input name='entrance'
                               type='text'
                               placeholder={t("account.form.entrance")}
                               disabled={true}
                               autoComplete='off'
                               value={entrance}/>
                    </div>

                    <div className='input-container'>
                        <label className='label'>
                            {t("account.form.apartment")}
                        </label>
                        <Input name='apartment'
                               type='text'
                               placeholder={t("account.form.apartment")}
                               disabled={true}
                               autoComplete='off'
                               value={apartment}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col style={{margin: '10px 0'}}>
                    <div className={cl.header}>
                        {t("account.form.users-label")}
                    </div>
                    <AccountFormUsersGrid users={users} onUserDelete={onUserDelete}/>
                </Col>
            </Row>
        </Container>
    );
};

export default AccountForm;