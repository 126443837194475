import logo from '../../../assets/images/logo.png';
import cl from './HomePage.module.css';

const HomePage = () => {
    return (
        <div className={cl.container}>
            <img src={logo} alt='logo'/>
        </div>
    );
}

export default HomePage;
