import React from 'react';
import Dialog from "../../../../ui/components/Dialog/Dialog";
import UserSyncForm from "./UserSyncForm/UserSyncForm";

const UserSyncDialog = ({open, close, onSyncSuccess}) => {
    return (
        <Dialog width='400px'
                height='220px'
                open={open}>
            <UserSyncForm close={close} onSyncSuccess={onSyncSuccess}/>
        </Dialog>
    );
};

export default UserSyncDialog;