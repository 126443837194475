import React from 'react';
import {useTranslation} from "react-i18next";
import DropdownGridFilter from "../../../../ui/components/Grid/filters/DropdownGridFilter";

const PostCategoryTypeFilter = ({onFilterChange, ...rest}) => {
    const {t} = useTranslation();
    const postCategoryTypeFilterData = [
        {id: '0', text: t("postCategory.postCategoryType.0")},
        {id: '1', text: t("postCategory.postCategoryType.1")},
    ];

    return <DropdownGridFilter onChange={onFilterChange} data={postCategoryTypeFilterData}  {...rest}/>
};

export default PostCategoryTypeFilter;