import React, {useEffect, useState} from 'react';
import TargetHeader from "./components/TargetHeader/TargetHeader";
import cl from './Target.module.css';
import TargetList from "./components/TargetList/TargetList";
import {v4 as uuidv4} from 'uuid';
import TargetDialog from "./components/TargetDialog/TargetDialog";
import {checkIfSimilarTargetExists, getTargetLabel} from "./utils";
import {toast} from "react-toastify";

const initialValue = {
    id: null,
    dbId: 0,
    buildingType: null,
    label: '',
    section: {
        key: null,
        value: null
    },
    homeOwner: {
        key: null,
        value: null
    },
    subSection: {
        key: null,
        value: null
    },
    street: {
        key: null,
        value: null
    },
    building: {
        key: null,
        value: null
    }
};

const Target = ({targets, disabled, change}) => {
    const [showDialog, setShowDialog] = useState(false);
    const [dialogItem, setDialogItem] = useState(null);
    const [items, setItems] = useState([]);

    useEffect(() => {
        // debugger;
        //   if (targets && targets.length > 0) {
        setItems(targets == null ? [] : targets);
        // }
    }, [targets]);

    const removeTarget = (id) => {
        if (!disabled) {
            if (change) {
                change([...items.filter(item => item.id !== id)]);
            } else {
                setItems(prev => {
                    let data = [...prev.filter(item => item.id !== id)];

                    if (change) {
                        change(data);
                    }

                    return data;
                });
            }
        }
    }

    const editTarget = (id) => {
        if (!disabled) {
            let item = items.find(item => item.id === id);

            setDialogItem(item ? item : {
                ...initialValue,
                id: uuidv4()
            });

            setShowDialog(prev => !prev);
        }
    }

    const closeDialog = () => {
        if (showDialog) {
            setDialogItem(null);

            setShowDialog(prev => !prev);
        }
    }

    const saveTarget = (ev) => {
        if (ev) {

            if (checkIfSimilarTargetExists(items, ev)) {
                toast.error('Item with similar data already exists');
            } else {

                if (change) {
                    if (ev.homeOwner !== null &&  ev.homeOwner.length > 0 /*&& target.homeOwner.key !== null && target.homeOwner.value !== null*/) {
                        let array = items;
                        ev.homeOwner.forEach (evKey  => {
                            const item = {
                                ...ev,
                                homeOwner:{key: evKey.id, value:evKey.label},
                                label: evKey.label,
                                id: uuidv4()
                            };

                            array = array.find(item => item.id === ev.id)
                                ? [item, ...array.filter(item => item.id !== ev.id)]
                                : [item, ...array]

                        })

                        if (change) {
                            change(array);
                        }


                    } else {
                        if (ev.subSection !== null &&  ev.subSection.length > 0 /*&& target.homeOwner.key !== null && target.homeOwner.value !== null*/) {
                            let array = items;
                            ev.subSection.forEach (evKey  => {
                                const item = {
                                    ...ev,
                                    subSection:{key: evKey.id, value:evKey.label},
                                    label: evKey.label,
                                    id: uuidv4()
                                };

                                array = array.find(item => item.id === ev.id)
                                    ? [item, ...array.filter(item => item.id !== ev.id)]
                                    : [item, ...array]

                            })

                            if (change) {
                                change(array);
                            }


                        }else {

                            const item = {
                                ...ev,
                                label: getTargetLabel(ev)
                            };

                            change(items.find(item => item.id === ev.id)
                                ? [item, ...items.filter(item => item.id !== ev.id)]
                                : [item, ...items]);
                        }
                    }
                } else {
                    if (ev.homeOwner !== null &&  ev.homeOwner.length > 0 /*&& target.homeOwner.key !== null && target.homeOwner.value !== null*/) {
                        let array = items;
                        ev.homeOwner.forEach (evKey  => {
                            const item = {
                                ...ev,
                                label: evKey.label,
                                homeOwner:{key: evKey.id, value:evKey.label},
                                id: uuidv4()
                            };

                            array = array.find(item => item.id === ev.id)
                                ? [item, ...array.filter(item => item.id !== ev.id)]
                                : [item, ...array]

                        })




                        if (change) {
                            change(array);
                        }

                        setItems(array);

                    } else {

                        if (ev.subSection !== null &&  ev.subSection.length > 0 /*&& target.homeOwner.key !== null && target.homeOwner.value !== null*/) {
                            let array = items;
                            ev.subSection.forEach (evKey  => {
                                const item = {
                                    ...ev,
                                    label: evKey.label,
                                    subSection:{key: evKey.id, value:evKey.label},
                                    id: uuidv4()
                                };

                                array = array.find(item => item.id === ev.id)
                                    ? [item, ...array.filter(item => item.id !== ev.id)]
                                    : [item, ...array]

                            })




                            if (change) {
                                change(array);
                            }

                            setItems(array);

                        } else {
                            setItems(prev => {
                                const item = {
                                    ...ev,
                                    label: getTargetLabel(ev)
                                };

                                let arr = prev.find(item => item.id === ev.id)
                                    ? [item, ...prev.filter(item => item.id !== ev.id)]
                                    : [item, ...prev];

                                if (change) {
                                    change(arr);
                                }

                                return arr;
                            });
                        }
                    }
                }
            }

            setShowDialog(prev => !prev);
        }
    }

    return (
        <div className={cl.targetContainer}>
            <TargetHeader onAddButtonClick={editTarget}/>
            <TargetList items={items}
                        onRemoveButtonClick={removeTarget}
                        onEditButtonClick={editTarget}/>
            {dialogItem &&
                <TargetDialog open={showDialog}
                              close={closeDialog}
                              save={saveTarget}
                              onOverlayClick={closeDialog}
                              target={dialogItem}/>
            }
        </div>
    );
};

export default Target;
