import React from 'react';
import cl from './ActiveUsersChartSelectorSection.module.css';
import {useTranslation} from "react-i18next";
import {DateRangePickerComponent} from "@syncfusion/ej2-react-calendars";
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExport} from "@fortawesome/free-solid-svg-icons";

const ActiveUsersChartSelectorSection = ({isLoading, startDate, endDate, onDateChange, onActivityCodeChange, onExportBtnClick}) => {
    const {t} = useTranslation();

    const activityData = [
        {value: "all", text: t("analytics.activityCodes.all")},
        {value: "banner", text: t("analytics.activityCodes.banner")},
        {value: "vote", text: t("analytics.activityCodes.vote")},
        {value: "payment", text: t("analytics.activityCodes.payment")},
        {value: "appeal", text: t("analytics.activityCodes.incident")},
        {value: "post", text: t("analytics.activityCodes.news")},
        {value: "page", text: t("analytics.activityCodes.views")},
    ]

    return (
        <div className={cl.selectorsContainer}>
            <div>
                <button className={cl.exportBtn} onClick={onExportBtnClick}>
                    <span>
                        <FontAwesomeIcon icon={faFileExport}/>
                    </span>
                    <span>
                        {t("btn.export")}
                    </span>
                </button>
            </div>
            <div>
                <div>
                    <div className={cl.selectorLabel}>
                        {t("analytics.grid.period")}
                    </div>
                    <div>
                        <DateRangePickerComponent id='chart-date-range-picker'
                                                  width='300px'
                                                  change={onDateChange}
                                                  startDate={startDate}
                                                  endDate={endDate}
                                                  format='dd.MM.yyyy'
                                                  enabled={!isLoading}/>
                    </div>
                </div>
                <div>
                    <div className={cl.selectorLabel}>
                        {t("analytics.grid.activity-type")}
                    </div>
                    <div>
                        <DropDownListComponent id='chart-activity-code-picker'
                                               popupHeight='270px'
                                               dataSource={activityData}
                                               fields={{text: 'text', value: 'value'}}
                                               showClearButton={false}
                                               width='300px'
                                               change={onActivityCodeChange}
                                               index={0}
                                               enabled={!isLoading}>

                        </DropDownListComponent>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActiveUsersChartSelectorSection;