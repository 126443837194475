import React from 'react';
import Dialog from "../../../../ui/components/Dialog/Dialog";
import AccountSyncForm from "./AccountSyncForm/AccountSyncForm";

const AccountSyncDialog = ({open, close, onSyncSuccess}) => {
    return (
        <Dialog width='400px'
                height='220px'
                open={open}>
            <AccountSyncForm close={close} onSyncSuccess={onSyncSuccess}/>
        </Dialog>
    );
};

export default AccountSyncDialog;