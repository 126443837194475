import React from 'react';
import Dialog from "../../../../ui/components/Dialog/Dialog";
import UsersExportForm from "./UsersExportForm/UsersExportForm";

const UsersExportDialog = ({open, close}) => {
    return (
        <Dialog width='400px'
                height='220px'
                open={open}
                onOverlayClick={close}>
            <UsersExportForm close={close}/>
        </Dialog>
    );
};

export default UsersExportDialog;