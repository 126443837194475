import React from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Col, Container, Row} from "react-bootstrap";
import Input from "../../../ui/components/Input/Input";
import cl from './PollForm.module.css';
import TextArea from "../../../ui/components/TextArea/TextArea";
import Target from "../../../ui/components/Target/Target";
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";
import {createPortal} from "react-dom";
import PollQuestionList from "./components/PollQuestionList/PollQuestionList";
import {validatePollQuestionList} from "./components/PollQuestionList/utils";

const PollForm = ({
                      id,
                      forCommercialProperty,
                      forResidents,
                      hasIncidents,
                      hasServices,
                      hasPayments,
                      isBlocked,
                      isPriority,
                      name,
                      period,
                      pointsQuantity,
                      popupContentText,
                      popupHeaderText,
                      pushNotificationText,
                      codeName,
                      targets,
                      pollQuestions,
                      onSubmit
                  }) => {
    const {t} = useTranslation();
    const [isRendered, setIsRendered] = React.useState(false)

    React.useEffect(() => {
        setIsRendered(true)
    }, []);

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name,
            codeName: codeName,
            forCommercialProperty: forCommercialProperty,
            forResidents: forResidents,
            hasIncidents: hasIncidents,
            hasServices: hasServices,
            hasPayments: hasPayments,
            isBlocked: isBlocked,
            isPriority: isPriority,
            period: period,
            pointsQuantity: pointsQuantity,
            popupContentText: popupContentText,
            popupHeaderText: popupHeaderText,
            pushNotificationText: pushNotificationText,
            pollQuestions: pollQuestions,
            targets: targets,
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("poll.form.name"))),
            codeName: Yup.string()
                .test(
                    'requiredWhenNew',
                    t("errors.required-error").replace('{0}', t("poll.form.code-name")),
                    function (item) {
                        return !(item === null || item === undefined);
                    }
                ),
            period: Yup.number()
                .nullable()
                .transform((_, val) => val === Number(val) ? val : null)
                .required(t("errors.required-error").replace('{0}', t("poll.grid.period"))),
            popupHeaderText: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("poll.form.popup-header-text"))),
            popupContentText: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("poll.form.popup-text"))),
            pollQuestions: Yup.array()
                .min(1, t("poll.errors.question-length-error"))
                .max(10, t("poll.errors.question-length-error"))
                .nullable()
                .test(
                    'pollQuestionsValidations',
                    t('poll.errors.opinion-scale-required-error'),
                    function (items) {
                        return validatePollQuestionList(items, form.values.type);
                    }
                )
        }),
        onSubmit: onSubmit
    });

    const pollPeriods = [
        {id: 0, text: t("poll.poll-period.one-time")},
        {id: 1, text: t("poll.poll-period.day")},
        {id: 2, text: t("poll.poll-period.week")},
        {id: 3, text: t("poll.poll-period.month")},
    ];

    return (
        <Container fluid>
            <form id='poll-form' onSubmit={form.handleSubmit}>
                <Row>
                    <Col>
                        <div className='input-container'>
                            <label className='label required'>
                                {t("poll.form.name")}
                            </label>
                            <Input name='name'
                                   type='text'
                                   placeholder={t("poll.form.name")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.name}/>
                            {form.touched.name && form.errors.name ?
                                <div className='field-error'>{form.errors.name}</div> : null}
                        </div>

                        <div className='input-container'>
                            <label className='label required'>
                                {t("poll.form.code-name")}
                            </label>
                            <Input name='codeName'
                                   type='text'
                                   placeholder={t("poll.form.code-name")}
                                   disabled={form.isSubmitting || id > 0}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.codeName}/>
                            {form.touched.codeName && form.errors.codeName ?
                                <div className='field-error'>{form.errors.codeName}</div> : null}
                        </div>

                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label required'>
                                {t("poll.grid.period")}
                            </label>
                            <DropDownListComponent enabled={!form.isSubmitting}
                                                   id='poll-period'
                                                   popupHeight='250px'
                                                   placeholder={t("poll.grid.period")}
                                                   width='100%'
                                                   dataSource={pollPeriods}
                                                   value={form.values.period}
                                                   change={(ev) => form.setFieldValue('period', ev.itemData?.id)}
                                                   fields={{text: 'text', value: 'id'}}
                                                   showClearButton={true}>

                            </DropDownListComponent>
                            {form.touched.period && form.errors.period ?
                                <div className='field-error'>{form.errors.period}</div> : null}
                        </div>

                        <div className='input-container'>
                            <label className='label required'>
                                {t("poll.form.popup-header-text")}
                            </label>
                            <Input name='popupHeaderText'
                                   type='text'
                                   placeholder={t("poll.form.popup-header-text")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.popupHeaderText}/>
                            {form.touched.popupHeaderText && form.errors.popupHeaderText ?
                                <div className='field-error'>{form.errors.popupHeaderText}</div> : null}
                        </div>

                        <div style={{minHeight: '70px'}}>
                            <label className='label required'>
                                {t("poll.form.popup-text")}
                            </label>
                            <TextArea name='popupContentText'
                                      placeholder={t("poll.form.popup-text")}
                                      disabled={form.isSubmitting}
                                      onChange={form.handleChange}
                                      autoComplete='off'
                                      value={form.values.popupContentText}/>
                            {form.touched.popupContentText && form.errors.popupContentText ?
                                <div className='field-error'>{form.errors.popupContentText}</div> : null}
                        </div>

                        <div className='input-container'>
                            <label className='label'>
                                {t("poll.form.push-notification-text")}
                            </label>
                            <Input name='pushNotificationText'
                                   type='text'
                                   placeholder={t("poll.form.push-notification-text")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.pushNotificationText}/>
                            {form.touched.pushNotificationText && form.errors.pushNotificationText ?
                                <div className='field-error'>{form.errors.pushNotificationText}</div> : null}
                        </div>
                    </Col>
                    <Col>
                        <div className={cl.checkboxColContainer}>
                            <div>
                                <div className={`input-container ${cl.checkboxRow}`}>
                                    <Input name='isPriority'
                                           type='checkbox'
                                           disabled={form.isSubmitting}
                                           onChange={form.handleChange}
                                           checked={form.values.isPriority}/>
                                    <label className='label'>
                                        {t("poll.grid.is-priority")}
                                    </label>
                                </div>

                                <div className={`input-container ${cl.checkboxRow}`}>
                                    <Input name='forResidents'
                                           type='checkbox'
                                           disabled={form.isSubmitting}
                                           onChange={form.handleChange}
                                           checked={form.values.forResidents}/>
                                    <label className='label'>
                                        {t("poll.grid.for-residents")}
                                    </label>
                                </div>

                                <div className={`input-container ${cl.checkboxRow}`}>
                                    <Input name='hasIncidents'
                                           type='checkbox'
                                           disabled={form.isSubmitting}
                                           onChange={form.handleChange}
                                           checked={form.values.hasIncidents}/>
                                    <label className='label'>
                                        {t("poll.grid.has-incidents")}
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div className={`${cl.checkboxRow}`}>
                                </div>

                                <div className={`input-container ${cl.checkboxRow}`}>
                                    <Input name='forCommercialProperty'
                                           type='checkbox'
                                           disabled={form.isSubmitting}
                                           onChange={form.handleChange}
                                           checked={form.values.forCommercialProperty}/>
                                    <label className='label'>
                                        {t("poll.grid.for-commercial-property")}
                                    </label>
                                </div>

                                <div className={`input-container ${cl.checkboxRow}`}>
                                    <Input name='hasServices'
                                           type='checkbox'
                                           disabled={form.isSubmitting}
                                           onChange={form.handleChange}
                                           checked={form.values.hasServices}/>
                                    <label className='label'>
                                        {t("poll.grid.has-services")}
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div className={`input-container ${cl.checkboxRow}`}>
                                    <Input name='isBlocked'
                                           type='checkbox'
                                           disabled={form.isSubmitting}
                                           onChange={form.handleChange}
                                           checked={form.values.isBlocked}/>
                                    <label className='label'>
                                        {t("poll.grid.is-blocked")}
                                    </label>
                                </div>

                                <div className={`${cl.checkboxRow}`}>
                                </div>

                                <div className={`input-container checkbox ${cl.checkboxRow}`}>
                                    <Input name='hasPayments'
                                           type='checkbox'
                                           disabled={form.isSubmitting}
                                           onChange={form.handleChange}
                                           checked={form.values.hasPayments}/>
                                    <label className='label'>
                                        {t("poll.grid.has-payments")}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div id={'target-list-container'} style={{margin: '20px 0'}}>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{marginTop: '5px', marginBottom: '10px'}}>
                        {form.touched.pollQuestions && form.errors.pollQuestions && !Array.isArray(form.errors.pollQuestions)
                            ? <div className='field-error'>{form.errors.pollQuestions}</div>
                            : null}
                        <PollQuestionList disabled={form.isSubmitting}
                                          change={(items) => form.setFieldValue('pollQuestions', items)}
                                          items={form.values.pollQuestions}/>
                    </Col>
                </Row>
            </form>
            {isRendered && createPortal(
                <Target targets={form.values.targets}
                        disabled={form.isSubmitting}
                        change={(items) => form.setFieldValue('targets', items)}/>,
                document.getElementById('target-list-container')
            )}
        </Container>
    );
};

export default PollForm;