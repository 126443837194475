import React from 'react';
import cl from "../../../ActiveUsersChart/ChartSection/ActiveUsersChartSection.module.css";
import {
    AccumulationChartComponent,
    AccumulationDataLabel, AccumulationSeriesCollectionDirective,
    AccumulationSeriesDirective
} from "@syncfusion/ej2-react-charts";
import {Inject} from "@syncfusion/ej2-react-grids";

const UsersRegistrationsPieChart = ({data, isLoading}) => {
    const dataLabel = {visible: true, name: 'xValue', position: 'Outside'};

    return (
        <div className={cl.chartContainer}>
            {isLoading &&
                <div className={cl.loaderContainer}>
                    <div className='loader light'>

                    </div>
                </div>
            }
            <AccumulationChartComponent id='user-registration-pie-chart'
                                        width='100%'
                                        height='500px'>
                <Inject services={[AccumulationDataLabel]}/>
                <AccumulationSeriesCollectionDirective>
                    <AccumulationSeriesDirective dataSource={data}
                                                 xName='xValue'
                                                 yName='yValue'
                                                 pointColorMapping='fill'
                                                 dataLabel={dataLabel}>

                    </AccumulationSeriesDirective>
                </AccumulationSeriesCollectionDirective>
            </AccumulationChartComponent>
        </div>
    );
};

export default UsersRegistrationsPieChart;