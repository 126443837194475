import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Query} from "@syncfusion/ej2-data";
import GridTab from "../../../ui/components/Grid/GridTab/GridTab";
import GridTabSection, {GridTabSectionType} from "../../../ui/components/Grid/GridTab/GridTabSection/GridTabSection";
import GridTabItem from "../../../ui/components/Grid/GridTab/GridTabItem/GridTabItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExport, faRefresh, faTrash} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../ui/components/Loader/Loader";
import GridComponent from "../../../ui/components/Grid/GridComponent";
import {ColumnDirective, ColumnsDirective, Filter, Inject, Page, Sort} from "@syncfusion/ej2-react-grids";
import DateRangeGridFilter from "../../../ui/components/Grid/filters/DateRangeGridFilter";
import ProblemUserDateTemplate from "./templates/ProblemUserDateTemplate";
import ProblemUserProblemTemplate from "./templates/ProblemUserProblemTemplate";
import getServerErrorResourceName from "../../../../data/apiErrors/apiErrors";
import ResultCode from "../../../../data/enums/ResultCode";
import {toast} from "react-toastify";
import apiAxios from "../../../../app/api/apiAxios";
import {useDownloadFile} from "../../../../hooks/useDownloadFile";
import ProgressBarToast from "../../../ui/components/ProgressBarToast/ProgressBarToast";

const ProblemUser = () => {
    const [percentage, setPercentage] = useState(0);
    const [show, setShow] = useState(false);
    const [tab, setTab] = useState('0');
    const [query, setQuery] = useState(null);
    const [filterTrigger, setFilterTrigger] = useState(null);
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const {t} = useTranslation();

    const createDownloadFileRequest = () => {
        return apiAxios.get(`${process.env.REACT_APP_BE_DOMAIN}/api/analytics/getProblemUsersExcelReport?t=${tab}`, {
            responseType: 'blob',
            onDownloadProgress(ev) {
                let progress = Math.round((ev.loaded / ev.total) * 100);
                if (progress) {
                    setPercentage(progress);
                }
            }
        });
    }

    const {download} = useDownloadFile({
        request: createDownloadFileRequest,
        beforeDownload: () => setShow(prev => !prev),
        afterDownload: () => {
            setShow(prev => !prev);

            const timeoutId = setTimeout(() => {
                setPercentage(0);

                clearTimeout(timeoutId);
            }, 1000);
        },
        actionFailure: (ev) => {
            if (ev && ev.response) {
                if (ev.response.status === 500) {
                    toast.error(t("errors.download-file-server-error"));
                } else if (ev.response.status === 404) {
                    toast.error(t("analytics.errors.download-file-error-not-found"));
                }
            }

            setPercentage(0);
            setShow(prev => !prev);
        },
        getFileName: () => tab === '0' ? 'Проблемы пользователей.xlsx' : 'Проблемы регистраций пользователей.xlsx'
    });

    const onTabItemClick = ({id, queryParameterName, queryKey}) => {
        if (queryParameterName && queryKey) {
            setTab(queryKey);
        } else if (id) {
            if (id === 'refresh-btn') {
                setRefreshTrigger(prev => prev + 1);
            } else if (id === 'ignore-btn') {
                ignoreRows();
            } else if (id === 'export-btn') {
                download().then();
            }
        }
    }

    const ignoreRows = () => {
        (async () => {
            try {
                const response = await apiAxios.post(`${process.env.REACT_APP_BE_DOMAIN}/api/analytics/ignore`, {
                    ids: selectedItems.map(item => item.id),
                    tab: tab
                });

                if (response) {
                    if (response.status === 200 && response.data) {
                        if (response.data.resultCode === ResultCode.Success) {
                            setRefreshTrigger(prev => prev + 1);
                        } else {
                            toast.error(t(getServerErrorResourceName({
                                model: 'analytics',
                                resultCode: response.data.resultCode,
                                description: response.data.description
                            })));
                        }
                    }
                }
            } catch (err) {
                if (err && err.message) {
                    toast.error(`${t(getServerErrorResourceName({
                        model: 'analytics',
                        resultCode: ResultCode.ServerError
                    }))} Message: (${err.message})`);
                }
            }
        })();
    }

    useEffect(() => {
        setQuery(prev => {
            let q = new Query();
            if (prev) {
                if (prev && prev.params && prev.params.length > 0) {
                    const params = prev.params.filter(item => item.key !== 'tab');
                    for (const item of params) {
                        q.addParams(item.key, item.value);
                    }
                }

                q.addParams('tab', tab);
            } else {
                const urlParams = new URLSearchParams(window.location.search);
                if (urlParams.get('tab')) {
                    if (tab !== urlParams.get('tab')) {
                        setTab(urlParams.get('tab'));

                        return null;
                    }

                    q.addParams('tab', urlParams.get('tab'));
                } else {
                    q.addParams('tab', tab);
                }
            }

            return q;
        });
    }, [tab]);

    const onFilterChange = (ev, fieldName) => setFilterTrigger({
        ev,
        fieldName
    });
    const onRowSelected = (ev) => {
        if (ev && ev.data) {
            setSelectedItems(prev => {
                if (prev && prev.find(item => item.id === ev.data.id)) {
                    return prev;
                }

                if (!prev) {
                    return [ev.data];
                }

                let arr = [...prev];

                arr.push(ev.data);

                return arr;
            });
        }
    }

    const onRowDeselected = (ev) => {
        if (ev && ev.data) {
            setSelectedItems(prev => {
                if (!prev || prev.length <= 0) {
                    return prev;
                }

                if (prev.find(item => item.id === ev.data.id)) {
                    prev = prev.filter(item => item.id !== ev.data.id);

                    return prev;
                }
            });
        }
    }

    return (
        <>
            <ProgressBarToast show={show} percentage={percentage}/>
            <div className='list-container'>
                <GridTab onClick={onTabItemClick}>
                    <GridTabSection type={GridTabSectionType.Tab}
                                    showRightSeparator={true}
                                    queryParameterName='tab'>
                        <GridTabItem id='user-problems'
                                     text={t("analytics.grid.user-problem-tab")}
                                     queryKey='0'
                                     isDefaultActive={true}>

                        </GridTabItem>
                        <GridTabItem id='user-registration-problems'
                                     text={t("analytics.grid.user-registration-problem-tab")}
                                     queryKey='1'>

                        </GridTabItem>
                    </GridTabSection>
                    <GridTabSection type={GridTabSectionType.Button}>
                        {selectedItems && selectedItems.length > 0
                            ? <GridTabItem id='ignore-btn'
                                           text={t("btn.ignore")}
                                           icon={<FontAwesomeIcon icon={faTrash}/>}/>
                            : null
                        }
                        <GridTabItem id='export-btn'
                                     text={t("btn.export")}
                                     icon={<FontAwesomeIcon icon={faFileExport}/>}>

                        </GridTabItem>
                        <GridTabItem id='refresh-btn'
                                     text={t("btn.refresh")}
                                     icon={<FontAwesomeIcon icon={faRefresh}/>}>

                        </GridTabItem>
                    </GridTabSection>
                </GridTab>
                {!query
                    ? <Loader/>
                    : <GridComponent id='grid'
                                     container='.list-container'
                                     url='/api/analytics/getProblems'
                                     query={query}
                                     refresh={refreshTrigger}
                                     filter={filterTrigger}
                                     rowSelected={onRowSelected}
                                     rowDeselected={onRowDeselected}
                                     filterStoreEntityName={'problemUser'}
                                     selectionSettings={{type: 'Multiple', enableSimpleMultiRowSelection: true}}
                                     sortSettings={{columns: [{field: 'registrationDate', direction: 'Descending'}]}}>
                        <ColumnsDirective>
                            <ColumnDirective field='id'
                                             visible={false}
                                             textAlign="center"
                                             isPrimaryKey={true}
                                             type='number'/>
                            <ColumnDirective field='registrationDate'
                                             headerText={t("analytics.grid.registration-date")}
                                             width='200'
                                             textAlign="center"
                                             template={(props) => <div><ProblemUserDateTemplate {...props} /></div>}
                                             filterTemplate={(props) => <DateRangeGridFilter
                                                 onChange={onFilterChange} filterStoreEntityName={'problemUser'} {...props}/>}/>
                            <ColumnDirective field='phone'
                                             headerText={t("analytics.grid.phone")}
                                             width='200'
                                             textAlign="center"/>
                            <ColumnDirective field='name'
                                             headerText={t("analytics.grid.name")}
                                             width='300'
                                             textAlign="center"/>
                            <ColumnDirective field='error'
                                             headerText={t("analytics.grid.problem")}
                                             width='450'
                                             textAlign="center"
                                             allowFiltering={false}
                                             allowSorting={false}
                                             template={(props) => <div><ProblemUserProblemTemplate {...props} />
                                             </div>}/>
                            <ColumnDirective field='incident'
                                             headerText={t("analytics.grid.incident")}
                                             width='300'
                                             textAlign="center"/>
                        </ColumnsDirective>
                        <Inject services={[Page, Sort, Filter]}/>
                    </GridComponent>
                }
            </div>
        </>
    );
};

export default ProblemUser;
