import React, {useState} from "react";
import {Query} from "@syncfusion/ej2-data";
import {useTranslation} from "react-i18next";
import GridTab from "../../../ui/components/Grid/GridTab/GridTab";
import GridTabSection, {GridTabSectionType} from "../../../ui/components/Grid/GridTab/GridTabSection/GridTabSection";
import GridTabItem from "../../../ui/components/Grid/GridTab/GridTabItem/GridTabItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../ui/components/Loader/Loader";
import GridComponent from "../../../ui/components/Grid/GridComponent";
import {ColumnDirective, ColumnsDirective, Filter, Inject, Page, Sort} from "@syncfusion/ej2-react-grids";
import UserLogResultCodeTemplate from "./templates/UserLogResultCodeTemplate";
import UserLogResultCodeFilter from "./filters/UserLogResultCodeFilter";
import UserLogActivityCodeTemplate from "./templates/UserLogActivityCodeTemplate";
import UserLogActivityCodeFilter from "./filters/UserLogActivityCodeFilter";
import DateRangeGridFilter from "../../../ui/components/Grid/filters/DateRangeGridFilter";
import UserLogDateTemplate from "./templates/UserLogDateTemplate";

const UserLog = () => {
    const [query] = useState(new Query());
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [filterTrigger, setFilterTrigger] = useState(null);
    const {t} = useTranslation();

    const onFilterChange = (ev, fieldName) => setFilterTrigger({
        ev,
        fieldName
    });

    const onTabItemClick = ({id}) => {
        if (id) {
            if (id === 'refresh-btn') {
                setRefreshTrigger(prev => prev + 1);
            }

            if (id === 'add-btn') {

            }
        }
    }

    return (
        <div className='list-container'>
            <GridTab onClick={onTabItemClick}>
                <GridTabSection type={GridTabSectionType.Button}>
                    <GridTabItem id='refresh-btn'
                                 text={t("btn.refresh")}
                                 icon={<FontAwesomeIcon icon={faRefresh}/>}>

                    </GridTabItem>
                </GridTabSection>
            </GridTab>
            {!query
                ? <Loader/>
                : <GridComponent id='grid'
                                 container='.list-container'
                                 url='/api/userLog/getUserLogs'
                                 query={query}
                                 filterStoreEntityName={'userLog'}
                                 refresh={refreshTrigger}
                                 filter={filterTrigger}>
                    <ColumnsDirective>
                        <ColumnDirective field='id'
                                         visible={false}
                                         textAlign="center"
                                         isPrimaryKey={true}
                                         type='number'/>
                        <ColumnDirective field='fullName'
                                         headerText={t("userLog.grid.name")}
                                         width='200'
                                         textAlign="center"/>
                        <ColumnDirective field='phone'
                                         headerText={t("userLog.grid.phone")}
                                         width='150'
                                         textAlign="center"/>
                        <ColumnDirective field='createdOn'
                                         headerText={t("userLog.grid.date")}
                                         width='150'
                                         textAlign="center"
                                         template={(props) => <div><UserLogDateTemplate {...props} /></div>}
                                         filterTemplate={(props) => <DateRangeGridFilter
                                             onChange={onFilterChange} filterStoreEntityName={'userLog'} {...props}/>}/>
                        <ColumnDirective field='activityCode'
                                         headerText={t("userLog.grid.activity-code")}
                                         width='300'
                                         textAlign="center"
                                         template={(props) => <div><UserLogActivityCodeTemplate {...props}/></div>}
                                         filterTemplate={(props) => <UserLogActivityCodeFilter
                                             onFilterChange={onFilterChange} filterStoreEntityName={'userLog'} {...props}/>}/>
                        <ColumnDirective field='resultCode'
                                         headerText={t("userLog.grid.result-code")}
                                         width='150'
                                         textAlign="center"
                                         template={(props) => <div><UserLogResultCodeTemplate {...props}/></div>}
                                         filterTemplate={(props) => <UserLogResultCodeFilter
                                             onFilterChange={onFilterChange} filterStoreEntityName={'userLog'} {...props}/>}/>
                        <ColumnDirective field='resultNotes'
                                         headerText={t("userLog.grid.notes")}
                                         width='600'
                                         textAlign="center"
                                         allowSorting={false}
                                         allowFiltering={false}/>
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter]}/>
                </GridComponent>
            }
        </div>
    );
}

export default UserLog;