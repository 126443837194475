import React from 'react';
import cl from './Button.module.css';

const Button = ({children, style, buttonType, width, height, ...rest}) => {
    const getStyleObject = () => {
        const object = {};

        if (style) {
            Object.assign(object, style);
        }

        if (width) {
            object.width = width;
        }

        if (height) {
            object.height = height;
        }

        return object;
    }

    return (
        <button {...rest}
                className={cl.btn}
                data-type={buttonType}
                style={getStyleObject()}>
            {children}
        </button>
    );
};

export default Button;

export const ButtonType = {
    Default: 'default',
    Warning: 'warning',
    Back: 'back'
}