import React, {useEffect, useState} from 'react';
import cl from './Dialog.module.css';
import {createPortal} from "react-dom";
import {v4 as uuidv4} from 'uuid';

const Dialog = ({open, children, width, height, onOverlayClick, usePortalToDocumentBody = false}) => {
    const [id, setId] = useState();

    useEffect(() => {
        setId(uuidv4());
    }, [])

    if (!open || !id) {
        return null;
    }

    const getDialogStyle = () => {
        const object = {};

        if (width) {
            object.width = width;
        }

        if (height) {
            object.height = height;
        }

        return object;
    }

    const onOverlay = (ev) => {
        if (ev && ev.target && ev.target.getAttribute('data-type') === 'overlay' && onOverlayClick) {
            onOverlayClick();
        }
    }

    if (usePortalToDocumentBody) {
        return (
            createPortal(
                <div className={cl.overlay}
                     onClick={onOverlay}
                     key={id}
                     id={id}
                     data-type='overlay'>
                    <div className={`${cl.dialog} ${cl.bounce}`} style={getDialogStyle()}>
                        {children}
                    </div>
                </div>,
                document.body
            )
        );
    }

    return (
        <div className={cl.overlay}
             onClick={onOverlay}
             key={id}
             id={id}
             data-type='overlay'>
            <div className={`${cl.dialog} ${cl.bounce}`} style={getDialogStyle()}>
                {children}
            </div>
        </div>
    );
};

export default Dialog;