import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSelectors";
import {useNavigate} from "react-router-dom";
import GridTabSection, {GridTabSectionType} from "../../../ui/components/Grid/GridTab/GridTabSection/GridTabSection";
import GridTabItem from "../../../ui/components/Grid/GridTab/GridTabItem/GridTabItem";
import PermissionType from "../../../../data/enums/PermissionType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faRefresh} from "@fortawesome/free-solid-svg-icons";
import GridTab from "../../../ui/components/Grid/GridTab/GridTab";
import {ColumnDirective, ColumnsDirective, Filter, Inject, Page, Sort} from "@syncfusion/ej2-react-grids";
import GridComponent from "../../../ui/components/Grid/GridComponent";
import PollStateFilter from "./filters/PollStateFilter";
import PollPeriodFilter from "./filters/PollPeriodFilter";
import {Query} from "@syncfusion/ej2-data";
import Loader from "../../../ui/components/Loader/Loader";

const PeriodTemplate = (props) => {
    const {t} = useTranslation();

    if (!props[props.column.field] === undefined) {
        return (<div></div>);
    }

    const getString = () => {
        switch (props[props.column.field]) {
            case 0:
                return t("poll.poll-period.one-time");
            case 1:
                return t("poll.poll-period.day");
            case 2:
                return t("poll.poll-period.week");
            case 3:
                return t("poll.poll-period.month");
        }

        return '';
    };

    return (
        <div>
            {getString()}
        </div>
    );
};

const Poll = () => {
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [filterTrigger, setFilterTrigger] = useState(null);
    const [tab, setTab] = useState(null);
    const [query, setQuery] = useState(null);

    const {t} = useTranslation();
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();

    const onTabItemClick = ({id, queryParameterName, queryKey}) => {
        if (queryParameterName && queryKey) {
            setTab(queryKey);
        } else if (id) {
            if (id === 'refresh-btn') {
                setRefreshTrigger(prev => prev + 1);
            }
            if (id === 'add-btn') {
                navigate(`/users/polls/poll/`);
            }
        }
    };

    useEffect(() => {
        setQuery(prev => {
            let q = new Query();
            if (prev) {
                if (prev && prev.params && prev.params.length > 0) {
                    const params = prev.params.filter(item => item.key !== 'tab');
                    for (const item of params) {
                        q.addParams(item.key, item.value);
                    }
                }
                q.addParams('tab', tab.toString());
            } else {
                const urlParams = new URLSearchParams(window.location.search);
                if (urlParams.get('tab')) {
                    q.addParams('tab', urlParams.get('tab'));
                } else {
                    q.addParams('tab', tab.toString());
                }
            }

            return q;
        });
    }, [tab]);

    const onFilterChange = (ev, fieldName) => setFilterTrigger({
        ev,
        fieldName
    });

    const onRowDoubleClick = ev => {
        if (ev && ev.rowData) {
            navigate(`/users/polls/poll/${ev.rowData.id}`);
        }
    };

    return (
        <div className='list-container'>
            <GridTab onClick={onTabItemClick}>
                <GridTabSection type={GridTabSectionType.Tab}
                                showRightSeparator={true}
                                queryParameterName='tab'>
                    <GridTabItem id='all-tab'
                                 text={t("shared.grid.all-tab")}
                                 queryKey='0'
                                 isDefaultActive={true}>

                    </GridTabItem>
                    <GridTabItem id='active-tab'
                                 text={t("shared.grid.active-tab")}
                                 queryKey='1'>

                    </GridTabItem>
                </GridTabSection>
                <GridTabSection type={GridTabSectionType.Button}>
                    {user.permission.views === PermissionType.Editing
                        ? (
                            <GridTabItem id='add-btn'
                                         text={t("btn.add")}
                                         icon={<FontAwesomeIcon icon={faAdd}/>}>

                            </GridTabItem>
                        )
                        : null
                    }
                    <GridTabItem id='refresh-btn'
                                 text={t("btn.refresh")}
                                 icon={<FontAwesomeIcon icon={faRefresh}/>}>

                    </GridTabItem>
                </GridTabSection>
            </GridTab>
            {!query
                ? <Loader/>
                :
                <GridComponent id='grid'
                               container='.list-container'
                               url='/api/poll/getPolls'
                               filterStoreEntityName={'poll'}
                               refresh={refreshTrigger}
                               query={query}
                               onRowClick={onRowDoubleClick}
                               filter={filterTrigger}>
                    <ColumnsDirective>
                        <ColumnDirective field='id'
                                         visible={false}
                                         textAlign="center"
                                         isPrimaryKey={true}
                                         type='number'/>
                        <ColumnDirective field='name'
                                         headerText={t("poll.grid.name")}
                                         width='450'
                                         textAlign="center"/>
                        <ColumnDirective field='targetLabel'
                                         headerText={t("poll.grid.filter")}
                                         width='500'
                                         allowSorting={false}
                                         allowFiltering={false}
                                         textAlign="center"/>
                        <ColumnDirective field='period'
                                         headerText={t("poll.grid.period")}
                                         width='250'
                                         textAlign="center"
                                         template={(props) => <PeriodTemplate {...props}/>}
                                         filterTemplate={(props) => (<PollPeriodFilter filterStoreEntityName={'poll'}
                                                                                       onFilterChange={onFilterChange} {...props}/>)}/>
                        <ColumnDirective field='forResidents'
                                         headerText={t("poll.grid.for-residents")}
                                         width='250'
                                         displayAsCheckBox={true}
                                         textAlign="center"
                                         filterTemplate={(props) => <PollStateFilter
                                             onFilterChange={onFilterChange}
                                             filterStoreEntityName={'poll'} {...props} />}/>
                        <ColumnDirective field='forCommercialProperty'
                                         headerText={t("poll.grid.for-commercial-property")}
                                         width='250'
                                         displayAsCheckBox={true}
                                         textAlign="center"
                                         filterTemplate={(props) => <PollStateFilter
                                             onFilterChange={onFilterChange}
                                             filterStoreEntityName={'poll'} {...props} />}/>
                        <ColumnDirective field='hasIncidents'
                                         headerText={t("poll.grid.has-incidents")}
                                         width='250'
                                         displayAsCheckBox={true}
                                         textAlign="center"
                                         filterTemplate={(props) => <PollStateFilter
                                             onFilterChange={onFilterChange}
                                             filterStoreEntityName={'poll'} {...props} />}/>
                        <ColumnDirective field='hasServices'
                                         headerText={t("poll.grid.has-services")}
                                         width='250'
                                         displayAsCheckBox={true}
                                         textAlign="center"
                                         filterTemplate={(props) => <PollStateFilter
                                             onFilterChange={onFilterChange}
                                             filterStoreEntityName={'poll'} {...props} />}/>
                        <ColumnDirective field='hasPayments'
                                         headerText={t("poll.grid.has-payments")}
                                         width='250'
                                         displayAsCheckBox={true}
                                         textAlign="center"
                                         filterTemplate={(props) => <PollStateFilter
                                             onFilterChange={onFilterChange}
                                             filterStoreEntityName={'poll'} {...props} />}/>
                        <ColumnDirective field='isPriority'
                                         headerText={t("poll.grid.is-priority")}
                                         width='250'
                                         displayAsCheckBox={true}
                                         textAlign="center"
                                         filterTemplate={(props) => <PollStateFilter
                                             onFilterChange={onFilterChange}
                                             filterStoreEntityName={'poll'} {...props} />}/>
                        <ColumnDirective field='isBlocked'
                                         headerText={t("poll.grid.is-blocked")}
                                         width='250'
                                         displayAsCheckBox={true}
                                         textAlign="center"
                                         filterTemplate={(props) => <PollStateFilter
                                             onFilterChange={onFilterChange}
                                             filterStoreEntityName={'poll'} {...props} />}/>
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter]}/>
                </GridComponent>
            }
        </div>
    );
};

export default Poll;