import React from 'react';
import {useTranslation} from "react-i18next";

const PostCategoryTypeColumnTemplate = (props) => {
    const {t} = useTranslation();

    if (!props && !props.postCategoryType) {
        return null;
    }

    return (
        <div>
            {t(`postCategory.postCategoryType.${props.postCategoryType}`)}
        </div>
    );
};

export default PostCategoryTypeColumnTemplate;