import React from 'react';
import {useTranslation} from "react-i18next";

const UserLogResultCodeTemplate = (props) => {
    const {t} = useTranslation();

    if (!props[props.column.field]) {
        return null;
    }


    return (
        <div>
            {t(`userLog.userLogResultCode.${props.resultCode}`)}
        </div>
    );
};

export default UserLogResultCodeTemplate;