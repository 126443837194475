import React from 'react';
import cl from './GridTabItem.module.css';

const GridTabItem = ({type, text, isDefaultActive, color, onClick, uuid, icon}) => {

    if (!type) {
        return null;
    }

    const style = color ? {
        backgroundColor: color,
    } : null;

    return (
        <button title={text}
                data-role={type}
                style={style}
                className={`${cl.button} ${type === 'button' && !icon ? cl.center : ''} ${isDefaultActive ? cl.active : ''} ${icon ? cl.buttonIconPadding : ''}`}
                onClick={() => onClick(uuid)}>
            {icon &&
                <div className={cl.icon}>
                    {icon}
                </div>
            }
            {text}
        </button>
    );
};

export default GridTabItem;