import {configureStore} from "@reduxjs/toolkit";
import {apiSlice} from "../api/apiSlice";
import authenticationReducer from "../../features/authentication/authenticationSlice";
import applicationReducer from "../../features/application/applicationSlice";
import filterReducer from '../../features/filter/filterSlice';

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        authentication: authenticationReducer,
        application: applicationReducer,
        filter: filterReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlice.middleware)
});