import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useServerError} from "../../../../hooks/useServerError";
import apiAxios from "../../../../app/api/apiAxios";
import ResultCode from "../../../../data/enums/ResultCode";
import getServerErrorResourceName from "../../../../data/apiErrors/apiErrors";
import {toast} from "react-toastify";
import FormLayout from "../../../ui/layouts/FormLayout/FormLayout";
import Button, {ButtonType} from "../../../ui/components/Button/Button";
import PostForm from "./PostForm";
import moment from "moment";


const initialState = {
    id: 0,
    createdOn: null,
    publicationDate: moment.utc(),
    isBlocked: false,
    title: '',
    draftTitle: '',
    content: '',
    draftContent: '',
    isDraft: false,
    isImportant: false,
    postCategoryId: null,
    targets: null,
    showToAnonymous: false,
    showToResidents: true,
    postSourceId: null,
    otherPostSource: '',
    extUrl: '',
    additionalInfo: '',
    hasCustomFilter: false,
    sourceImage: null,
    postCategoryListType: 0,
    postCategoryType: 0,
    isNew: false,
    postPhoto: null,
    endDate: moment.utc().add(3, 'days')
}

const Post = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [post, setPost] = useState(null);
    const [resetTrigger, setResetTrigger] = useState(0);
    const [serverError, setServerError] = useServerError(null);
    const {state} = useLocation();


    useEffect(() => {
        (async () => await load(id))();
    }, [id]);

    const load = async (id) => {
        if (id && id > 0) {
            setServerError(null);
            setIsLoading(true);

            const url = `${process.env.REACT_APP_BE_DOMAIN}/api/post/getPost?id=${id}`;

            try {
                const response = await apiAxios.get(url);

                if (response) {
                    if (response.status === 200 && response.data) {
                        if (response.data.resultCode === ResultCode.Success) {

                            response.data.hasImageTemplate = response.data.imageTemplateId != null && response.data.imageTemplateId > 0;

                            setPost(response.data);
                        } else {
                            setServerError(t(getServerErrorResourceName({
                                model: 'post',
                                resultCode: response.data.resultCode
                            })));
                        }
                    }
                }

                setIsLoading(false);
            } catch (e) {
                if (e && e.message) {
                    setServerError(`${t(getServerErrorResourceName({
                        model: 'post',
                        resultCode: ResultCode.ServerError
                    }))} Message: (${e.message})`);
                }
                setIsLoading(false);
            }
        } else {
            if (state && state.category) {
                setPost({
                    ...initialState,
                    postCategoryId: state.category,
                    postSourceId: state.category === 5 ? 3 : null
                });
            }
        }
    };

    const save = async (values, actions) => {
        try {
            setServerError(null);
            setIsLoading(true);
            actions.setSubmitting(true);

            const response = await apiAxios.post(`${process.env.REACT_APP_BE_DOMAIN}/api/post/save`, {
                id: post.id,
                publicationDate: values.publicationDate,
                showToAnonymous: values.showToAnonymous,
                showToResidents: values.showToResidents,
                postSourceId: values.postSourceId,
                otherPostSource: values.otherPostSource,
                isBlocked: values.isBlocked,
                imageTemplateId:  values.hasImageTemplate ? values.imageTemplateId : null,
                additionalInfo: values.additionalInfo,
                extUrl: values.extUrl,
                targets: values.targets,
                title: values.title,
                draftTitle: values.draftTitle,
                content: values.content,
                draftContent: values.draftContent,
                postCategoryId: values.postCategoryId,
                isImportant: values.isImportant,
                position: values.position,
                endDate: values.endDate,
                postPhoto: values.postPhoto
            });

            if (response) {
                if (response.status === 200 && response.data) {
                    if (response.data.resultCode === ResultCode.Success) {
                        toast.success(t("post.form.saved-label"), {position: "top-center",});

                        if (response.data.description) {
                            await load(+response.data.description);

                            setResetTrigger(prev => prev + 1);
                        }
                    } else {
                        setServerError(t(getServerErrorResourceName({
                            model: 'post',
                            resultCode: response.data.resultCode,
                            description: response.data.description
                        })));
                    }
                }
            }

            setIsLoading(false);
            actions.setSubmitting(false);
        } catch (err) {
            if (err && err.message) {
                setServerError(`${t(getServerErrorResourceName({
                    model: 'post',
                    resultCode: ResultCode.ServerError
                }))} Message: (${err.message})`);
            }
            setIsLoading(false);
            actions.setSubmitting(false);
        }
    };

    const deletePage = () => {
        (async () => {
            try {
                setServerError(null);
                setIsLoading(true);

                const response = await apiAxios.post(`${process.env.REACT_APP_BE_DOMAIN}/api/post/delete`, {
                    id: post.id
                });

                if (response) {
                    if (response.status === 200 && response.data) {
                        if (response.data.resultCode === ResultCode.Success) {
                            toast.success(t("post.form.deleted-label"), {position: "top-center",});

                            navigate(-1);
                        } else {
                            setServerError(t(getServerErrorResourceName({
                                model: 'post',
                                resultCode: response.data.resultCode,
                                description: response.data.description
                            })));
                        }
                    }
                }

                setIsLoading(false);
            } catch (err) {
                if (err && err.message) {
                    setServerError(`${t(getServerErrorResourceName({
                        model: 'post',
                        resultCode: ResultCode.ServerError
                    }))} Message: (${err.message})`);
                }
                setIsLoading(false);
            }
        })();
    };

    const publishPage = () => {
        if (!post.publicationDate) {
            setServerError("Publication date is required for publication");
        } else {
            (async () => {
                try {
                    setServerError(null);
                    setIsLoading(true);

                    const response = await apiAxios.post(`${process.env.REACT_APP_BE_DOMAIN}/api/post/publish`, {
                        id: post.id,
                        publicationDate: post.publicationDate
                    });

                    if (response) {
                        if (response.status === 200 && response.data) {
                            if (response.data.resultCode === ResultCode.Success) {
                                toast.success(t("post.form.published-label"), {position: "top-center",});

                                await load(post.id);

                                setResetTrigger(prev => prev + 1);
                            } else {
                                setServerError(t(getServerErrorResourceName({
                                    model: 'post',
                                    resultCode: response.data.resultCode,
                                    description: response.data.description
                                })));
                            }
                        }
                    }

                    setIsLoading(false);
                } catch (err) {
                    if (err && err.message) {
                        setServerError(`${t(getServerErrorResourceName({
                            model: 'post',
                            resultCode: ResultCode.ServerError
                        }))} Message: (${err.message})`);
                    }
                    setIsLoading(false);
                }
            })();
        }
    };

    return (
        <FormLayout header={<div className='default-form-layout-header'>{t("post.form.form-label")}</div>}
                    footer={(
                        <div className='default-form-layout-footer'>
                            <Button buttonType={ButtonType.Back}
                                    onClick={() => navigate(-1)}
                                    disabled={isLoading}>
                                {t("btn.back")}
                            </Button>
                            <div>
                                {post && post.id && post.id > 0
                                    ? <Button buttonType={ButtonType.Warning}
                                              onClick={() => deletePage()}
                                              type='button'
                                              disabled={isLoading}
                                              style={{marginRight: '20px'}}>
                                        {t("btn.delete")}
                                    </Button>
                                    : null
                                }
                                {post && post.id && post.id > 0 && (post.isDraft || post.isNew)
                                    ? <Button buttonType={ButtonType.Default}
                                              onClick={() => publishPage()}
                                              type='button'
                                              disabled={isLoading}
                                              style={{marginRight: '20px'}}>
                                        {t("btn.publish")}
                                    </Button>
                                    : null
                                }
                                <Button buttonType={ButtonType.Default}
                                        form='post-form'
                                        type='submit'
                                        disabled={isLoading}>
                                    {t("btn.save")}
                                </Button>
                            </div>
                        </div>
                    )}
                    footerPosition='top'
                    isLoading={isLoading || !post}>
            <div className='default-form-layout-container'>
                {serverError &&
                    <div className='server-error-container'>
                        <div>
                            {serverError}
                        </div>
                    </div>
                }
                {post ? <PostForm resetTrigger={resetTrigger} {...post} onSubmit={save}/> : null}
            </div>
        </FormLayout>
    );
};

export default Post;
