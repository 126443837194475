import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentToken} from "../../../../../features/authentication/authenticationSelectors";
import {DataManager, Query, WebApiAdaptor} from "@syncfusion/ej2-data";
import {useSearchParams} from "react-router-dom";
import {refreshToken} from "../../../../../features/authentication/authenticationSlice";
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";

const PostCategorySelector = React.memo(({onChange}) => {
    let gridRef;
    const token = useSelector(selectCurrentToken);
    const [id, setId] = useState(null);
    const dispatch = useDispatch();
    const [query] = useState(new Query().addParams('postCategoryType', '1'));
    const [searchParams, setSearchParams] = useSearchParams();
    const [source] = useState(new DataManager({
        adaptor: new WebApiAdaptor(),
        url: `${process.env.REACT_APP_BE_DOMAIN}/api/postCategory/getPostCategoriesList`,
        headers: [
            {
                'Authorization': `bearer ${token}`
            }
        ]
    }));

    useEffect(() => {
        setId(() => {
            return searchParams.get('category') ? parseInt(searchParams.get('category')) : null;
        })
    }, []);

    const onSelect = (ev) => {
        if (ev && ev.itemData) {
            onChange(ev.itemData.id);

            setupQueryParam(ev.itemData.id);
        }
    }

    const onActionFailure = (ev) => {
        if (ev.name && ev.error && ev.error.error && ev.name === 'actionFailure' && ev.error.error.status === 401) {
            dispatch(refreshToken());
        }
    }

    const onDataBound = (ev) => {
        if (id) {
            onChange(id);

            setupQueryParam(id);
        } else if (ev && ev.items && ev.items.length > 0) {
            onChange(ev.items[0].id);

            setupQueryParam(ev.items[0].id);
        }
    }

    const setupQueryParam = (id) => {
        setSearchParams(() => {
            const url = new URLSearchParams();

            for (let item of searchParams) {
                url.append(item[0], item[1]);
            }

            url.set('category', id);

            return url;
        });
    }

    return (
        <div style={{'padding': '0 10px'}}>
            {!id
                ? <DropDownListComponent ref={ref => gridRef = ref}
                                         id='post-category-filter'
                                         fields={{text: 'text', value: 'id'}}
                                         width={'300'}
                                         popupHeight='250px'
                                         dataSource={source}
                                         showClearButton={false}
                                         query={query}
                                         index={0}
                                         select={onSelect}
                                         actionFailure={onActionFailure}
                                         dataBound={onDataBound}/>
                : <DropDownListComponent ref={ref => gridRef = ref}
                                         id='post-category-filter'
                                         fields={{text: 'text', value: 'id'}}
                                         width={'300'}
                                         popupHeight='250px'
                                         dataSource={source}
                                         showClearButton={false}
                                         query={query}
                                         value={id}
                                         select={onSelect}
                                         actionFailure={onActionFailure}
                                         dataBound={onDataBound}/>
            }
        </div>
    );
});

export default PostCategorySelector;