import React from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Col, Container, Row} from "react-bootstrap";
import Target from "../../../ui/components/Target/Target";
import ImageUploader from "../../../ui/components/ImageUploader/ImageUploader";
import cl from './PostImageTemplateForm.module.css';
import Input from "../../../ui/components/Input/Input";

const PostImageTemplateForm = ({
                              name,
                              isBlocked,

                                   logo,
                                   logoName,
                                   logoSize,

                              onSubmit
                          }) => {
        const {t} = useTranslation();





        const form = useFormik({
            enableReinitialize: true,
            initialValues: {
                name: name,
                isBlocked: isBlocked,

                image: {
                    image: logo,
                    name: logoName,
                    size: logoSize
                }
            },
            validationSchema: Yup.object({
                name: Yup.string()
                    .required(t("errors.required-error").replace('{0}', t("postImageTemplate.form.name"))),
            }),
            onSubmit: onSubmit
        });

        return (
            <Container fluid>
                <form id='post-image-template-form' onSubmit={form.handleSubmit}>
                    <Row>
                        <Col>
                            <div className='input-container'>
                                <label className='label required'>
                                    {t("postImageTemplate.form.name")}
                                </label>
                                <Input name='name'
                                       type='text'
                                       placeholder={t("postImageTemplate.form.name")}
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       autoComplete='off'
                                       value={form.values.name}/>
                                {form.touched.name && form.errors.name ?
                                    <div className='field-error'>{form.errors.name}</div> : null}
                            </div>







                        </Col>
                        <Col>
                            <div className='input-container checkbox end'>
                                <label className='label'>
                                    {t("postImageTemplate.form.blocked")}
                                </label>
                                <Input name='isBlocked'
                                       type='checkbox'
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       checked={form.values.isBlocked}/>
                            </div>




                        </Col>
                    </Row>
                </form>
                <Row className={cl.formRow}>
                    <Col>
                        {form.errors.image?.image ?
                            <div className='field-error'>{form.errors.image.image}</div> : null}
                        <ImageUploader {...form.values.image}
                                       disabled={form.isSubmitting}
                                       cropperSettings={{width: 720, height: 300}}
                                       change={ev => form.setFieldValue('image', ev)}/>
                    </Col>
                    <Col>

                    </Col>
                </Row>
            </Container>
        );
    }
;

export default PostImageTemplateForm;
