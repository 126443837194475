import {createSelector} from "@reduxjs/toolkit";

export const selectSettingFilters = state => state.filter.setting;

export const selectGridFilters = state => state.filter;

export const selectEntityFilterValue = createSelector(
    [
        state => state.filter,
        (_, payload) => payload
    ],
    (items, payload) => items[payload.type].find(item => item.field === payload.field)
)