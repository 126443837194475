import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import ResultCode from "../../data/enums/ResultCode";
import {logOut, setCredentials} from "../../features/authentication/authenticationSlice";
import {encode} from "js-base64"

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BE_DOMAIN}`,
    credentials: 'include',
    prepareHeaders: (headers, {getState}) => {
        const token = getState().authentication.token;
        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }

        return headers;
    }
});

const baseQueryWithReAuthentication = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    const state = api.getState();
    if (result?.error?.status === 401 && state.authentication.token && state.authentication.refreshToken) {
        const refreshResponse = await fetch(`${process.env.REACT_APP_BE_DOMAIN}/api/authentication/refreshToken`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: state.authentication.token,
                    refreshToken: state.authentication.refreshToken
                })
            });

        const data = await refreshResponse.json();

        if (refreshResponse.ok && data?.resultCode === ResultCode.Success && data.token && data.refreshToken) {
            const user = state.authentication.user;

            const {token, refreshToken, validTo} = data;

            localStorage.setItem('_d', encode(JSON.stringify({
                user: user,
                refreshToken: refreshToken,
                token: token,
                expiry: validTo,
            })));

            api.dispatch(setCredentials({
                user,
                token,
                refreshToken,
                expiry: validTo
            }));

            result = await baseQuery(args, api, extraOptions);
        } else {
            localStorage.removeItem('_d');

            api.dispatch(logOut());
        }
    }

    return result;
};

export const apiSlice = createApi({
    baseQuery: baseQueryWithReAuthentication,
    endpoints: (build) => ({})
});
