import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useServerError} from "../../../../hooks/useServerError";
import apiAxios from "../../../../app/api/apiAxios";
import ResultCode from "../../../../data/enums/ResultCode";
import getServerErrorResourceName from "../../../../data/apiErrors/apiErrors";
import Button, {ButtonType} from "../../../ui/components/Button/Button";
import FormLayout from "../../../ui/layouts/FormLayout/FormLayout";
import AccountForm from "./AccountForm";
import SyncFromCrmConfirmationDialog from "./components/SyncFromCrmConfirmationDialog/SyncFromCrmConfirmationDialog";
import {toast} from "react-toastify";

const initialState = {
    id: 0,
    number: '',
    section: '',
    subSection: '',
    homeOwner: '',
    street: '',
    entrance: '',
    building: '',
    apartment: '',
    isBlocked: false,
    isGroup: false,
    isCommercial: false,
    crmId: '',
    users: []
}

const Account = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isSyncFromCrmDialogOpen, setIsSyncFromCrmDialogOpen] = useState(false);
    const [account, setAccount] = useState(initialState);
    const [serverError, setServerError] = useServerError(null);

    useEffect(() => {
        (async () => await load(id))();
    }, [id]);

    const load = async (id) => {
        if (id && id > 0) {
            setServerError(null);
            setAccount(initialState);
            setIsLoading(true);

            const url = `${process.env.REACT_APP_BE_DOMAIN}/api/account/getAccount?id=${id}`;

            try {
                const response = await apiAxios.get(url);

                if (response) {
                    if (response.status === 200 && response.data) {
                        if (response.data.resultCode === ResultCode.Success) {
                            setAccount(response.data);
                        } else {
                            setServerError(t(getServerErrorResourceName({
                                model: 'account',
                                resultCode: response.data.resultCode
                            })));
                        }
                    }
                }

                setIsLoading(false);
            } catch (e) {
                if (e && e.message) {
                    setServerError(`${t(getServerErrorResourceName({
                        model: 'account',
                        resultCode: ResultCode.ServerError
                    }))} Message: (${e.message})`);
                }
                setIsLoading(false);
            }
        }
    };

    const onSyncFromCrmComplete = () => {
        setIsSyncFromCrmDialogOpen(prev => !prev);

        (async () => await load(id))();
    };

    const onAccountDelete = (account) => {
        if (!account) {
            setServerError('Account cannot be deleted');
        } else {
            (async () => {
                try {
                    setServerError(null);
                    setIsLoading(true);

                    const response = await apiAxios.post(`${process.env.REACT_APP_BE_DOMAIN}/api/userAccount/delete`, {
                        id: account.id,
                    });

                    if (response) {
                        if (response.status === 200 && response.data) {
                            if (response.data.resultCode === ResultCode.Success) {
                                toast.success(t("account.form.account-deleted-label"));

                                await load(id);
                            } else {
                                setServerError(t(getServerErrorResourceName({
                                    model: 'account',
                                    resultCode: response.data.resultCode,
                                    description: response.data.description
                                })));
                            }
                        }
                    }

                    setIsLoading(false);
                } catch (err) {
                    if (err && err.message) {
                        setServerError(`${t(getServerErrorResourceName({
                            model: 'account',
                            resultCode: ResultCode.ServerError
                        }))} Message: (${err.message})`);
                    }
                    setIsLoading(false);
                }
            })();
        }
    }

    return (
        <FormLayout header={<div className='default-form-layout-header'>{t("account.form.form-label")}</div>}
                    footer={(
                        <div className='default-form-layout-footer'>
                            <Button buttonType={ButtonType.Back}
                                    onClick={() => navigate(-1)}
                                    disabled={isLoading}>
                                {t("btn.back")}
                            </Button>
                            {account && account.id && account.id > 0
                                ? <div>
                                    <Button buttonType={ButtonType.Default}
                                            style={{marginLeft: '5px'}}
                                            onClick={() => setIsSyncFromCrmDialogOpen(prev => !prev)}
                                            disabled={isLoading}>
                                        {t("account.btn.sync-from-crm")}
                                    </Button>
                                </div>
                                : null
                            }
                        </div>
                    )}
                    footerPosition='top'
                    isLoading={isLoading}>
            <div>
                {serverError &&
                    <div className='server-error-container'>
                        <div>
                            {serverError}
                        </div>
                    </div>
                }
                <AccountForm {...account} onUserDelete={onAccountDelete}/>

                <SyncFromCrmConfirmationDialog open={isSyncFromCrmDialogOpen}
                                               accountId={id}
                                               actionComplete={onSyncFromCrmComplete}
                                               close={() => setIsSyncFromCrmDialogOpen(prev => !prev)}/>
            </div>
        </FormLayout>
    );
};

export default Account;