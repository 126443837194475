import React, {useEffect, useState} from 'react';
import {Outlet} from "react-router-dom";
import Sidebar from "../../components/ui/components/Sidebar/Sidebar";
import cl from "./AuthorizedLayout.module.css";
import LoadingScreen from "../../components/ui/components/LoadingScreen/LoadingScreen";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useGetCurrentUserQuery} from "../../features/application/applicationApiSlice";
import {useDispatch} from "react-redux";
import {logOut, setUser} from "../../features/authentication/authenticationSlice";
import AppErrorBoundary from "../../components/ui/boundaries/AppErrorBoundary/AppErrorBoundary";

const AuthorizedLayout = () => {
    const [loading, setLoading] = useState(true);
    const {data: user, isLoading} = useGetCurrentUserQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLoading(false);
        }, 1000);


        return () => {
            clearTimeout(timeoutId);
        }
    }, [])

    useEffect(() => {
        if (user) {
            if (user.permission) {
                dispatch(setUser({user}))
            } else {
                dispatch(logOut());
            }
        }
    }, [user, dispatch]);

    if (!user || loading || isLoading) {
        return <LoadingScreen/>
    }

    return (
        <div className={cl.applicationContainer}>
            <AppErrorBoundary>
                <ToastContainer position="top-right"
                                autoClose={2000}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick={false}
                                rtl={false}
                                pauseOnFocusLoss={false}
                                draggable={false}
                                pauseOnHover
                                bodyClassName='toast-class'
                                theme="light"/>
                <Sidebar/>
                <main>
                    <Outlet/>
                </main>
            </AppErrorBoundary>
        </div>
    );
};

export default AuthorizedLayout;