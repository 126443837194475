import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Query} from "@syncfusion/ej2-data";
import DropDownList from "../../../../DropDownList/DropDownList";
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";
import Input from "../../../../Input/Input";

const SectionSection = ({
                            isLoading,
                            sectionValue,
                            parking,
                            buildingTypeValue,
                            streetValue,
                            buildingNumberValue,
                            onSectionChange,
                            onBuildingTypeChange,
                            onBuildingChange,
                            onStreetChange,
                            onParkingChange,
                        }) => {
    const [streetQuery, setStreetQuery] = useState(sectionValue !== null ? new Query().addParams("sectionId", sectionValue) : null);
    const [buildingQuery, setBuildingQuery] = useState(streetValue !== null ? new Query().addParams("streetId", streetValue) : null);

    const {t} = useTranslation();
    const buildingTypes = [
        {id: 0, text: t('locationTarget.cottage')},
        {id: 1, text: t('locationTarget.multi-storey')}
    ];

    useEffect(() => {
        if (sectionValue) {
            if (streetQuery && streetQuery.params.length > 0) {
                if (streetQuery.params.find(item => item.key === 'sectionId' && item.value !== sectionValue)) {
                    setStreetQuery(new Query().addParams("sectionId", sectionValue));
                }
            } else {
                setStreetQuery(new Query().addParams("sectionId", sectionValue));
            }
        }
    }, [sectionValue]);

    useEffect(() => {
        if (streetValue) {
            if (buildingQuery && buildingQuery.params.length > 0) {
                if (buildingQuery.params.find(item => item.key === 'streetId' && item.value !== streetValue)) {
                    setBuildingQuery(new Query().addParams("streetId", streetValue));
                }
            } else {
                setBuildingQuery(new Query().addParams("streetId", streetValue));
            }
        }
    }, [streetValue]);

    const onChange = useCallback((ev) => {
        if (onSectionChange) {
            if (!ev.itemData) {
                onBuildingTypeChange(null);
                onStreetChange(null);
                onBuildingChange(null);
            }
            onSectionChange(ev);
        }
    }, []);

    const onTypeChange = useCallback((ev) => {
        if (onBuildingTypeChange) {
            onBuildingTypeChange(ev);
        }
    }, []);

    const onStreetValueChange = useCallback((ev) => {
        if (onStreetChange) {
            if (!ev.itemData) {
                onBuildingChange(null);
            }
            onStreetChange(ev);
        }
    }, []);

    const onBuildingNumberChange = useCallback((ev) => {
        if (onBuildingChange) {
            onBuildingChange(ev);
        }
    }, []);

    return (
        <div className='target-form-container'>
            <div className='input-container syncfusion-control-input-container'>
                <label className='label required'>
                    {t("locationTarget.section")}
                </label>
                <DropDownList url='/api/target/getSections'
                              popupHeight='250px'
                              change={onChange}
                              enabled={!isLoading}
                              placeholder={t("locationTarget.undefined")}
                              value={sectionValue}
                              showClearButton={true}/>

            </div>
            <div className='target-form-container'>

                    <Input name={'sectionParking'} type={"checkbox"} checked={parking} disabled={isLoading}  onChange={onParkingChange} />
                    <label style={{marginLeft:'5px',verticalAlign: 'top'}} className='label'>
                        {t("locationTarget.parking")}
                    </label>

            </div>
            <div className='input-container syncfusion-control-input-container'>
                <label className='label'>
                    {t("locationTarget.building-type")}
                </label>
                <DropDownListComponent dataSource={buildingTypes}
                                       placeholder={t("locationTarget.undefined")}
                                       popupHeight='250px'
                                       fields={{text: 'text', value: 'id'}}
                                       change={onTypeChange}
                                       enabled={sectionValue && streetValue === null}
                                       value={buildingTypeValue}
                                       showClearButton={true}/>

            </div>
            <div className='input-container syncfusion-control-input-container'>
                <label className='label'>
                    {t("locationTarget.street")}
                </label>
                <DropDownList url='/api/target/getStreets'
                              query={streetQuery}
                              skipIfNoQueryParams={true}
                              popupHeight='250px'
                              enabled={(sectionValue && buildingTypeValue === null)}
                              change={onStreetValueChange}
                              placeholder={t("locationTarget.undefined")}
                              value={streetValue}
                              showClearButton={true}/>
            </div>
            <div className='input-container syncfusion-control-input-container'>
                <label className='label'>
                    {t("locationTarget.home-number")}
                </label>
                <DropDownList url='/api/target/getBuildings'
                              query={buildingQuery}
                              skipIfNoQueryParams={true}
                              popupHeight='250px'
                              enabled={streetValue}
                              change={onBuildingNumberChange}
                              placeholder={t("locationTarget.undefined")}
                              value={buildingNumberValue}
                              showClearButton={true}/>
            </div>
        </div>
    );
};

export default SectionSection;
