import React, {useEffect, useState} from 'react';
import Button, {ButtonType} from "../../../ui/components/Button/Button";
import FormLayout from "../../../ui/layouts/FormLayout/FormLayout";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useServerError} from "../../../../hooks/useServerError";
import PollForm from "./PollForm";
import apiAxios from "../../../../app/api/apiAxios";
import ResultCode from "../../../../data/enums/ResultCode";
import getServerErrorResourceName from "../../../../data/apiErrors/apiErrors";
import {toast} from "react-toastify";

const initialState = {
    id: 0,
    forCommercialProperty: false,
    forResidents: false,
    hasIncidents: false,
    hasServices: false,
    hasPayments: false,
    isBlocked: false,
    isPriority: false,
    isPublished: false,
    name: '',
    period: null,
    popupContentText: '',
    popupHeaderText: '',
    pushNotificationText: '',
    codeName: '',
    pollQuestions: [],
    targets: null
};

const Poll = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [poll, setPoll] = useState(initialState);
    const [serverError, setServerError] = useServerError(null);

    useEffect(() => {
        (async () => await load(id))();
    }, [id]);

    const load = async (id) => {
        if (id && id > 0) {
            setServerError(null);
            setPoll(initialState);
            setIsLoading(true);

            const url = `${process.env.REACT_APP_BE_DOMAIN}/api/poll/getPoll?id=${id}`;

            try {
                const response = await apiAxios.get(url);

                if (response) {
                    if (response.status === 200 && response.data) {
                        if (response.data.resultCode === ResultCode.Success) {
                            setPoll(response.data);
                        } else {
                            setServerError(t(getServerErrorResourceName({
                                model: 'poll',
                                resultCode: response.data.resultCode
                            })));
                        }
                    }
                }

                setIsLoading(false);
            } catch (e) {
                if (e && e.message) {
                    setServerError(`${t(getServerErrorResourceName({
                        model: 'poll',
                        resultCode: ResultCode.ServerError
                    }))} Message: (${e.message})`);
                }
                setIsLoading(false);
            }
        }
    }

    const save = async (values, actions) => {
        try {
            setServerError(null);
            setIsLoading(true);
            actions.setSubmitting(true);

            const response = await apiAxios.post(`${process.env.REACT_APP_BE_DOMAIN}/api/poll/save`, {
                id: poll.id,
                name: values.name,
                forCommercialProperty: values.forCommercialProperty,
                forResidents: values.forResidents,
                hasIncidents: values.hasIncidents,
                hasServices: values.hasServices,
                hasPayments: values.hasPayments,
                isBlocked: values.isBlocked,
                isPriority: values.isPriority,
                period: values.period,
                popupContentText: values.popupContentText,
                popupHeaderText: values.popupHeaderText,
                pushNotificationText: values.pushNotificationText,
                targets: values.targets,
                pollQuestions: values.pollQuestions,
                codeName: values.codeName
            });

            if (response) {
                if (response.status === 200 && response.data) {
                    if (response.data.resultCode === ResultCode.Success) {
                        toast.success(t("poll.form.saved-label"));

                        if (response.data.description) {
                            await load(+response.data.description);
                        }
                    } else {
                        setServerError(t(getServerErrorResourceName({
                            model: 'poll',
                            resultCode: response.data.resultCode,
                            description: response.data.description
                        })));
                    }
                }
            }

            setIsLoading(false);
            actions.setSubmitting(false);
        } catch (err) {
            if (err && err.message) {
                setServerError(`${t(getServerErrorResourceName({
                    model: 'poll',
                    resultCode: ResultCode.ServerError
                }))} Message: (${err.message})`);
            }
            setIsLoading(false);
            actions.setSubmitting(false);
        }
    }

    const publish = () => {
        (async () => {
            try {
                setServerError(null);
                setIsLoading(true);

                const response = await apiAxios.post(`${process.env.REACT_APP_BE_DOMAIN}/api/poll/publish`, {
                    id: poll.id,
                });

                if (response) {
                    if (response.status === 200 && response.data) {
                        if (response.data.resultCode === ResultCode.Success) {
                            toast.success(t("poll.form.published-label"));

                            await load(poll.id);
                        } else {
                            setServerError(t(getServerErrorResourceName({
                                model: 'poll',
                                resultCode: response.data.resultCode,
                                description: response.data.description
                            })));
                        }
                    }
                }

                setIsLoading(false);
            } catch (err) {
                if (err && err.message) {
                    setServerError(`${t(getServerErrorResourceName({
                        model: 'poll',
                        resultCode: ResultCode.ServerError
                    }))} Message: (${err.message})`);
                }
                setIsLoading(false);
            }
        })();
    };

    return (
        <FormLayout header={<div className='default-form-layout-header'>{t("poll.form.form-label")}</div>}
                    footer={(
                        <div className='default-form-layout-footer'>
                            <Button buttonType={ButtonType.Back}
                                    onClick={() => navigate(-1)}
                                    disabled={isLoading}>
                                {t("btn.back")}
                            </Button>
                            <div>
                                {poll && poll.id && poll.id > 0 && !poll.isPublished
                                    ? <Button buttonType={ButtonType.Default}
                                              onClick={() => publish()}
                                              type='button'
                                              disabled={isLoading}
                                              style={{marginRight: '20px'}}>
                                        {t("btn.publish")}
                                    </Button>
                                    : null
                                }
                                <Button buttonType={ButtonType.Default}
                                        form='poll-form'
                                        type='submit'
                                        disabled={isLoading}>
                                    {t("btn.save")}
                                </Button>
                            </div>
                        </div>
                    )}
                    footerPosition='top'
                    isLoading={isLoading}>
            <div className='default-form-layout-container'>
                {serverError &&
                    <div className='server-error-container'>
                        <div>
                            {serverError}
                        </div>
                    </div>
                }
                <PollForm {...poll} onSubmit={save}/>
            </div>
        </FormLayout>
    );
};

export default Poll;