import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import {v4 as uuidv4} from 'uuid';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: 'ru',
        fallbackLng: 'ru',
        backendOptions: [{
            expirationTime: 24 * 60 * 60 * 1000 * 7,
            defaultVersions: `v${uuidv4()}`
        }]
    });

export default i18n;