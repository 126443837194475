import React from 'react';
import {useTranslation} from "react-i18next";
import DropdownGridFilter from "../../../../ui/components/Grid/filters/DropdownGridFilter";

const UserLogResultCodeFilter = ({onFilterChange, ...rest}) => {
    const {t} = useTranslation();

    const resultCodeFilterData = [
        {id: '0', text: t("userLog.userLogResultCode.0")},
        {id: '1', text: t("userLog.userLogResultCode.1")},
        {id: '2', text: t("userLog.userLogResultCode.2")},
    ];

    return <DropdownGridFilter onChange={onFilterChange} {...rest} data={resultCodeFilterData}/>
};

export default UserLogResultCodeFilter;