import React, {useCallback} from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Col, Container, Row} from "react-bootstrap";
import Input from "../../../../../ui/components/Input/Input";
import PostSourceDropDown from "./PostSourceDropDown/PostSourceDropDown";

const NewsGrabberSettingDetails = ({site, siteCode, sourceId, isBlocked, onSubmit}) => {
    const {t} = useTranslation();

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            site: site,
            siteCode: siteCode,
            sourceId: sourceId,
            isBlocked: isBlocked
        },
        validationSchema: Yup.object({
            site: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("setting.form.site"))),
            siteCode: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("setting.form.site-code"))),
            sourceId: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("setting.form.source"))),
        }),
        onSubmit: onSubmit
    });

    const onPostSourceChange = useCallback((param, value) => {
        form.setFieldValue(param, value);
    }, []);

    return (
        <Container fluid>
            <form id='grabber-setting-form' onSubmit={form.handleSubmit}>
                <Row>
                    <Col>
                        <div className='input-container'>
                            <label className='label required'>
                                {t("setting.form.site")}
                            </label>
                            <Input name='site'
                                   type='text'
                                   placeholder={t("setting.form.site")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.site}/>
                            {form.touched.site && form.errors.site ?
                                <div className='field-error'>{form.errors.site}</div> : null}
                        </div>

                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label required'>
                                {t("setting.form.source")}
                            </label>
                            <PostSourceDropDown isSubmitting={form.isSubmitting}
                                                value={form.values.sourceId}
                                                onChange={onPostSourceChange}/>
                            {form.touched.sourceId && form.errors.sourceId ?
                                <div className='field-error'>{form.errors.sourceId}</div> : null}
                        </div>

                        <div className='input-container'>
                            <label className='label required'>
                                {t("setting.form.site-code")}
                            </label>
                            <Input name='siteCode'
                                   placeholder={t("setting.form.site-code")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   type='number'
                                   value={form.values.siteCode}/>
                            {form.touched.siteCode && form.errors.siteCode ?
                                <div className='field-error'>{form.errors.siteCode}</div> : null}
                        </div>

                        <div className='input-container checkbox'>
                            <Input name='isBlocked'
                                   type='checkbox'
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   checked={form.values.isBlocked}/>
                            <label className='label'>
                                {t("setting.form.blocked")}
                            </label>
                        </div>
                    </Col>
                </Row>
            </form>
        </Container>
    );
};

export default NewsGrabberSettingDetails;