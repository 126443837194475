import React from 'react';
import cl from './UsersChartSelectorSection.module.css';
import {DateRangePickerComponent} from "@syncfusion/ej2-react-calendars";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExport} from "@fortawesome/free-solid-svg-icons";

const UsersChartSelectorSection = ({isLoading, startDate, endDate, onDateChange, onExportBtnClick}) => {
    const {t} = useTranslation();

    return (
        <div className={cl.selectorsContainer}>
            <div>
                <button className={cl.exportBtn} onClick={onExportBtnClick}>
                    <span>
                        <FontAwesomeIcon icon={faFileExport}/>
                    </span>
                    <span>
                        {t("btn.export")}
                    </span>
                </button>
            </div>
            <div>
                <div>
                    <div className={cl.selectorLabel}>
                        {t("analytics.grid.period")}
                    </div>
                    <div>
                        <DateRangePickerComponent id='chart-date-range-picker'
                                                  width='300px'
                                                  change={onDateChange}
                                                  startDate={startDate}
                                                  endDate={endDate}
                                                  format='dd.MM.yyyy'
                                                  enabled={!isLoading}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UsersChartSelectorSection;