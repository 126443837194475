import React from 'react';
import cl from './FormLayout.module.css';

const FormLayout = ({header, footer, children, isLoading, footerPosition = 'bottom'}) => {
    return (
        <div className={cl.formContainer}>
            {header &&
                <div className={cl.header}>
                    {header}
                </div>
            }
            {footer && footerPosition === 'top' &&
                <div className={`${cl.footer} ${cl.footerTop}`}>
                    {footer}
                </div>
            }
            <div className={`${cl.content} ${!footer ? cl.contentFw : ''} ${isLoading ? cl.disabled : ''}`}>
                {isLoading &&
                    <div className={cl.loaderContainer}>
                        <div className={`loader light ${cl.formLoader}`}>

                        </div>
                    </div>
                }
                {children}
            </div>
            {footer && footerPosition === 'bottom' &&
                <div className={`${cl.footer} ${cl.footerBottom}`}>
                    {footer}
                </div>
            }
        </div>
    );
};

export default FormLayout;