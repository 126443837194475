import React from 'react';
import cl from './LoginInput.module.css';

const LoginInput = ({name, label, validationError, ...rest}) => {
    return (
        <div className={cl.container}>
            <input {...rest} />
            <label htmlFor={name}>
                {label}
            </label>
            {validationError &&
                <div className={cl.error}>
                    {validationError}
                </div>
            }
        </div>
    );
};

export default LoginInput;