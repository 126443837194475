import React from 'react';
import {useTranslation} from "react-i18next";
import {ColumnDirective, ColumnsDirective, GridComponent} from "@syncfusion/ej2-react-grids";

const UserFormRequestsGrid = ({requests}) => {
    const {t} = useTranslation();

    return (
        <GridComponent dataSource={requests}
                       id='user-accounts-requests-grid'
                       height='300px'
                       locale='ru-RU'
                       width='100%'>
            <ColumnsDirective>
                <ColumnDirective field='id'
                                 visible={false}
                                 textAlign="center"
                                 isPrimaryKey={true}
                                 type='number'/>
                <ColumnDirective field='accountNumber'
                                 headerText={t("user.form.request.account")}
                                 width='40%'
                                 textAlign="center"/>
                <ColumnDirective field='ownerName'
                                 headerText={t("user.form.request.name")}
                                 width='40%'
                                 textAlign="center"/>
                <ColumnDirective field='verificationPhoneNumber'
                                 headerText={t("user.form.request.phone")}
                                 width='40%'
                                 textAlign="center"/>
            </ColumnsDirective>
        </GridComponent>
    );
};

export default UserFormRequestsGrid;