import React from 'react';
import cl from './TargetList.module.css';
import TargetListItem from "./TargetListItem/TargetListItem";

const TargetList = ({items, onEditButtonClick, onRemoveButtonClick}) => {
    return (
        <div className={cl.targetList}>
            {items && items.length > 0 &&
                items.map(item => {
                    return <TargetListItem key={item.id}
                                           id={item.id}
                                           label={item.label}
                                           onEditButtonClick={onEditButtonClick}
                                           onRemoveButtonClick={onRemoveButtonClick}/>
                })
            }
        </div>
    );
};

export default TargetList;