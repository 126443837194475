import {apiSlice} from "../../app/api/apiSlice";

export const applicationApiSlice = apiSlice.injectEndpoints({
    endpoints: build => ({
        getCurrentUser: build.query({
            query: () => 'api/admin/getCurrentUser',
            keepUnusedDataFor: 2,
        }),
        changePassword: build.mutation({
            query: (data) => ({
                url: 'api/admin/changePassword',
                method: 'POST',
                body: {...data}
            })
        })
    })
});

export const {useGetCurrentUserQuery, useChangePasswordMutation} = applicationApiSlice;