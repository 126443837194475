import React from 'react';
import moment from "moment";

const PageDateTemplate = (props) => {
    if (!props[props.column.field]) {
        return null;
    }

    const date = moment(new Date(Date.UTC(
        props[props.column.field].getFullYear(),
        props[props.column.field].getMonth(),
        props[props.column.field].getDay(),
        props[props.column.field].getHours(),
        props[props.column.field].getMinutes(),
        props[props.column.field].getSeconds(),
        props[props.column.field].getMilliseconds()
    ))).format('DD.MM.YYYY HH:mm');

    return (
        <div>
            {date}
        </div>
    );
};

export default PageDateTemplate;