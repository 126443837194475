import React from 'react';
import {useTranslation} from "react-i18next";

const UserLogActivityCodeTemplate = (props) => {
    const {t} = useTranslation();

    if (!props[props.column.field]) {
        return null;
    }


    return (
        <div>
            {t(`userLog.userLogActivityCode.${props.activityCode}`)}
        </div>
    );
};

export default UserLogActivityCodeTemplate;