import React, {useEffect, useState} from 'react';
import {DateRangePickerComponent} from "@syncfusion/ej2-react-calendars";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectEntityFilterValue} from "../../../../../features/filter/filterSelectors";

const DateRangeGridFilter = ({column, onChange, filterStoreEntityName}) => {
    const {i18n} = useTranslation();
    const [data, setData] = useState(null);

    const value = useSelector(state => selectEntityFilterValue(state, {
        type: filterStoreEntityName,
        field: column.field
    }));

    useEffect(() => {
        if (value && value.value) {
            const obj = JSON.parse(value.value);
            setData({
                sDate: moment(obj.sDate, "DD.MM.YYYY HH:mm").toDate(),
                eDate: moment(obj.eDate, "DD.MM.YYYY HH:mm").toDate()
            });
        } else {
            setData(null);
        }
    }, [value]);

    const onDateSelect = (ev) => {
        if (!ev.startDate && !ev.endDate) {
            onChange({value: null}, column.field)
        } else {
            const obj = JSON.stringify({
                sDate: `${moment(ev.startDate).format("DD.MM.YYYY")} 00:00`,
                eDate: `${moment(ev.endDate).format("DD.MM.YYYY")} 23:59`,
            });

            onChange({
                value: obj
            }, column.field)
        }
    }

    return (
        <DateRangePickerComponent enabled={column.allowFiltering}
                                  id={column.field}
                                  showClearButton={true}
                                  startDate={data?.sDate}
                                  endDate={data?.eDate}
                                  change={onDateSelect}
                                  locale={`${i18n.language}-${i18n.language.toUpperCase()}`}>

        </DateRangePickerComponent>
    );
};

export default DateRangeGridFilter;