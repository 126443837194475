import React, {useEffect, useState} from 'react';
import cl from './ProgressBarToast.module.css';

const ProgressBarToast = ({show, percentage}) => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(show);
    }, [show]);

    return (
        <div className={`${cl.progressBarToast} ${isActive ? cl.active : ''}`}>
            <div className={cl.progressBar}>
                <div className={cl.progressBarFiller} style={{width: `${percentage}%`}}>
                </div>
                <span className={cl.progressBarLabel}>{`${percentage}%`}</span>
            </div>
        </div>
    );
};

export default ProgressBarToast;