import React, {useState} from 'react';
import cl from './Login.module.css';
import logo from '../../../../assets/images/logo.png'
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {useLoginMutation} from "../../../../features/authentication/authenticationApiSlice";
import ResultCode from "../../../../data/enums/ResultCode";
import {encode} from "js-base64"
import {useDispatch} from "react-redux";
import {setCredentials} from "../../../../features/authentication/authenticationSlice";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import LoginInput from "../../../ui/components/LoginInput/LoginInput";
import getServerErrorResourceName from "../../../../data/apiErrors/apiErrors";

const Login = () => {
    const [serverError, setServerError] = useState(null);
    const {t} = useTranslation();
    const [login] = useLoginMutation();
    const dispatch = useDispatch();

    const form = useFormik({
        initialValues: {
            login: '',
            password: ''
        },
        validationSchema: Yup.object({
            login: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("login.labels.login"))),
            password: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("login.labels.password"))),
        }),
        onSubmit: async (values, actions) => {
            try {
                setServerError(null);

                const response = await login(
                    {
                        login: values.login,
                        password: values.password
                    });

                if (response.data) {
                    if (response.data.resultCode === ResultCode.Success) {
                        const authData = {
                            token: response.data.token,
                            refreshToken: response.data.refreshToken,
                            expiry: response.data.expiry,
                            user: response.data.user
                        };

                        localStorage.setItem('_d', encode(JSON.stringify(authData)));

                        actions.setSubmitting(false);

                        dispatch(setCredentials(authData));
                    } else {
                        setServerError(t(getServerErrorResourceName({
                            model: 'login',
                            resultCode: response.data.resultCode,
                            description: response.data.description
                        })));
                    }
                } else if (response.error) {
                    setServerError(t(getServerErrorResourceName({
                        model: 'login',
                        resultCode: ResultCode.ServerError
                    })));
                }
            } catch (error) {
                setServerError(t(getServerErrorResourceName({
                    model: 'login',
                    resultCode: ResultCode.ServerError
                })));
            }
        },
    });


    return (
        <div className={cl.loginContainer}>
            <div className={cl.bounce}>
                <div>
                    <img src={logo} alt='logo'/>
                </div>
                <div>
                    {t("login.labels.header-label")}
                </div>
                <div>
                    <form onSubmit={form.handleSubmit}>
                        <div className={cl.controlContainer}>
                            <LoginInput id="login"
                                        name="login"
                                        autoComplete="login"
                                        type='text'
                                        disabled={form.isSubmitting}
                                        onChange={form.handleChange}
                                        value={form.values.login}
                                        data-validate={form.values.login.length > 0 ? 'valid' : 'invalid'}
                                        label={t("login.labels.login")}
                                        validationError={form.touched.login && form.errors.login ? form.errors.login : null}/>
                        </div>

                        <div className={cl.controlContainer}>
                            <LoginInput id="password"
                                        name="password"
                                        type="password"
                                        disabled={form.isSubmitting}
                                        autoComplete="current-password"
                                        onChange={form.handleChange}
                                        value={form.values.password}
                                        data-validate={form.values.password.length > 0 ? 'valid' : 'invalid'}
                                        label={t("login.labels.password")}
                                        validationError={form.touched.password && form.errors.password ? form.errors.password : null}/>

                        </div>

                        <div className={cl.buttonContainer}>
                            <button type='submit'
                                    disabled={form.isSubmitting}>
                                {form.isSubmitting &&
                                    <span className={cl.loadingSpan}>
                                        <FontAwesomeIcon icon={faSpinner}/>
                                    </span>
                                }
                                {t("login.btn.sign-in")}
                            </button>
                        </div>
                    </form>
                </div>
                <div>
                  {/*  <NavLink to='restore'>
                        {t("login.btn.restore-access")}
                    </NavLink>*/}
                </div>
                {serverError &&
                    <div className={cl.serverErrorContainer}>
                        <div>
                            {serverError}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
};

export default Login;