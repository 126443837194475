import React from 'react';
import {
    SeriesCollectionDirective,
    SeriesDirective,
    StackingLineSeries,
    Legend,
    Tooltip,
    DataLabel,
    ChartComponent,
    DateTime
} from "@syncfusion/ej2-react-charts";
import {Inject} from "@syncfusion/ej2-react-grids";
import cl from "../../../ActiveUsersChart/ChartSection/ActiveUsersChartSection.module.css";

const UsersRegistrationsChart = ({isLoading, data}) => {
    const primaryXAxis = {valueType: 'DateTime'};
    const primaryYAxis = {minimum: 0, interval: 10};
    return (
        <div className={cl.chartContainer}>
            {isLoading &&
                <div className={cl.loaderContainer}>
                    <div className='loader light'>

                    </div>
                </div>
            }
            <ChartComponent id='user-reg-chart'
                            primaryXAxis={primaryXAxis}
                            primaryYAxis={primaryYAxis}
                            palettes={["#7ac834"]}
                            tooltip={{enable: true}}>
                <Inject services={[StackingLineSeries, Legend, Tooltip, DataLabel, DateTime]}/>
                <SeriesCollectionDirective>
                    <SeriesDirective dataSource={data}
                                     xName='xValue'
                                     yName='yValue'
                                     width={2}
                                     type='StackingLine'
                                     marker={{visible: true, width: 10, height: 10}}>
                    </SeriesDirective>
                </SeriesCollectionDirective>
            </ChartComponent>
        </div>
    );
};

export default UsersRegistrationsChart;