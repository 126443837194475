import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useServerError} from "../../../../hooks/useServerError";
import apiAxios from "../../../../app/api/apiAxios";
import ResultCode from "../../../../data/enums/ResultCode";
import getServerErrorResourceName from "../../../../data/apiErrors/apiErrors";
import Button, {ButtonType} from "../../../ui/components/Button/Button";
import FormLayout from "../../../ui/layouts/FormLayout/FormLayout";
import UserForm from "./UserForm";
import SyncFromCrmConfirmationDialog from "./components/SyncFromCrmConfirmationDialog/SyncFromCrmConfirmationDialog";
import SyncToCrmConfirmationDialog from "./components/SyncToCrmConfirmationDialog/SyncToCrmConfirmationDialog";
import GridTabSection, {GridTabSectionType} from "../../../ui/components/Grid/GridTab/GridTabSection/GridTabSection";
import GridTabItem from "../../../ui/components/Grid/GridTab/GridTabItem/GridTabItem";
import GridTab from "../../../ui/components/Grid/GridTab/GridTab";
import cl from './User.module.css';
import UserLogsGrid from "./components/UserLogsGrid/UserLogsGrid";
import {toast} from "react-toastify";

const initialState = {
    id: 0,
    name: '',
    surname: '',
    middleName: '',
    crmId: '',
    phone: '',
    email: '',
    notes: '',
    isBlocked: false,
    birthdate: null,
    isAllowVote: false,
    isAllowCheckLists: false,
    userSetting: {
        showAllIncidents: false,
        stopNewsNotification: false,
    },
    accountRequests: [],
    accounts: []
}

const User = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isSendToCrmDialogOpen, setIsSendToCrmDialogOpen] = useState(false);
    const [isSyncFromCrmDialogOpen, setIsSyncFromCrmDialogOpen] = useState(false);
    const [user, setUser] = useState(initialState);
    const [serverError, setServerError] = useServerError(null);
    const [tab, setTab] = useState(0);

    useEffect(() => {
        (async () => await load(id))();
    }, [id]);

    const load = async (id) => {
        if (id && id > 0) {
            setServerError(null);
            setUser(initialState);
            setIsLoading(true);

            const url = `${process.env.REACT_APP_BE_DOMAIN}/api/user/getUser?id=${id}`;

            try {
                const response = await apiAxios.get(url);

                if (response) {
                    if (response.status === 200 && response.data) {
                        if (response.data.resultCode === ResultCode.Success) {
                            setUser(response.data);
                        } else {
                            setServerError(t(getServerErrorResourceName({
                                model: 'user',
                                resultCode: response.data.resultCode
                            })));
                        }
                    }
                }

                setIsLoading(false);
            } catch (e) {
                if (e && e.message) {
                    setServerError(`${t(getServerErrorResourceName({
                        model: 'user',
                        resultCode: ResultCode.ServerError
                    }))} Message: (${e.message})`);
                }
                setIsLoading(false);
            }
        }
    };

    const onAccountDelete = (account) => {
        if (!account) {
            setServerError('Account cannot be deleted');
        } else {
            (async () => {
                try {
                    setServerError(null);
                    setIsLoading(true);

                    const response = await apiAxios.post(`${process.env.REACT_APP_BE_DOMAIN}/api/userAccount/delete`, {
                        id: account.id,
                    });

                    if (response) {
                        if (response.status === 200 && response.data) {
                            if (response.data.resultCode === ResultCode.Success) {
                                toast.success(t("user.form.account-deleted-label"));

                                await load(id);
                            } else {
                                setServerError(t(getServerErrorResourceName({
                                    model: 'user',
                                    resultCode: response.data.resultCode,
                                    description: response.data.description
                                })));
                            }
                        }
                    }

                    setIsLoading(false);
                } catch (err) {
                    if (err && err.message) {
                        setServerError(`${t(getServerErrorResourceName({
                            model: 'user',
                            resultCode: ResultCode.ServerError
                        }))} Message: (${err.message})`);
                    }
                    setIsLoading(false);
                }
            })();
        }
    }

    const onSyncFromCrmComplete = () => {
        setIsSyncFromCrmDialogOpen(prev => !prev);

        (async () => await load(id))();
    };

    const onSyncToCrmComplete = () => {
        setIsSendToCrmDialogOpen(prev => !prev);

        (async () => await load(id))();
    };

    const onTabItemClick = ({queryKey}) => {
        setTab(queryKey);
    };

    const deleteUser = () => {
        (async () => {
            try {
                setServerError(null);
                setIsLoading(true);

                const response = await apiAxios.post(`${process.env.REACT_APP_BE_DOMAIN}/api/user/delete`, {
                    id: user.id
                });

                if (response) {
                    if (response.status === 200 && response.data) {
                        if (response.data.resultCode === ResultCode.Success) {
                            toast.success(t("page.form.deleted-label"));

                            navigate(-1);
                        } else {
                            setServerError(t(getServerErrorResourceName({
                                model: 'user',
                                resultCode: response.data.resultCode,
                                description: response.data.description
                            })));
                        }
                    }
                }

                setIsLoading(false);
            } catch (err) {
                if (err && err.message) {
                    setServerError(`${t(getServerErrorResourceName({
                        model: 'user',
                        resultCode: ResultCode.ServerError
                    }))} Message: (${err.message})`);
                }
                setIsLoading(false);
            }
        })();
    };

    return (
        <FormLayout header={<div className='default-form-layout-header'>{t("user.form.form-label")}</div>}
                    footer={(
                        <div className='default-form-layout-footer'>
                            <Button buttonType={ButtonType.Back}
                                    onClick={() => navigate(-1)}
                                    disabled={isLoading}>
                                {t("btn.back")}
                            </Button>
                            {user && user.id && user.id > 0
                                ? <Button buttonType={ButtonType.Warning}
                                          onClick={() => deleteUser()}
                                          type='button'
                                          disabled={isLoading}>
                                    {t("btn.delete")}
                                </Button>
                                : null
                            }
                            {user && user.id && user.id > 0
                                ? <div>
                                    <Button buttonType={ButtonType.Default}
                                            onClick={() => setIsSendToCrmDialogOpen(prev => !prev)}
                                            style={{marginRight: '5px'}}
                                            disabled={isLoading}>
                                        {t("user.btn.sync-to-crm")}
                                    </Button>
                                    <Button buttonType={ButtonType.Default}
                                            style={{marginLeft: '5px'}}
                                            onClick={() => setIsSyncFromCrmDialogOpen(prev => !prev)}
                                            disabled={isLoading}>
                                        {t("user.btn.sync-from-crm")}
                                    </Button>
                                </div>
                                : null
                            }

                        </div>
                    )}
                    footerPosition='top'
                    isLoading={isLoading}>
            <div className={`${cl.formContainer} user-form-container`}>
                {serverError &&
                    <div className='server-error-container'>
                        <div>
                            {serverError}
                        </div>
                    </div>
                }
                <GridTab onClick={onTabItemClick}>
                    <GridTabSection type={GridTabSectionType.Tab}
                                    queryParameterName='tab'
                                    setQueryParams={false}>
                        <GridTabItem id='personal'
                                     text={t("user.form.data-tab")}
                                     queryKey={0}
                                     isDefaultActive={true}>

                        </GridTabItem>
                        <GridTabItem id='activity'
                                     text={t("user.form.activity-tab")}
                                     queryKey={1}>

                        </GridTabItem>
                    </GridTabSection>
                </GridTab>
                {tab === 0 &&
                    <UserForm user={user} onAccountDelete={onAccountDelete}/>
                }

                {tab === 1 &&
                    <UserLogsGrid userId={user.id}
                                  phone={user.phone}
                                  surname={user.surname}/>
                }

                <SyncFromCrmConfirmationDialog open={isSyncFromCrmDialogOpen}
                                               userId={id}
                                               actionComplete={onSyncFromCrmComplete}
                                               close={() => setIsSyncFromCrmDialogOpen(prev => !prev)}/>
                <SyncToCrmConfirmationDialog open={isSendToCrmDialogOpen}
                                             userId={id}
                                             actionComplete={onSyncToCrmComplete}
                                             close={() => setIsSendToCrmDialogOpen(prev => !prev)}/>
            </div>
        </FormLayout>
    );
};

export default User;
