import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSelectors";
import {Query} from "@syncfusion/ej2-data";
import GridTabItem from "../../../ui/components/Grid/GridTab/GridTabItem/GridTabItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PermissionType from "../../../../data/enums/PermissionType";
import GridTabSection, {GridTabSectionType} from "../../../ui/components/Grid/GridTab/GridTabSection/GridTabSection";
import {faAdd, faRefresh} from "@fortawesome/free-solid-svg-icons";
import GridTab from "../../../ui/components/Grid/GridTab/GridTab";
import Loader from "../../../ui/components/Loader/Loader";
import GridComponent from "../../../ui/components/Grid/GridComponent";
import {ColumnDirective, ColumnsDirective, Filter, Inject, Page, Sort} from "@syncfusion/ej2-react-grids";
import BannerDateTemplate from "./templates/BannerDateTemplate";
import DateRangeGridFilter from "../../../ui/components/Grid/filters/DateRangeGridFilter";
import BannerStateFilter from "./filters/BannerStateFilter";
import {useNavigate} from "react-router-dom";

const Banner = () => {
    const [query, setQuery] = useState(null);
    const [tab, setTab] = useState('0');
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [filterTrigger, setFilterTrigger] = useState(null);
    const {t} = useTranslation();
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();

    const onTabItemClick = ({id, queryParameterName, queryKey}) => {
        if (queryParameterName && queryKey) {
            setTab(queryKey);
        } else if (id) {
            if (id === 'refresh-btn') {
                setRefreshTrigger(prev => prev + 1);
            }
            if (id === 'add-btn') {
                navigate(`/news/banners/banner/`);
            }
        }
    }

    useEffect(() => {
        setQuery(prev => {
            let q = new Query();
            if (prev) {
                if (prev && prev.params && prev.params.length > 0) {
                    const params = prev.params.filter(item => item.key !== 'tab');
                    for (const item of params) {
                        q.addParams(item.key, item.value);
                    }
                }

                q.addParams('tab', tab);
            } else {
                const urlParams = new URLSearchParams(window.location.search);
                if (urlParams.get('tab')) {
                    q.addParams('tab', urlParams.get('tab'));
                } else {
                    q.addParams('tab', tab);
                }
            }

            return q;
        });
    }, [tab]);

    const onFilterChange = (ev, fieldName) => setFilterTrigger({
        ev,
        fieldName
    });

    const onRowDoubleClick = ev => {
        if (ev && ev.rowData) {
            navigate(`/news/banners/banner/${ev.rowData.id}`);
        }
    }

    return (
        <div className='list-container'>
            <GridTab onClick={onTabItemClick}>
                <GridTabSection type={GridTabSectionType.Tab}
                                showRightSeparator={true}
                                queryParameterName='tab'>
                    <GridTabItem id='all-tab'
                                 text={t("shared.grid.all-tab")}
                                 queryKey='0'
                                 isDefaultActive={true}>

                    </GridTabItem>
                    <GridTabItem id='published-tab'
                                 text={t("banner.grid.published-tab")}
                                 queryKey='1'>

                    </GridTabItem>
                    <GridTabItem id='unpublished-tab'
                                 text={t("banner.grid.not-published-tab")}
                                 queryKey='2'>

                    </GridTabItem>
                </GridTabSection>
                <GridTabSection type={GridTabSectionType.Button}>
                    {user.permission.views === PermissionType.Editing
                        ? (
                            <GridTabItem id='add-btn'
                                         text={t("btn.add")}
                                         icon={<FontAwesomeIcon icon={faAdd}/>}>

                            </GridTabItem>
                        )
                        : null
                    }
                    <GridTabItem id='refresh-btn'
                                 text={t("btn.refresh")}
                                 icon={<FontAwesomeIcon icon={faRefresh}/>}>

                    </GridTabItem>
                </GridTabSection>
            </GridTab>
            {!query
                ? <Loader/>
                : <GridComponent id='grid'
                                 container='.list-container'
                                 url='/api/banner/getBanners'
                                 query={query}
                                 filterStoreEntityName={'banner'}
                                 refresh={refreshTrigger}
                                 onRowClick={onRowDoubleClick}
                                 filter={filterTrigger}>
                    <ColumnsDirective>
                        <ColumnDirective field='id'
                                         visible={false}
                                         textAlign="center"
                                         isPrimaryKey={true}
                                         type='number'/>
                        <ColumnDirective field='name'
                                         headerText={t("banner.grid.name")}
                                         width='250'
                                         textAlign="center"/>
                        <ColumnDirective field='link'
                                         headerText={t("banner.grid.link")}
                                         width='300'
                                         textAlign="center"/>
                        <ColumnDirective field='targetLabel'
                                         headerText={t("banner.grid.filter")}
                                         width='400'
                                         displayAsCheckBox={true}
                                         textAlign="center"
                                         allowSorting={false}
                                         allowFiltering={false}/>
                        <ColumnDirective field='publicationDate'
                                         headerText={t("banner.grid.publication-date")}
                                         width='200'
                                         textAlign="center"
                                         template={(props) => <div><BannerDateTemplate {...props} /></div>}
                                         filterTemplate={(props) => <DateRangeGridFilter
                                             onChange={onFilterChange} filterStoreEntityName={'banner'} {...props}/>}/>
                        <ColumnDirective field='tillDate'
                                         headerText={t("banner.grid.published-till")}
                                         width='200'
                                         textAlign="center"
                                         template={(props) => <div><BannerDateTemplate {...props} /></div>}
                                         filterTemplate={(props) => <DateRangeGridFilter
                                             onChange={onFilterChange} filterStoreEntityName={'banner'} {...props}/>}/>
                        <ColumnDirective field='showToAnonym'
                                         headerText={t("banner.grid.show-to-anonym-user")}
                                         width='250'
                                         displayAsCheckBox={true}
                                         textAlign="center"
                                         filterTemplate={(props) => <BannerStateFilter
                                             onFilterChange={onFilterChange}
                                             filterStoreEntityName={'banner'} {...props} />}/>
                        <ColumnDirective field='showToResidents'
                                         headerText={t("banner.grid.show-to-residents-user")}
                                         width='250'
                                         displayAsCheckBox={true}
                                         textAlign="center"
                                         filterTemplate={(props) => <BannerStateFilter
                                             onFilterChange={onFilterChange}
                                             filterStoreEntityName={'banner'} {...props} />}/>
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter]}/>
                </GridComponent>
            }
        </div>
    );
}

export default Banner;