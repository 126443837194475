import React from 'react';
import cl from './PostTargetSelector.module.css';
import {useTranslation} from "react-i18next";
import ComboBox from "../../../../../ui/components/ComboBox/ComboBox";
import {useDispatch, useSelector} from "react-redux";
import {selectEntityFilterValue} from "../../../../../../features/filter/filterSelectors";
import {appendFilter, removeFilter} from "../../../../../../features/filter/filterSlice";

const labelStyle = {
    fontSize: '9px'
};

const PostTargetSelector = () => {
    return (
        <div className={cl.container}>
            <div className='syncfusion-control-input-container'>
                <PostHomeOwnerTarget/>
            </div>
            <div className='syncfusion-control-input-container'>
                <PostSubSectionTarget/>
            </div>
            <div className='syncfusion-control-input-container'>
                <PostSectionTarget/>
            </div>
        </div>
    );
};

export default PostTargetSelector;

const PostHomeOwnerTarget = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const value = useSelector(state => selectEntityFilterValue(state, {
        type: 'post',
        field: 'grid-home-owner'
    }));

    const onSelect = (ev) => {
        if (ev && ev.itemData && ev.itemData.id) {
            dispatch(appendFilter({
                type: 'post',
                value: {
                    field: 'grid-home-owner',
                    operator: 'startswith',
                    value: ev.itemData.id,
                    ignoreInGrid: true
                }
            }));
        }
    }

    const onChange = (ev) => {
        if (ev && ev.itemData === null && ev.previousItemData !== null) {
            dispatch(removeFilter({
                type: 'post',
                field: 'grid-home-owner'
            }));
        }
    }

    return (
        <>
            <label className='label' style={labelStyle}>
                {t("locationTarget.home-owner")}
            </label>
            <ComboBox url='/api/target/getHomeOwners'
                      popupHeight='250px'
                      allowFiltering={true}
                      select={onSelect}
                      change={onChange}
                      value={value?.value}
                      placeholder={t("locationTarget.undefined")}
                      showClearButton={true}/>
        </>
    );
}

const PostSectionTarget = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const value = useSelector(state => selectEntityFilterValue(state, {
        type: 'post',
        field: 'grid-section'
    }));

    const onSelect = (ev) => {
        if (ev && ev.itemData && ev.itemData.id) {
            dispatch(appendFilter({
                type: 'post',
                value: {
                    field: 'grid-section',
                    operator: 'startswith',
                    value: ev.itemData.id,
                    ignoreInGrid: true
                }
            }));
        }
    }

    const onChange = (ev) => {
        if (ev && ev.itemData === null && ev.previousItemData !== null) {
            dispatch(removeFilter({
                type: 'post',
                field: 'grid-section'
            }));
        }
    }

    return (
        <>
            <label className='label' style={labelStyle}>
                {t("locationTarget.section")}
            </label>
            <ComboBox url='/api/target/getSections'
                      popupHeight='250px'
                      allowFiltering={true}
                      select={onSelect}
                      change={onChange}
                      value={value?.value}
                      placeholder={t("locationTarget.undefined")}
                      showClearButton={true}/>
        </>
    );
}

const PostSubSectionTarget = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const value = useSelector(state => selectEntityFilterValue(state, {
        type: 'post',
        field: 'grid-sub-section'
    }));

    const onSelect = (ev) => {
        if (ev && ev.itemData && ev.itemData.id) {
            dispatch(appendFilter({
                type: 'post',
                value: {
                    field: 'grid-sub-section',
                    operator: 'startswith',
                    value: ev.itemData.id,
                    ignoreInGrid: true
                }
            }));
        }
    }

    const onChange = (ev) => {
        if (ev && ev.itemData === null && ev.previousItemData !== null) {
            dispatch(removeFilter({
                type: 'post',
                field: 'grid-sub-section'
            }));
        }
    }

    return (
        <>
            <label className='label' style={labelStyle}>
                {t("locationTarget.sub-section")}
            </label>
            <ComboBox url='/api/target/getSubSections'
                      popupHeight='250px'
                      allowFiltering={true}
                      select={onSelect}
                      change={onChange}
                      value={value?.value}
                      placeholder={t("locationTarget.undefined")}
                      showClearButton={true}/>
        </>
    );
}