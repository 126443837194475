import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import FormLayout from "../../../../ui/layouts/FormLayout/FormLayout";
import GridTab from "../../../../ui/components/Grid/GridTab/GridTab";
import GridTabSection, {GridTabSectionType} from "../../../../ui/components/Grid/GridTab/GridTabSection/GridTabSection";
import PermissionType from "../../../../../data/enums/PermissionType";
import GridTabItem from "../../../../ui/components/Grid/GridTab/GridTabItem/GridTabItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faRefresh} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../../../../features/authentication/authenticationSelectors";
import {useNavigate} from "react-router-dom";
import {ColumnDirective, ColumnsDirective} from "@syncfusion/ej2-react-grids";
import GridComponent from "../../../../ui/components/Grid/GridComponent";
import NewsGrabberSettingDialog from "./NewsGrabberSettingDialog/NewsGrabberSettingDialog";

const NewsGrabberSetting = () => {
    const user = useSelector(selectCurrentUser);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [editItemId, setEditItemId] = useState(null);

    const onTabItemClick = ({id}) => {
        if (id) {
            if (id === 'refresh-btn') {
                setRefreshTrigger(prev => prev + 1);
            }

            if (id === 'add-btn') {
                setEditItemId(null);
                setIsEditDialogOpen(true);
            }

            if (id === 'back-btn') {
                navigate(-1);
            }
        }
    }

    const onRowDoubleClick = (ev) => {
        if(ev && ev.rowData){
            setEditItemId(ev.rowData.id);
            setIsEditDialogOpen(true);
        }
    }

    const close = () => {
        setIsEditDialogOpen(false);

        setRefreshTrigger(prev => prev + 1);
    }

    return (
        <FormLayout
            header={<div className='default-form-layout-header'>{t("setting.form.grabber-setting-grid-label")}</div>}>
            <div className='list-container'>
                <GridTab onClick={onTabItemClick}>
                    <GridTabSection type={GridTabSectionType.Button}>
                        <GridTabItem id='back-btn'
                                     text={t("btn.back")}
                                     color='#c73535'>

                        </GridTabItem>
                    </GridTabSection>
                    <GridTabSection type={GridTabSectionType.Button}>
                        <GridTabItem id='refresh-btn'
                                     text={t("btn.refresh")}
                                     icon={<FontAwesomeIcon icon={faRefresh}/>}>

                        </GridTabItem>
                        {user.permission.settings === PermissionType.Editing
                            ? (
                                <GridTabItem id='add-btn'
                                             text={t("btn.add")}
                                             icon={<FontAwesomeIcon icon={faAdd}/>}>

                                </GridTabItem>
                            )
                            : null
                        }
                    </GridTabSection>
                </GridTab>
                <GridComponent id='grid'
                               container='.list-container'
                               allowFiltering={false}
                               allowPaging={false}
                               allowSelection={false}
                               allowSorting={false}
                               url='/api/setting/getGrabberSettings'
                               refresh={refreshTrigger}
                               onRowClick={onRowDoubleClick}>
                    <ColumnsDirective>
                        <ColumnDirective field='id'
                                         visible={false}
                                         textAlign="center"
                                         isPrimaryKey={true}/>
                        <ColumnDirective field='site'
                                         headerText={t("setting.form.site")}
                                         width='40%'
                                         textAlign="center"/>
                        <ColumnDirective field='siteCode'
                                         headerText={t("setting.form.site-code")}
                                         width='40%'
                                         textAlign="center"/>
                        <ColumnDirective field='source'
                                         headerText={t("setting.form.source")}
                                         width='40%'
                                         textAlign="center"/>
                        <ColumnDirective field='isBlocked'
                                         headerText={t("setting.form.blocked")}
                                         width='20%'
                                         displayAsCheckBox={true}
                                         textAlign="center"/>
                    </ColumnsDirective>
                </GridComponent>
                <NewsGrabberSettingDialog open={isEditDialogOpen}
                                          onOverlayClick={() => setIsEditDialogOpen(false)}
                                          close={close}
                                          id={editItemId}/>
            </div>
        </FormLayout>
    );
};

export default NewsGrabberSetting;