import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
};

const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        setLoadingScreenState: (state, {payload}) => {
            state.isLoading = payload;
        }
    }
});

export const {setLoadingScreenState} = applicationSlice.actions;
export default applicationSlice.reducer;