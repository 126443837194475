import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import Target from "../../../ui/components/Target/Target";
import Input from "../../../ui/components/Input/Input";
import cl from './BannerForm.module.css';
import {DateTimePickerComponent, Inject, MaskedDateTime} from "@syncfusion/ej2-react-calendars";
import ImageUploader from "../../../ui/components/ImageUploader/ImageUploader";
import DropDownList from "../../../ui/components/DropDownList/DropDownList";
import GridComponent from "../../../ui/components/Grid/GridComponent";
import {ColumnDirective, ColumnsDirective} from "@syncfusion/ej2-react-grids";
import {Query} from "@syncfusion/ej2-data";
import moment from 'moment';

const BannerForm = ({
                        name,
                        link,
                        showToAnonymous,
                        showToResidents,
                        publicationDate,
                        tillDate,
                        serviceId,
                        subServices,
                        image,
                        imageName,
                        imageSize,
                        targets,
                        onSubmit
                    }) => {
    const {t} = useTranslation();
    const [serviceWorkQuery, setServiceWorkQuery] = useState(null);
    const [selectedServiceWorks, setSelectedServiceWorks] = useState(null);

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name,
            link: link,
            showToAnonymous: showToAnonymous,
            showToResidents: showToResidents,
            publicationDate: publicationDate !== null ? moment.utc(publicationDate).toDate() : null,
            tillDate:  tillDate !== null ? moment.utc(tillDate).toDate() : null,
            serviceId: serviceId,
            subServices: subServices,
            image: {
                image: image,
                name: imageName,
                size: imageSize
            },
            imageName: imageName,
            imageSize: imageSize,
            targets: targets
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("banner.grid.name"))),
            link: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("banner.grid.link"))),
            image: Yup.object()
                .shape({
                    image: Yup.string().required(t("errors.required-error").replace('{0}', t("banner.form.image"))),
                    name: Yup.string().required(t("errors.required-error").replace('{0}', t("banner.form.image"))),
                    size: Yup.number().required(t("errors.required-error").replace('{0}', t("banner.form.image")))
                })
                .default(null)
                .required(),
        }),
        onSubmit: onSubmit
    });

    useEffect(() => {
        setServiceWorkQuery(() => {
            return form.values.serviceId
                ? new Query().addParams('service360id', form.values.serviceId)
                : new Query();
        });
    }, [form.values.serviceId]);

    useEffect(() => {
        setSelectedServiceWorks(() => {
            return form.values.subServices
                ? form.values.subServices.split(',').map(item => Number(item))
                : [];
        });
    }, [form.values.subServices])

    return (
        <Container fluid>
            <form id='banner-form' onSubmit={form.handleSubmit}
            >
                <Row>
                    <Col>
                        <div className='input-container'>
                            <label className='label required'>
                                {t("banner.grid.name")}
                            </label>
                            <Input name='name'
                                   type='text'
                                   placeholder={t("banner.grid.name")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.name}/>
                            {form.touched.name && form.errors.name ?
                                <div className='field-error'>{form.errors.name}</div> : null}
                        </div>

                        <div className='input-container'>
                            <label className='label required'>
                                {t("banner.grid.link")}
                            </label>
                            <Input name='link'
                                   type='text'
                                   placeholder={t("banner.grid.link")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.link}/>
                            {form.touched.link && form.errors.link ?
                                <div className='field-error'>{form.errors.link}</div> : null}
                        </div>
                    </Col>
                    <Col className={cl.checkboxesContainer}>
                        <div className='input-container checkbox'>
                            <label className='label'>
                                {t("banner.grid.show-to-anonym-user")}
                            </label>
                            <Input name='showToAnonymous'
                                   type='checkbox'
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   checked={form.values.showToAnonymous}/>
                        </div>
                        <div className='input-container checkbox'>
                            <label className='label'>
                                {t("banner.grid.show-to-residents-user")}
                            </label>
                            <Input name='showToResidents'
                                   type='checkbox'
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   checked={form.values.showToResidents}/>
                        </div>
                    </Col>
                </Row>
                <Row className={cl.formRow}>
                    <Col>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label'>
                                {t("banner.grid.publication-date")}
                            </label>
                            <DateTimePickerComponent placeholder={t("banner.grid.publication-date")}
                                                     enabled={!form.isSubmitting}
                                                     change={ev => {
                                                         form.setFieldValue('publicationDate', ev.value)

                                                         if (ev.value === null) {
                                                             form.setFieldValue('tillDate', null);
                                                         }
                                                     }}
                                                     value={form.values.publicationDate}
                                                     enableMask={true}
                                                     format='dd.MM.yyyy HH:mm'
                                                     timeFormat='HH:mm'
                                                     maskPlaceholder={{
                                                         day: 'dd',
                                                         month: 'MM',
                                                         year: 'yyyy',
                                                         hour: 'HH',
                                                         minute: 'mm',
                                                         second: 'ss'
                                                     }}>
                                <Inject services={[MaskedDateTime]}/>
                            </DateTimePickerComponent>
                        </div>
                    </Col>
                    <Col>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label'>
                                {t("banner.grid.published-till")}
                            </label>
                            <DateTimePickerComponent enabled={form.values.publicationDate !== null}
                                                     placeholder={t("banner.grid.published-till")}
                                                     change={ev => form.setFieldValue('tillDate', ev.value)}
                                                     min={form.values.publicationDate}
                                                     value={form.values.tillDate}
                                                     enableMask={true}
                                                     format='dd.MM.yyyy HH:mm'
                                                     timeFormat='HH:mm'
                                                     maskPlaceholder={{
                                                         day: 'dd',
                                                         month: 'MM',
                                                         year: 'yyyy',
                                                         hour: 'HH',
                                                         minute: 'mm',
                                                         second: 'ss'
                                                     }}>
                                <Inject services={[MaskedDateTime]}/>
                            </DateTimePickerComponent>
                        </div>
                    </Col>
                </Row>
                <Row className={cl.formRow}>
                    <Col>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label'>
                                {t("banner.form.service")}
                            </label>
                            <DropDownList url='/api/service360/getService360List'
                                          popupHeight='250px'
                                          change={(ev) => {
                                              form.setFieldValue('serviceId', ev.itemData ? ev.itemData.id : null)
                                              form.setFieldValue('subServices', null);
                                              setSelectedServiceWorks(null);
                                          }}
                                          enabled={!form.isSubmitting}
                                          placeholder={t("banner.form.service")}
                                          value={form.values.serviceId}
                                          showClearButton={true}/>
                        </div>
                    </Col>
                    <Col>
                        <div style={{height: '400px'}}
                             className='input-container syncfusion-control-input-container works-list-container'>
                            <label className='label'>
                                {t("banner.form.works")}
                            </label>
                            <GridComponent id='grid'
                                           container='.works-list-container'
                                           allowFiltering={false}
                                           allowPaging={false}
                                           allowSelection={true}
                                           query={serviceWorkQuery}
                                           allowSorting={false}
                                           selectedRowsIds={selectedServiceWorks}
                                           rowSelected={(ev) => {
                                               if (ev && ev.data && !Array.isArray(ev.data) &&
                                                   (!form.values.subServices || !form.values.subServices.split(',').map(item => Number(item)).find(item => item === ev.data.id))) {
                                                   let ids = form.values.subServices
                                                       ? form.values.subServices.split(',').map(item => Number(item))
                                                       : [];

                                                   ids.push(ev.data.id);

                                                   form.setFieldValue('subServices', ids.join(','));
                                               }
                                           }}
                                           rowDeselected={(ev) => {
                                               if (ev && ev.data && !Array.isArray(ev.data) &&
                                                   (!form.values.subServices || form.values.subServices.split(',').map(item => Number(item)).find(item => item === ev.data.id))) {
                                                   let ids = form.values.subServices
                                                       ? form.values.subServices.split(',').map(item => Number(item))
                                                       : [];

                                                   ids = ids.filter(item => item !== ev.data.id);

                                                   form.setFieldValue('subServices', ids.join(','));
                                               }
                                           }}
                                           selectionSettings={{type: 'Multiple', enableSimpleMultiRowSelection: true}}
                                           url='/api/serviceWork/getServiceWorks'>
                                <ColumnsDirective>
                                    <ColumnDirective type='checkbox' width='50'/>
                                    <ColumnDirective field='id'
                                                     visible={false}
                                                     textAlign="center"
                                                     isPrimaryKey={true}/>
                                    <ColumnDirective field='name'
                                                     width='100%'
                                                     textAlign="center"/>
                                </ColumnsDirective>
                            </GridComponent>
                        </div>
                    </Col>
                </Row>
            </form>
            <Row className={cl.formRow}>
                <Col>
                    {form.errors.image?.image ?
                        <div className='field-error'>{form.errors.image.image}</div> : null}
                    <ImageUploader {...form.values.image}
                                   disabled={form.isSubmitting}
                                   cropperSettings={{width: 1080, height: 480}}
                                   change={ev => form.setFieldValue('image', ev)}/>
                </Col>
                <Col>
                    <Target targets={form.values.targets}
                            disabled={form.isSubmitting}
                            change={(items) => form.setFieldValue('targets', items)}/>
                </Col>
            </Row>
        </Container>
    );
};

export default BannerForm;