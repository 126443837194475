import {apiSlice} from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: build => ({
        ping: build.query({
            query: () => 'api/authentication/ping'
        }),
        login: build.mutation({
            query: (credentials) => ({
                url: 'api/authentication/login',
                method: 'POST',
                body: {...credentials}
            })
        })
    })
});

export const { usePingQuery, useLoginMutation } = authApiSlice;