import React from 'react';

const LoadingScreen = () => {
    return (
        <div className='loading-screen-container'>
            <div className='loader'>

            </div>
        </div>
    );
};

export default LoadingScreen;