import React from 'react';
import {Navigate, Outlet} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import cl from "./AnonymousLayout.module.css";

const AnonymousLayout = () => {
    const isAuth = useAuth();

    if (isAuth) {
        return <Navigate to='/' replace/>
    }

    return (
        <div className={cl.anonymousLayoutContainer}>
            <Outlet/>
        </div>
    );
};

export default AnonymousLayout;