import React from 'react';
import Dialog from "../../../../ui/components/Dialog/Dialog";
import ChangePasswordForm from "../ChangePasswordForm/ChangePasswordForm";

const ChangePasswordDialog = ({open, onOverlayClick, close}) => {
    return (
        <Dialog width='500px'
                height='250px'
                open={open}
                onOverlayClick={onOverlayClick}>
            <ChangePasswordForm close={close}/>
        </Dialog>
    );
};

export default ChangePasswordDialog;