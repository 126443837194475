import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./app/store/store";
import routes from "./data/routes/routes";
import './index.css';
import './app/i18n/i18n';
import {registerLicense} from '@syncfusion/ej2-base';

import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
import '../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';
import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
import setupInterceptors from "./app/api/services/setupAxiosInterceptors";

registerLicense(process.env.REACT_APP_SYNCFUSION_KEY);
setupInterceptors(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <RouterProvider router={createBrowserRouter(routes)}/>
    </Provider>
);

