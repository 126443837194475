import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import Input from "../../../ui/components/Input/Input";
import cl from './AdminForm.module.css';
import Select from "../../../ui/components/Select/Select";
import PermissionType from "../../../../data/enums/PermissionType";

const AdminForm = ({name, login, isBlocked, permission, onSubmit}) => {
    const {t} = useTranslation();

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name,
            login: login,
            isBlocked: isBlocked,
            news: permission.news,
            newsSources: permission.newsSources,
            admins: permission.admins,
            settings: permission.settings,
            adds: permission.adds,
            views: permission.views,
            userLog: permission.userLog
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("admin.grid.name"))),
            login: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("admin.grid.login"))),
        }),
        onSubmit: onSubmit
    });

    return (
        <Container fluid>
            <form id='admin-form' onSubmit={form.handleSubmit}>
                <Row>
                    <Col>
                        <div className='input-container'>
                            <label className='label required'>
                                {t("admin.grid.name")}
                            </label>
                            <Input name='name'
                                   type='text'
                                   placeholder={t("admin.grid.name")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.name}/>
                            {form.touched.name && form.errors.name ?
                                <div className='field-error'>{form.errors.name}</div> : null}
                        </div>

                        <div className='input-container'>
                            <label className='label required'>
                                {t("admin.grid.login")}
                            </label>
                            <Input name='login'
                                   type='text'
                                   placeholder={t("admin.grid.login")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.login}/>
                            {form.touched.login && form.errors.login ?
                                <div className='field-error'>{form.errors.login}</div> : null}
                        </div>

                        <div className='input-container checkbox'>
                            <Input name='isBlocked'
                                   type='checkbox'
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   checked={form.values.isBlocked}/>
                            <label className='label'>
                                {t("admin.form.blocked")}
                            </label>
                        </div>
                    </Col>
                    <Col>
                        <Row>
                            <Col className={`${cl.center} ${cl.bold}`}>
                                {t("profile.labels.section")}
                            </Col>
                            <Col className={`${cl.center} ${cl.bold}`}>
                                {t("profile.labels.role")}
                            </Col>
                        </Row>
                        <Row className={cl.permissionRow}>
                            <Col className={cl.permissionLabel}>
                                {t("profile.labels.news")}
                            </Col>
                            <Col>
                                <Select name='news'
                                        type='checkbox'
                                        disabled={form.isSubmitting}
                                        onChange={ev => form.setFieldValue('news', +ev.target.value)}
                                        value={form.values.news}>
                                    <option value={PermissionType.NotAvailable}>
                                        {t("shared.permission.not-available")}
                                    </option>
                                    <option value={PermissionType.Reading}>
                                        {t("shared.permission.reading")}
                                    </option>
                                    <option value={PermissionType.Editing}>
                                        {t("shared.permission.editing")}
                                    </option>
                                </Select>
                            </Col>
                        </Row>
                        <Row className={cl.permissionRow}>
                            <Col className={cl.permissionLabel}>
                                {t("profile.labels.sources")}
                            </Col>
                            <Col>
                                <Select name='newsSources'
                                        type='checkbox'
                                        disabled={form.isSubmitting}
                                        onChange={ev => form.setFieldValue('newsSources', +ev.target.value)}
                                        value={form.values.newsSources}>
                                    <option value={PermissionType.NotAvailable}>
                                        {t("shared.permission.not-available")}
                                    </option>
                                    <option value={PermissionType.Reading}>
                                        {t("shared.permission.reading")}
                                    </option>
                                    <option value={PermissionType.Editing}>
                                        {t("shared.permission.editing")}
                                    </option>
                                </Select>
                            </Col>
                        </Row>
                        <Row className={cl.permissionRow}>
                            <Col className={cl.permissionLabel}>
                                {t("profile.labels.users")}
                            </Col>
                            <Col>
                                <Select name='userLog'
                                        type='checkbox'
                                        disabled={form.isSubmitting}
                                        onChange={ev => form.setFieldValue('userLog', +ev.target.value)}
                                        value={form.values.userLog}>
                                    <option value={PermissionType.NotAvailable}>
                                        {t("shared.permission.not-available")}
                                    </option>
                                    <option value={PermissionType.Reading}>
                                        {t("shared.permission.reading")}
                                    </option>
                                    <option value={PermissionType.Editing}>
                                        {t("shared.permission.editing")}
                                    </option>
                                </Select>
                            </Col>
                        </Row>
                        <Row className={cl.permissionRow}>
                            <Col className={cl.permissionLabel}>
                                {t("profile.labels.views")}
                            </Col>
                            <Col>
                                <Select name='views'
                                        type='checkbox'
                                        disabled={form.isSubmitting}
                                        onChange={ev => form.setFieldValue('views', +ev.target.value)}
                                        value={form.values.views}>
                                    <option value={PermissionType.NotAvailable}>
                                        {t("shared.permission.not-available")}
                                    </option>
                                    <option value={PermissionType.Reading}>
                                        {t("shared.permission.reading")}
                                    </option>
                                    <option value={PermissionType.Editing}>
                                        {t("shared.permission.editing")}
                                    </option>
                                </Select>
                            </Col>
                        </Row>
                        <Row className={cl.permissionRow}>
                            <Col className={cl.permissionLabel}>
                                {t("profile.labels.settings")}
                            </Col>
                            <Col>
                                <Select name='settings'
                                        type='checkbox'
                                        disabled={form.isSubmitting}
                                        onChange={ev => form.setFieldValue('settings', +ev.target.value)}
                                        value={form.values.settings}>
                                    <option value={PermissionType.NotAvailable}>
                                        {t("shared.permission.not-available")}
                                    </option>
                                    <option value={PermissionType.Reading}>
                                        {t("shared.permission.reading")}
                                    </option>
                                    <option value={PermissionType.Editing}>
                                        {t("shared.permission.editing")}
                                    </option>
                                </Select>
                            </Col>
                        </Row>
                        <Row className={cl.permissionRow}>
                            <Col className={cl.permissionLabel}>
                                {t("profile.labels.admins")}
                            </Col>
                            <Col>
                                <Select name='admins'
                                        type='checkbox'
                                        disabled={form.isSubmitting}
                                        onChange={ev => form.setFieldValue('admins', +ev.target.value)}
                                        value={form.values.admins}>
                                    <option value={PermissionType.NotAvailable}>
                                        {t("shared.permission.not-available")}
                                    </option>
                                    <option value={PermissionType.Reading}>
                                        {t("shared.permission.reading")}
                                    </option>
                                    <option value={PermissionType.Editing}>
                                        {t("shared.permission.editing")}
                                    </option>
                                </Select>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </form>
        </Container>
    );
};

export default AdminForm;