import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Query} from "@syncfusion/ej2-data";
import GridTab from "../../../ui/components/Grid/GridTab/GridTab";
import GridTabSection, {GridTabSectionType} from "../../../ui/components/Grid/GridTab/GridTabSection/GridTabSection";
import GridTabItem from "../../../ui/components/Grid/GridTab/GridTabItem/GridTabItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowDown, faRefresh} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../ui/components/Loader/Loader";
import GridComponent from "../../../ui/components/Grid/GridComponent";
import {ColumnDirective, ColumnsDirective, Filter, Inject, Page, Sort} from "@syncfusion/ej2-react-grids";
import AccountSyncDialog from "./AccountSyncDialog/AccountSyncDialog";
import AccountStateFilter from "./filters/AccountStateFilter";
import StreetFilter from "./filters/StreetFilter";

const Account = () => {
    const [isSyncDialogOpen, setIsSyncDialogOpen] = useState(false);
    const [query, setQuery] = useState(null);
    const [tab, setTab] = useState('0');
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [filterTrigger, setFilterTrigger] = useState(null);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const onTabItemClick = ({id, queryParameterName, queryKey}) => {
        if (queryParameterName && queryKey) {
            setTab(queryKey);
        } else if (id) {
            if (id === 'refresh-btn') {
                setRefreshTrigger(prev => prev + 1);
            }

            if (id === 'sync-btn') {
                setIsSyncDialogOpen(prev => !prev);
            }
        }
    }

    useEffect(() => {
        setQuery(prev => {
            let q = new Query();
            if (prev) {
                if (prev && prev.params && prev.params.length > 0) {
                    const params = prev.params.filter(item => item.key !== 'tab');
                    for (const item of params) {
                        q.addParams(item.key, item.value);
                    }
                }
                q.addParams('tab', tab);
            } else {
                const urlParams = new URLSearchParams(window.location.search);
                if (urlParams.get('tab')) {
                    q.addParams('tab', urlParams.get('tab'));
                } else {
                    q.addParams('tab', tab);
                }
            }

            return q;
        });
    }, [tab]);

    const onFilterChange = (ev, fieldName) => setFilterTrigger({
        ev,
        fieldName
    });

    const onRowDoubleClick = ev => {
        if (ev && ev.rowData) {
            navigate(`/users/accounts/account/${ev.rowData.id}`);
        }
    }

    const onSyncSuccess = () => {
        setIsSyncDialogOpen(prev => !prev);

        setRefreshTrigger(prev => prev + 1);
    }

    return (
        <>
            <AccountSyncDialog open={isSyncDialogOpen}
                               close={() => setIsSyncDialogOpen(prev => !prev)}
                               onSyncSuccess={onSyncSuccess}/>
            <div className='list-container'>
                <GridTab onClick={onTabItemClick}>
                    <GridTabSection type={GridTabSectionType.Tab}
                                    showRightSeparator={true}
                                    queryParameterName='tab'>
                        <GridTabItem id='residential-tab'
                                     text={t("account.grid.residential-tab")}
                                     queryKey='0'
                                     isDefaultActive={true}>

                        </GridTabItem>
                        <GridTabItem id='commerce-tab'
                                     text={t("account.grid.commerce-tab")}
                                     queryKey='1'>

                        </GridTabItem>
                    </GridTabSection>
                    <GridTabSection type={GridTabSectionType.Button}>
                        <GridTabItem id='sync-btn'
                                     text={t("account.btn.sync-from-crm-short")}
                                     icon={<FontAwesomeIcon icon={faCloudArrowDown}/>}>

                        </GridTabItem>
                        <GridTabItem id='refresh-btn'
                                     text={t("btn.refresh")}
                                     icon={<FontAwesomeIcon icon={faRefresh}/>}>

                        </GridTabItem>
                    </GridTabSection>
                </GridTab>
                {!query
                    ? <Loader/>
                    : <GridComponent id='grid'
                                     container='.list-container'
                                     url='/api/account/getAccounts'
                                     query={query}
                                     refresh={refreshTrigger}
                                     onRowClick={onRowDoubleClick}
                                     filterStoreEntityName={'account'}
                                     filter={filterTrigger}>
                        <ColumnsDirective>
                            <ColumnDirective field='id'
                                             visible={false}
                                             textAlign="center"
                                             isPrimaryKey={true}
                                             type='number'/>
                            <ColumnDirective field='number'
                                             headerText={t("account.grid.number")}
                                             width='200'
                                             textAlign="center"/>
                            <ColumnDirective field='address'
                                             headerText={t("account.grid.address")}
                                             width='400'
                                             allowSorting={false}
                                             filterTemplate={(props) => <StreetFilter
                                                 onChange={(ev, fieldName) => setFilterTrigger({
                                                     ev,
                                                     fieldName
                                                 })} filterStoreEntityName={'account'} {...props}/>}
                                             textAlign="center"/>
                            <ColumnDirective field='isBlocked'
                                             headerText={t("account.grid.blocked")}
                                             width='170'
                                             displayAsCheckBox={true}
                                             textAlign="center"
                                             filterTemplate={(props) => <AccountStateFilter
                                                 onFilterChange={onFilterChange} filterStoreEntityName={'account'} {...props}/>}/>
                        </ColumnsDirective>
                        <Inject services={[Page, Sort, Filter]}/>
                    </GridComponent>
                }
            </div>
        </>
    );
};

export default Account;