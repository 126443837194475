import React from 'react';
import cl from './Select.module.css';

const Select = ({children, ...rest}) => {
    return(
        <select className={cl.select} {...rest}>
            {children}
        </select>
    )
};

export default Select;