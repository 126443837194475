import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import moment from "moment/moment";
import * as Yup from "yup";
import {Container, Row, Col} from "react-bootstrap";
import Target from "../../../ui/components/Target/Target";
import cl from './HomeOwnerEripForm.module.css';
import {Query} from "@syncfusion/ej2-data";
import DropDownList from "../../../ui/components/DropDownList/DropDownList";
import Input from "../../../ui/components/Input/Input";



const HomeOwnerEripForm = ({
                               id,
                               name,
                               eripPath,
                               erip,
                               eripDiType,

                               communalPath,
                               communalPayCode,
                               communalDiType,

                               energyPath,
                               energyPayCode,
                               energyDiType,

                               gasPath,
                               gasPayCode,
                               gasDiType,

                               onSubmit
                           }) => {

    const {t} = useTranslation();

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: id,
            eripPath: eripPath,
            erip: erip,
            eripDiType: eripDiType,

            communalPath:communalPath,
            communalPayCode:communalPayCode,
            communalDiType:communalDiType,

            energyPath:energyPath,
            energyPayCode:energyPayCode,
            energyDiType:energyDiType,

            gasPath:gasPath,
            gasPayCode:gasPayCode,
            gasDiType:gasDiType,

            name: name
        },

        onSubmit: onSubmit
    });

    return (
        <Container fluid>
            <form id='page-form' onSubmit={form.handleSubmit}>

                <Row className={cl.formRow}>
                    <Col>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label required'>
                                {t("homeowner.form.name")}
                            </label>
                            <Input name='name'
                                   type='text'
                                   placeholder={t("homeowner.form.name")}
                                   disabled={true}
                                   autoComplete='off'
                                   value={form.values.name}/>
                        </div>
                    </Col>
                    <Col>

                    </Col>
                </Row>
                <div className={cl.group}>
                <Row className={cl.formRow}>
                    <div className={cl.groupheader}>
                        <label className={cl.grouplabel}>
                            {t("homeowner.form.erip")}
                        </label>

                    </div>

                </Row>
                <Row className={cl.formRow}>
                    <Col>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label'>
                                {t("homeowner.form.path")}
                            </label>
                            <Input name='eripPath'
                                   type='text'
                                   placeholder={t("homeowner.form.path")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.eripPath}/>
                        </div>
                    </Col>

                </Row>
                <Row className={cl.formRow}>

                    <Col>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label'>
                                {t("homeowner.form.payCode")}
                            </label>
                            <Input name='erip'
                                   type='text'
                                   placeholder={t("homeowner.form.payCode")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.erip}/>
                        </div>
                    </Col>
                    <Col>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label'>
                                {t("homeowner.form.diType")}
                            </label>
                            <Input name='eripDiType'
                                   type='text'
                                   placeholder={t("homeowner.form.diType")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.eripDiType}/>
                        </div>
                    </Col>
                </Row>
                </div>


                <div className={cl.group}>
                    <Row className={cl.formRow}>
                        <div className={cl.groupheader}>
                            <label className={cl.grouplabel}>
                                {t("homeowner.form.communal")}
                            </label>

                        </div>

                    </Row>
                    <Row className={cl.formRow}>
                        <Col>
                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label'>
                                    {t("homeowner.form.path")}
                                </label>
                                <Input name='communalPath'
                                       type='text'
                                       placeholder={t("homeowner.form.path")}
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       autoComplete='off'
                                       value={form.values.communalPath}/>
                            </div>
                        </Col>

                    </Row>
                    <Row className={cl.formRow}>

                        <Col>
                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label'>
                                    {t("homeowner.form.payCode")}
                                </label>
                                <Input name='communalPayCode'
                                       type='text'
                                       placeholder={t("homeowner.form.payCode")}
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       autoComplete='off'
                                       value={form.values.communalPayCode}/>
                            </div>
                        </Col>
                        <Col>
                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label'>
                                    {t("homeowner.form.diType")}
                                </label>
                                <Input name='communalDiType'
                                       type='text'
                                       placeholder={t("homeowner.form.diType")}
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       autoComplete='off'
                                       value={form.values.communalDiType}/>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className={cl.group}>
                    <Row className={cl.formRow}>
                        <div className={cl.groupheader}>
                            <label className={cl.grouplabel}>
                                {t("homeowner.form.energy")}
                            </label>

                        </div>

                    </Row>
                    <Row className={cl.formRow}>
                        <Col>
                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label'>
                                    {t("homeowner.form.path")}
                                </label>
                                <Input name='energyPath'
                                       type='text'
                                       placeholder={t("homeowner.form.path")}
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       autoComplete='off'
                                       value={form.values.energyPath}/>
                            </div>
                        </Col>

                    </Row>
                    <Row className={cl.formRow}>

                        <Col>
                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label'>
                                    {t("homeowner.form.payCode")}
                                </label>
                                <Input name='energyPayCode'
                                       type='text'
                                       placeholder={t("homeowner.form.payCode")}
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       autoComplete='off'
                                       value={form.values.energyPayCode}/>
                            </div>
                        </Col>
                        <Col>
                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label'>
                                    {t("homeowner.form.diType")}
                                </label>
                                <Input name='energyDiType'
                                       type='text'
                                       placeholder={t("homeowner.form.diType")}
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       autoComplete='off'
                                       value={form.values.energyDiType}/>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className={cl.group}>
                    <Row className={cl.formRow}>
                        <div className={cl.groupheader}>
                            <label className={cl.grouplabel}>
                                {t("homeowner.form.gas")}
                            </label>

                        </div>

                    </Row>
                    <Row className={cl.formRow}>
                        <Col>
                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label'>
                                    {t("homeowner.form.path")}
                                </label>
                                <Input name='gasPath'
                                       type='text'
                                       placeholder={t("homeowner.form.path")}
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       autoComplete='off'
                                       value={form.values.gasPath}/>
                            </div>
                        </Col>

                    </Row>
                    <Row className={cl.formRow}>

                        <Col>
                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label'>
                                    {t("homeowner.form.payCode")}
                                </label>
                                <Input name='gasPayCode'
                                       type='text'
                                       placeholder={t("homeowner.form.payCode")}
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       autoComplete='off'
                                       value={form.values.gasPayCode}/>
                            </div>
                        </Col>
                        <Col>
                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label'>
                                    {t("homeowner.form.diType")}
                                </label>
                                <Input name='gasDiType'
                                       type='text'
                                       placeholder={t("homeowner.form.diType")}
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       autoComplete='off'
                                       value={form.values.gasDiType}/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </form>

        </Container>
    );
};

export default HomeOwnerEripForm;
