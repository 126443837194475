import React from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Col, Container, Row} from "react-bootstrap";
import Target from "../../../ui/components/Target/Target";
import ImageUploader from "../../../ui/components/ImageUploader/ImageUploader";
import cl from './PostCategoryForm.module.css';
import Input from "../../../ui/components/Input/Input";
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";
import TextArea from "../../../ui/components/TextArea/TextArea";

const PostCategoryForm = ({
                              name,
                              postCategoryDescription,
                              isBlocked,
                              showInMain,
                              hasHeader,
                              hasFooter,
                              sendPush,
                              headMenu,
                              postType,
                              listType,
                              menu,
                              icon,
                              iconName,
                              iconSize,
                              targets,
                              onSubmit
                          }) => {
        const {t} = useTranslation();

        const postTypesData = [
            {id: 0, text: t("postCategory.postCategoryType.0")},
            {id: 1, text: t("postCategory.postCategoryType.1")},
        ];

        const listTypesData = [
            {id: 0, text: t("postCategory.postCategoryListType.0")},
            {id: 1, text: t("postCategory.postCategoryListType.1")},
        ];

        const postCategoryMenuData = [
            {id: 0, text: t("postCategory.postCategoryMenu.0")},
            {id: 1, text: t("postCategory.postCategoryMenu.1")},
            {id: 2, text: t("postCategory.postCategoryMenu.2")},
            {id: 3, text: t("postCategory.postCategoryMenu.3")}
        ]

        const form = useFormik({
            enableReinitialize: true,
            initialValues: {
                name: name,
                postCategoryDescription: postCategoryDescription,
                isBlocked: isBlocked,
                showInMain: showInMain,
                hasHeader: hasHeader,
                hasFooter: hasFooter,
                sendPush: sendPush,
                headMenu: headMenu,
                postType: postType,
                listType: listType,
                menu: menu,
                image: {
                    image: icon,
                    name: iconName,
                    size: iconSize
                },
                targets: targets
            },
            validationSchema: Yup.object({
                name: Yup.string()
                    .required(t("errors.required-error").replace('{0}', t("postCategory.form.name"))),
            }),
            onSubmit: onSubmit
        });

        return (
            <Container fluid>
                <form id='post-category-form' onSubmit={form.handleSubmit}>
                    <Row>
                        <Col>
                            <div className='input-container'>
                                <label className='label required'>
                                    {t("postCategory.form.name")}
                                </label>
                                <Input name='name'
                                       type='text'
                                       placeholder={t("postCategory.form.name")}
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       autoComplete='off'
                                       value={form.values.name}/>
                                {form.touched.name && form.errors.name ?
                                    <div className='field-error'>{form.errors.name}</div> : null}
                            </div>
                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label'>
                                    {t("postCategory.form.type")}
                                </label>

                                <DropDownListComponent enabled={!form.isSubmitting}
                                                       id='post-category-type'
                                                       popupHeight='250px'
                                                       width='100%'
                                                       dataSource={postTypesData}
                                                       value={form.values.postType}
                                                       change={(ev) => {
                                                           form.setFieldValue('postType', ev.itemData.id);
                                                           if (ev.itemData.id === 0) {
                                                               form.setFieldValue('headMenu', false);
                                                               form.setFieldValue('showInMain', false)
                                                               form.setFieldValue('hasHeader', false);
                                                               form.setFieldValue('hasFooter', false);
                                                               form.setFieldValue('sendPush', false);
                                                           }
                                                       }}
                                                       fields={{text: 'text', value: 'id'}}
                                                       showClearButton={false}>

                                </DropDownListComponent>
                            </div>

                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label'>
                                    {t("postCategory.form.menu")}
                                </label>
                                <DropDownListComponent enabled={!form.isSubmitting}
                                                       id='post-category-menu'
                                                       popupHeight='250px'
                                                       width='100%'
                                                       dataSource={postCategoryMenuData}
                                                       value={form.values.menu}
                                                       change={(ev) => form.setFieldValue('menu', ev.itemData?.id)}
                                                       fields={{text: 'text', value: 'id'}}
                                                       showClearButton={false}>

                                </DropDownListComponent>
                            </div>

                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label'>
                                    {t("postCategory.form.list-type")}
                                </label>
                                <DropDownListComponent enabled={form.values.postType !== 0 && !form.isSubmitting}
                                                       id='post-category-list-type'
                                                       popupHeight='250px'
                                                       width='100%'
                                                       dataSource={listTypesData}
                                                       value={form.values.listType}
                                                       change={(ev) => form.setFieldValue('listType', ev.itemData?.id)}
                                                       fields={{text: 'text', value: 'id'}}
                                                       showClearButton={false}>

                                </DropDownListComponent>
                            </div>

                            <div className='input-textarea-container'>
                                <label className='label'>
                                    {t("postCategory.form.description")}
                                </label>
                                <TextArea name='postCategoryDescription'
                                          placeholder={t("postCategory.form.description")}
                                          disabled={form.isSubmitting}
                                          onChange={form.handleChange}
                                          value={form.values.postCategoryDescription}/>
                            </div>
                        </Col>
                        <Col>
                            <div className='input-container checkbox end'>
                                <label className='label'>
                                    {t("postCategory.form.blocked")}
                                </label>
                                <Input name='isBlocked'
                                       type='checkbox'
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       checked={form.values.isBlocked}/>
                            </div>

                            <div className='input-container checkbox end'>
                                <label className='label'>
                                    {t("postCategory.form.show-in-main")}
                                </label>
                                <Input name='showInMain'
                                       type='checkbox'
                                       disabled={form.isSubmitting || form.values.postType === 0}
                                       onChange={form.handleChange}
                                       checked={form.values.showInMain}/>
                            </div>

                            <div className='input-container checkbox end'>
                                <label className='label'>
                                    {t("postCategory.form.send-push")}
                                </label>
                                <Input name='sendPush'
                                       type='checkbox'
                                       disabled={form.isSubmitting || form.values.postType === 0}
                                       onChange={form.handleChange}
                                       checked={form.values.sendPush}/>
                            </div>

                            <div className='input-container checkbox end'>
                                <label className='label'>
                                    {t("postCategory.form.has-footer")}
                                </label>
                                <Input name='hasFooter'
                                       type='checkbox'
                                       disabled={form.isSubmitting || form.values.postType === 0}
                                       onChange={form.handleChange}
                                       checked={form.values.hasFooter}/>
                            </div>

                            <div className='input-container checkbox end'>
                                <label className='label'>
                                    {t("postCategory.form.has-header")}
                                </label>
                                <Input name='hasHeader'
                                       type='checkbox'
                                       disabled={form.isSubmitting || form.values.postType === 0}
                                       onChange={form.handleChange}
                                       checked={form.values.hasHeader}/>
                            </div>

                            <div className='input-container checkbox end'>
                                <label className='label'>
                                    {t("postCategory.form.head-menu")}
                                </label>
                                <Input name='headMenu'
                                       type='checkbox'
                                       disabled={form.isSubmitting || form.values.postType === 0}
                                       onChange={form.handleChange}
                                       checked={form.values.headMenu}/>
                            </div>
                        </Col>
                    </Row>
                </form>
                <Row className={cl.formRow}>
                    <Col>
                        {form.errors.image?.image ?
                            <div className='field-error'>{form.errors.image.image}</div> : null}
                        <ImageUploader {...form.values.image}
                                       disabled={form.isSubmitting}
                                       cropperSettings={{width: 300, height: 300}}
                                       change={ev => form.setFieldValue('image', ev)}/>
                    </Col>
                    <Col>
                        <Target targets={form.values.targets}
                                disabled={form.isSubmitting}
                                change={(items) => form.setFieldValue('targets', items)}/>
                    </Col>
                </Row>
            </Container>
        );
    }
;

export default PostCategoryForm;