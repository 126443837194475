import React, {createRef} from 'react';
import cl from './CropForm.module.css';
import {useTranslation} from "react-i18next";
import Button, {ButtonType} from "../../../Button/Button";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const CropForm = ({bytes, close, change, cropperSettings}) => {
    const {t} = useTranslation();
    const cropperRef = createRef();

    const save = () => {
        if (change) {
            if (typeof cropperRef.current?.cropper !== "undefined") {
                change(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            }
        }
    };

    return (
        <div className={cl.cropFormContainer}>
            <div>
                <Cropper ref={cropperRef}
                         style={{height: '100%', width: "100%"}}
                         src={`data:image/png;base64,${bytes}`}
                         viewMode={1}
                         dragMode='move'
                         cropBoxResizable={false}
                         background={false}
                         autoCropArea={1}
                         objectFit="horizontal-cover"
                         data={cropperSettings}/>
            </div>
            <div>
                <Button buttonType={ButtonType.Warning}
                        onClick={close}>
                    {t("btn.cancel")}
                </Button>
                <Button buttonType={ButtonType.Default}
                        onClick={save}>
                    {t("btn.save")}
                </Button>
            </div>
        </div>
    );
};

export default CropForm;