import React, {useEffect, useState} from 'react';
import useIsFirstRender from "../../../../../../../../hooks/useIsFirstRender";
import {useTranslation} from "react-i18next";
import cl from './PollQuestionAnswerList.module.css';
import Dialog from "../../../../../../../ui/components/Dialog/Dialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlus, faTrashCanArrowUp} from "@fortawesome/free-solid-svg-icons";
import {getPollTypeName} from "../../utils";
import NestedPollQuestionForm from "../../NestedPollQuestionForm/NestedPollQuestionForm";

const PollQuestionAnswerList = ({
                                    items,
                                    disabled,
                                    change
                                }) => {
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [editPollQuestionAnswer, setEditPollQuestionAnswer] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [answers, setAnswers] = useState(null);
    const isFirstRender = useIsFirstRender();
    const {t} = useTranslation();

    useEffect(() => {
        if (answers === null || (Array.isArray(answers) && answers.length <= 0)) {
            setIsLoading(prev => !prev);

            setAnswers(items);

            setIsLoading(prev => !prev);
        }
    }, [items]);

    useEffect(() => {
        if (change && !disabled && !isFirstRender) {
            change(answers);
        }
    }, [answers]);

    const addItem = () => {
        if (!disabled && !isLoading) {
            const itemToAdd = {
                id: null,
                point: answers === null || answers.length <= 0
                    ? 1
                    : Math.max(...answers.map(item => item.point)) + 1,
                questionText: '',
                multipleSelection: false,
                isRequired: false,
                pollId: null,
                parentQuestionId: null,
                pollQuestions: null
            };

            setAnswers(answers === null || !Array.isArray(answers)
                ? [itemToAdd]
                : [...answers, itemToAdd]);
        }
    };

    const removeItem = (point) => {
        if (!disabled && !isLoading) {
            let data = [...answers.filter(item => item.point !== point)];
            for (const [i, value] of data.entries()) {
                value.point = i + 1;
            }

            setAnswers(data);
        }
    };

    const editItem = (question) => {
        if (!disabled && !isLoading && question !== null) {
            setEditPollQuestionAnswer(question);

            setIsEditDialogOpen(prev => !prev);
        }
    };

    const onSave = (item) => {
        if (item) {
            setAnswers([
                ...answers.filter(p => p.point !== item.point),
                item
            ]);

            setIsEditDialogOpen(prev => !prev);
        }
    };

    return (
        <>
            {editPollQuestionAnswer !== null
                ? <Dialog open={isEditDialogOpen}
                          id={'poll-question-answer-form'}
                          usePortalToDocumentBody={true}
                          width='850px'
                          height='550px'>
                    <NestedPollQuestionForm nestedQuestion={editPollQuestionAnswer}
                                            close={() => setIsEditDialogOpen(prev => !prev)}
                                            onSave={onSave}/>
                </Dialog>
                : null}
            <div className={cl.pollQuestionListContainer}>
                <div className={cl.toolbarContainer}>
                    <button className={cl.toolbarBtn}
                            onClick={addItem}
                            disabled={disabled || isLoading}
                            type={'button'}>
                    <span>
                        <FontAwesomeIcon icon={faPlus}/>
                    </span>
                        <span>
                        {t("poll.form.add-question-answer-btn")}
                    </span>
                    </button>
                </div>
                <div className={cl.tableHeaderContainer}>
                    <div style={{width: '130px'}}>
                        {t("poll.form.question-answer-number")}
                    </div>
                    <div style={{width: '150px'}}>
                        {t("poll.form.question-answer-type")}
                    </div>
                    <div style={{width: 'calc(100% - 380px)'}}>
                        {t("poll.form.question-answer-question")}
                    </div>
                    <div style={{width: '50px'}}>

                    </div>
                    <div style={{width: '50px'}}>

                    </div>
                </div>
                <div>
                    {isLoading
                        ? <div className={cl.loaderContainer}>
                            <div className='loader'></div>
                        </div>
                        : null
                    }
                    {answers !== null && answers.length > 0
                        ? <table className={cl.table}>
                            <tbody>
                            {answers.sort((first, second) => first.point - second.point).map(p => {
                                return (
                                    <tr key={p.point}>
                                        <td style={{width: '130px'}}>
                                            {p.point}
                                        </td>
                                        <td style={{width: '150px'}}>
                                            {getPollTypeName(t, p.type)}
                                        </td>
                                        <td style={{width: 'calc(100% - 380px)'}}>
                                            {p.questionText !== null
                                                ? p.questionText
                                                : null
                                            }
                                        </td>
                                        <td style={{width: '50px'}}>
                                            <FontAwesomeIcon className={cl.edit} onClick={() => editItem(p)}
                                                             icon={faEdit}/>
                                        </td>
                                        <td style={{width: '50px'}}>
                                            <FontAwesomeIcon className={cl.reset} onClick={() => removeItem(p.point)}
                                                             icon={faTrashCanArrowUp}/>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        : null
                    }
                </div>
            </div>
        </>
    );
};

export default PollQuestionAnswerList;