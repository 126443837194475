import React, {useEffect, useState} from 'react';
import cl from './NestedPollQuestionForm.module.css';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import Button, {ButtonType} from "../../../../../../ui/components/Button/Button";
import {Col, Container, Row} from "react-bootstrap";
import FormLayout from "../../../../../../ui/layouts/FormLayout/FormLayout";
import Input from "../../../../../../ui/components/Input/Input";
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";
import {getPointsQuantityTypes, getPollTypes, validatePollQuestionList} from "../utils";
import PollQuestionPointList from "../components/PollQuestionPointList/PollQuestionPointList";
import PollQuestionOpinionScaleList from "../components/PollQuestionOpinionScaleList/PollQuestionOpinionScaleList";
import PollQuestionAnswerList from "../components/PollQuestionAnswerList/PollQuestionAnswerList";
import TextArea from "../../../../../../ui/components/TextArea/TextArea";
import {v4 as uuidv4} from 'uuid';

const NestedPollQuestionForm = ({
                                    nestedQuestion,
                                    close,
                                    onSave
                                }) => {
    const [id, setId] = useState();
    const {t} = useTranslation();

    useEffect(() => {
        setId(uuidv4());
    }, []);

    const form = useFormik({
        initialValues: {
            id: nestedQuestion.id,
            point: nestedQuestion.point,
            pollId: nestedQuestion.pollId,
            questionText: nestedQuestion.questionText ?? '',
            type: nestedQuestion.type,
            pointsQuantity: nestedQuestion.pointsQuantity,
            multipleSelection: nestedQuestion.multipleSelection,
            parentQuestionId: nestedQuestion.parentQuestionId,
            isRequired: nestedQuestion.isRequired,
            pollQuestions: nestedQuestion.pollQuestions
        },
        validationSchema: Yup.object({
            questionText: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("poll.form.question-text"))),
            type: Yup.number()
                .nullable()
                .transform((_, val) => val === Number(val) ? val : null)
                .required(t("errors.required-error").replace('{0}', t("poll.form.point-type"))),
            pointsQuantity: Yup.number()
                .nullable()
                .transform((_, val) => val === Number(val) ? val : null)
                .when('type', {
                    is: (param) => param !== null && param === 0,
                    then: () => Yup.number()
                        .nullable()
                        .transform((_, val) => val === Number(val) ? val : null)
                        .required(t("errors.required-error").replace('{0}', t("poll.form.points-quantity"))),
                }),
            pollQuestions: Yup.array()
                .nullable()
                .test(
                    'pollQuestionsValidations',
                    t('poll.errors.opinion-scale-required-error'),
                    function (items) {
                //        if (form.values.type === 0 || form.values.type === 3)
                  //          return true;

                        return validatePollQuestionList(items, form.values.type);
                    }
                )
        }),
        onSubmit: async (values, actions) => {
            actions.setSubmitting(true);

            if (onSave) {
                onSave({
                    ...values
                });
            }

            actions.setSubmitting(false);
        }
    });


    return (
        <FormLayout
            header={(
                <div className='default-form-layout-header' style={{position: 'relative'}}>
                    {t("poll.form.question")}
                    <span className={cl.close} onClick={close}>
                    &times;
                </span>
                </div>
            )}
            footer={(
                <div className='default-form-layout-footer' style={{justifyContent: 'end'}}>
                    <div>
                        <Button buttonType={ButtonType.Default}
                                form={`nested-question-form-${id}`}
                                type='submit'
                                disabled={form.isSubmitting}>
                            {t("btn.save")}
                        </Button>
                    </div>
                </div>
            )}>
            <div className='default-form-layout-container'>
                <Container fluid>
                    <form id={`nested-question-form-${id}`} onSubmit={form.handleSubmit}>
                        <Row>
                            <Col>
                                <div style={{minHeight: '70px'}}>
                                    <label className='label required'>
                                        {t("poll.form.question-text")}
                                    </label>
                                    <TextArea name='questionText'
                                              type='text'
                                              placeholder={t("poll.form.question-text")}
                                              disabled={form.isSubmitting}
                                              onChange={form.handleChange}
                                              autoComplete='off'
                                              value={form.values.questionText}/>
                                    {form.touched.questionText && form.errors.questionText ?
                                        <div className='field-error'>{form.errors.questionText}</div> : null}
                                </div>
                            </Col>
                            <Col>
                                <div className='input-container syncfusion-control-input-container'>
                                    <label className='label required'>
                                        {t("poll.form.point-type")}
                                    </label>
                                    <DropDownListComponent enabled={!form.isSubmitting}
                                                           id='poll-type'
                                                           popupHeight='250px'
                                                           placeholder={t("poll.form.point-type")}
                                                           width='100%'
                                                           dataSource={getPollTypes(t)}
                                                           value={form.values.type}
                                                           change={(ev) => {
                                                               if (ev.itemData && ev.itemData.id !== 0) {
                                                                   form.setFieldValue('pointsQuantity', null);
                                                               }

                                                               if (ev.itemData === null) {
                                                                   form.setFieldValue('pointsQuantity', null);
                                                               }

                                                               form.setFieldValue('type', ev.itemData?.id)
                                                               form.setFieldValue('pollQuestions', null);
                                                           }}
                                                           fields={{text: 'text', value: 'id'}}
                                                           showClearButton={true}>

                                    </DropDownListComponent>
                                    {form.touched.type && form.errors.type ?
                                        <div className='field-error'>{form.errors.type}</div> : null}
                                </div>

                                {form.values.type !== null && form.values.type === 0
                                    ? <div className='input-container syncfusion-control-input-container'>
                                        <label className='label required'>
                                            {t("poll.form.points-quantity")}
                                        </label>
                                        <DropDownListComponent enabled={!form.isSubmitting}
                                                               id='quantity'
                                                               popupHeight='250px'
                                                               placeholder={t("poll.form.points-quantity")}
                                                               width='100%'
                                                               dataSource={getPointsQuantityTypes()}
                                                               value={form.values.pointsQuantity}
                                                               change={(ev) => {
                                                                   if (ev && ev.itemData && ev.itemData.id !== null) {
                                                                       form.setFieldValue('pointsQuantity', ev.itemData.id)
                                                                   } else {
                                                                       form.setFieldValue('pointsQuantity', null);

                                                                       form.setFieldValue('pollQuestions', null);
                                                                   }
                                                               }}
                                                               fields={{text: 'text', value: 'id'}}
                                                               showClearButton={true}>

                                        </DropDownListComponent>
                                        {form.touched.pointsQuantity && form.errors.pointsQuantity ?
                                            <div className='field-error'>{form.errors.pointsQuantity}</div> : null}
                                    </div>
                                    : null
                                }

                                {form.values.type !== null && form.values.type === 2
                                    ? <div className='input-container checkbox' style={{float: 'right'}}>
                                        <label className='label'>
                                            {t("poll.form.multiple-selection")}
                                        </label>
                                        <Input name='multipleSelection'
                                               type='checkbox'
                                               disabled={form.isSubmitting}
                                               onChange={form.handleChange}
                                               checked={form.values.multipleSelection}/>
                                    </div>
                                    : null
                                }

                                {form.values.type !== null && form.values.type === 1
                                    ? <div className='input-container checkbox' style={{float: 'right'}}>
                                        <label className='label'>
                                            {t("poll.form.required")}
                                        </label>
                                        <Input name='isRequired'
                                               type='checkbox'
                                               disabled={form.isSubmitting}
                                               onChange={form.handleChange}
                                               checked={form.values.isRequired}/>
                                    </div>
                                    : null
                                }
                            </Col>
                        </Row>

                        {form.values.type !== null && form.values.type === 0 && form.values.pointsQuantity !== null && form.values.pointsQuantity !== undefined
                            ? <Row>
                                <Col>
                                    {form.touched.pollQuestions && form.errors.pollQuestions && !Array.isArray(form.errors.pollQuestions)
                                        ? <div className='field-error'>{form.errors.pollQuestions}</div>
                                        : null}
                                    <PollQuestionPointList quantity={form.values.pointsQuantity}
                                                           disabled={form.isSubmitting}
                                                           change={(items) => form.setFieldValue('pollQuestions', items)}
                                                           items={form.values.pollQuestions}/>
                                </Col>
                            </Row>
                            : null
                        }

                        {form.values.type !== null && form.values.type === 3
                            ? <Row>
                                <Col>
                                    {form.touched.pollQuestions && form.errors.pollQuestions && !Array.isArray(form.errors.pollQuestions)
                                        ? <div className='field-error'>{form.errors.pollQuestions}</div>
                                        : null}
                                    <PollQuestionOpinionScaleList disabled={form.isSubmitting}
                                                                  change={(items) => form.setFieldValue('pollQuestions', items)}
                                                                  items={form.values.pollQuestions}/>
                                </Col>
                            </Row>
                            : null
                        }

                        {form.values.type !== null && form.values.type === 2
                            ? <Row>
                                <Col>
                                    {form.touched.pollQuestions && form.errors.pollQuestions && !Array.isArray(form.errors.pollQuestions)
                                        ? <div className='field-error'>{form.errors.pollQuestions}</div>
                                        : null}
                                    <PollQuestionAnswerList disabled={form.isSubmitting}
                                                            change={(items) => form.setFieldValue('pollQuestions', items)}
                                                            items={form.values.pollQuestions}/>
                                </Col>
                            </Row>
                            : null
                        }
                    </form>
                </Container>
            </div>
        </FormLayout>
    );
};

export default NestedPollQuestionForm;