import React from 'react';
import {useTranslation} from "react-i18next";

const PostCategoryMenuColumnTemplate = (props) => {
    const {t} = useTranslation();

    if (!props && !props.postCategoryMenu) {
        return null;
    }

    const text = t(`postCategory.postCategoryMenu.${props.postCategoryMenu}`);

    return (
        <div>
            {text}
        </div>
    );
};

export default PostCategoryMenuColumnTemplate;