import React, {useState} from 'react';
import FormLayout from "../../../ui/layouts/FormLayout/FormLayout";
import cl from './Profile.module.css';
import Button, {ButtonType} from "../../../ui/components/Button/Button";
import ChangePasswordDialog from "./ChangePasswordDialog/ChangePasswordDialog";
import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import Input from "../../../ui/components/Input/Input";
import {useGetCurrentUserQuery} from "../../../../features/application/applicationApiSlice";
import {getPermissionLabelResourceName} from "../../../../data/enums/PermissionType";

const Profile = () => {
    const {t} = useTranslation();
    const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
    const {data: user, isLoading} = useGetCurrentUserQuery();

    return (
        <>
            <FormLayout
                header={<div className={cl.header}>{t("profile.labels.profile-label")}</div>}
                footer={(
                    <div className={cl.footer}>
                        <Button buttonType={ButtonType.Warning}
                                onClick={() => setChangePasswordDialogOpen(true)}>
                            {t("profile.btn.change-password")}
                        </Button>
                    </div>
                )}
                footerPosition='bottom'
                isLoading={isLoading}>
                <Container fluid className={cl.container}>
                    <Row>
                        {user &&
                            <>
                                <Col>
                                    <div className={cl.controlContainer}>
                                        <label className='label'>
                                            {t("profile.labels.name")}
                                        </label>
                                        <Input disabled={true}
                                               placeholder={t("profile.labels.name")}
                                               value={user.name}/>
                                    </div>
                                    <div className={cl.controlContainer}>
                                        <label className='label'>
                                            {t("profile.labels.login")}
                                        </label>
                                        <Input disabled={true}
                                               placeholder={t("profile.labels.login")}
                                               value={user.login}/>
                                    </div>
                                    <div className={`${cl.controlContainer} ${cl.checkContainer}`}>
                                        <Input type='checkbox'
                                               disabled={true}
                                               value={user.isBlocked}/>
                                        <label className='label'>
                                            {t("profile.labels.blocked")}
                                        </label>
                                    </div>
                                </Col>
                                <Col>
                                    <Row className={cl.controlContainer}>
                                        <Col className={`${cl.center} ${cl.bold}`}>
                                            {t("profile.labels.section")}
                                        </Col>
                                        <Col className={`${cl.center} ${cl.bold}`}>
                                            {t("profile.labels.role")}
                                        </Col>
                                    </Row>
                                    <Row className={cl.controlContainer}>
                                        <Col>
                                            {t("profile.labels.news")}
                                        </Col>
                                        <Col>
                                            <Input disabled={true}
                                                   placeholder={t("profile.labels.news")}
                                                   value={t(getPermissionLabelResourceName(user?.permission.news))}/>
                                        </Col>
                                    </Row>
                                    <Row className={cl.controlContainer}>
                                        <Col>
                                            {t("profile.labels.sources")}
                                        </Col>
                                        <Col>
                                            <Input disabled={true}
                                                   placeholder={t("profile.labels.sources")}
                                                   value={t(getPermissionLabelResourceName(user?.permission.newsSources))}/>
                                        </Col>
                                    </Row>
                                    <Row className={cl.controlContainer}>
                                        <Col>
                                            {t("profile.labels.users")}
                                        </Col>
                                        <Col>
                                            <Input disabled={true}
                                                   placeholder={t("profile.labels.users")}
                                                   value={t(getPermissionLabelResourceName(user?.permission.userLog))}/>
                                        </Col>
                                    </Row>
                                    <Row className={cl.controlContainer}>
                                        <Col>
                                            {t("profile.labels.views")}
                                        </Col>
                                        <Col>
                                            <Input disabled={true}
                                                   placeholder={t("profile.labels.views")}
                                                   value={t(getPermissionLabelResourceName(user?.permission.views))}/>
                                        </Col>
                                    </Row>
                                    <Row className={cl.controlContainer}>
                                        <Col>
                                            {t("profile.labels.settings")}
                                        </Col>
                                        <Col>
                                            <Input disabled={true}
                                                   placeholder={t("profile.labels.settings")}
                                                   value={t(getPermissionLabelResourceName(user?.permission.settings))}/>
                                        </Col>
                                    </Row>
                                    <Row className={cl.controlContainer}>
                                        <Col>
                                            {t("profile.labels.admins")}
                                        </Col>
                                        <Col>
                                            <Input disabled={true}
                                                   placeholder={t("profile.labels.admins")}
                                                   value={t(getPermissionLabelResourceName(user?.permission.admins))}/>
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        }
                    </Row>
                </Container>
            </FormLayout>
            <ChangePasswordDialog open={changePasswordDialogOpen}
                                  onOverlayClick={() => setChangePasswordDialogOpen(false)}
                                  close={() => setChangePasswordDialogOpen(false)}/>
        </>
    );
};

export default Profile;