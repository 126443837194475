import React from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Col, Container, Row} from "react-bootstrap";
import Input from "../../../../ui/components/Input/Input";
import TextArea from "../../../../ui/components/TextArea/TextArea";

const SettingForm = ({name, value, settingDescription, notes, onSubmit}) => {
    const {t} = useTranslation();

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name,
            value: value,
            settingDescription: settingDescription,
            notes: notes
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("setting.form.name"))),
        }),
        onSubmit: onSubmit
    });

    return (
        <Container fluid>
            <form id='setting-form' onSubmit={form.handleSubmit}>
                <Row>
                    <Col>
                        <div className='input-container'>
                            <label className='label required'>
                                {t("setting.form.name")}
                            </label>
                            <Input name='name'
                                   type='text'
                                   placeholder={t("setting.form.name")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.name}/>
                            {form.touched.name && form.errors.name ?
                                <div className='field-error'>{form.errors.name}</div> : null}
                        </div>

                        <div className='input-container'>
                            <label className='label'>
                                {t("setting.form.value")}
                            </label>
                            <Input name='value'
                                   type='text'
                                   placeholder={t("setting.form.value")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.value}/>
                        </div>

                        <div className='input-textarea-container'>
                            <label className='label'>
                                {t("setting.form.description")}
                            </label>
                            <TextArea name='settingDescription'
                                      placeholder={t("setting.form.description")}
                                      disabled={form.isSubmitting}
                                      onChange={form.handleChange}
                                      autoComplete='off'
                                      value={form.values.settingDescription}/>
                        </div>

                        <div className='input-textarea-container'>
                            <label className='label'>
                                {t("setting.form.notes")}
                            </label>
                            <TextArea name='notes'
                                      placeholder={t("setting.form.notes")}
                                      disabled={form.isSubmitting}
                                      onChange={form.handleChange}
                                      autoComplete='off'
                                      value={form.values.notes}/>
                        </div>
                    </Col>
                </Row>
            </form>
        </Container>
    );
};

export default SettingForm;