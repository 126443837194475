import React, {useState} from 'react';
import cl from './ToggleSidebarItem.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";

const ToggleSidebarItem = ({path, icon, text, children}) => {
    const [active, setActive] = useState(false);

    const onClick = (ev) => {
        ev.preventDefault();

        setActive(prev => !prev);
    }
    return (
        <>
            <div className={cl.toggleSidebarItem}>
                <NavLink to={path}
                         onClick={onClick}
                         className={({isActive}) => isActive ? `${cl.active}` : ''}>
                     <span>
                        {icon}
                    </span>
                    {text}
                    <span className={cl.caretIconContainer}>
                       {active
                           ? <FontAwesomeIcon icon={faCaretDown}/>
                           : <FontAwesomeIcon icon={faCaretUp}/>
                       }
                    </span>
                </NavLink>
            </div>
            <div className={cl.childrenItemsContainer}>
                {active && children}
            </div>
        </>
    );
};

export default ToggleSidebarItem;