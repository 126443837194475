import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import moment from "moment/moment";
import * as Yup from "yup";
import {Container, Row, Col} from "react-bootstrap";
import Target from "../../../ui/components/Target/Target";
import cl from './HomeOwnerPollForm.module.css';
import {Query} from "@syncfusion/ej2-data";
import DropDownList from "../../../ui/components/DropDownList/DropDownList";
import Input from "../../../ui/components/Input/Input";



const HomeOwnerPollForm = ({
                      id,
                      name,
                      quizeUrl,
                      onSubmit
                  }) => {

    const {t} = useTranslation();

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: id,
            quizeUrl: quizeUrl,

            name: name,
        },

        onSubmit: onSubmit
    });

    return (
        <Container fluid>
            <form id='page-form' onSubmit={form.handleSubmit}>

                <Row className={cl.formRow}>
                    <Col>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label required'>
                                {t("homeowner.form.name")}
                            </label>
                            <Input name='name'
                                   type='text'
                                   placeholder={t("homeowner.form.name")}
                                   disabled={true}
                                   autoComplete='off'
                                   value={form.values.name}/>
                        </div>
                    </Col>
                    <Col>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label'>
                                {t("homeowner.form.quize")}
                            </label>
                            <Input name='quizeUrl'
                                   type='text'
                                   placeholder={t("homeowner.form.quize")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.quizeUrl}/>
                        </div>
                    </Col>
                </Row>


            </form>

        </Container>
    );
};

export default HomeOwnerPollForm;
