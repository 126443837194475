export const useDownloadFile = ({
                                    request,
                                    beforeDownload,
                                    afterDownload,
                                    actionFailure,
                                    getFileName
                                }) => {
    const download = async () => {
        try {
            if (beforeDownload)
                beforeDownload();
            const {data} = await request();

            const url = URL.createObjectURL(new Blob([data]));
            const elem = document.createElement('a');

            elem.href = url;

            elem.setAttribute('download', getFileName());

            document.body.appendChild(elem);

            elem.click();

            if (afterDownload)
                afterDownload();

            URL.revokeObjectURL(url);
        } catch (e) {
            actionFailure(e);
        }
    };

    return {download};
}