import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../../../ui/components/Dialog/Dialog";
import {getPollTypeName} from "../../utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrashCanArrowUp} from "@fortawesome/free-solid-svg-icons";
import cl from './PollQuestionOpinionScaleList.module.css';
import NestedPollQuestionForm from "../../NestedPollQuestionForm/NestedPollQuestionForm";

function generateOpinionList(items) {
    if (!items || items.length === 0) {
        return [
            {
                id: null,
                point: 1,
                questionText: '',
                multipleSelection: false,
                isRequired: false,
                pollId: null,
                parentQuestionId: null,
                pollQuestions: null,
                type: null
            },
            {
                id: null,
                point: 2,
                questionText: '',
                multipleSelection: false,
                isRequired: false,
                pollId: null,
                parentQuestionId: null,
                pollQuestions: null,
                type: null
            },
            {
                id: null,
                point: 3,
                questionText: '',
                multipleSelection: false,
                isRequired: false,
                pollId: null,
                parentQuestionId: null,
                pollQuestions: null,
                type: null
            },
            {
                id: null,
                point: 4,
                questionText: '',
                multipleSelection: false,
                isRequired: false,
                pollId: null,
                parentQuestionId: null,
                pollQuestions: null,
                type: null
            },
        ];
    }

    return items;
}

const getOpinionScaleTextByPoint = (point) => {
    switch (point) {
        case 1:
            return 'Точно нет';
        case 2:
            return 'Скорее нет';
        case 3:
            return 'Скорее да, чем нет';
        case 4:
            return 'Точно да';
    }
}

const PollQuestionOpinionScaleList = ({items, disabled, change}) => {
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [item, setItem] = useState(null);
    const [opinions, setOpinions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        if (items && items.length > 0) {
            setIsLoading(prev => !prev);

            setOpinions(items);

            setIsLoading(prev => !prev);
        } else {
            setIsLoading(prev => !prev);

            setOpinions(generateOpinionList(opinions));

            setIsLoading(prev => !prev);
        }
    }, []);

    useEffect(() => {
        if (change) {
            change(opinions);
        }
    }, [opinions]);

    const editItem = (item) => {
        if (!disabled) {
            setItem(item);
            setIsEditDialogOpen(prev => !prev)
        }
    }

    const onSave = (item) => {
        if (item) {
            setOpinions([
                ...opinions.filter(p => p.point !== item.point),
                item
            ]);

            setIsEditDialogOpen(prev => !prev);
        }
    }

    const resetItem = (item) => {
        if (item) {
            const newItem = {
                id: null,
                point: item.point,
                questionText: '',
                multipleSelection: false,
                isRequired: false,
                pollId: null,
                parentQuestionId: null,
                pollQuestions: null
            };

            setOpinions([
                ...opinions.filter(p => p.point !== item.point),
                newItem
            ]);
        }
    }

    return (
        <>
            {item
                ?
                <Dialog open={isEditDialogOpen}
                        id={'poll-question-opinion-form'}
                        usePortalToDocumentBody={true}
                        width='850px'
                        height='550px'>
                    <NestedPollQuestionForm nestedQuestion={item}
                                            close={() => setIsEditDialogOpen(prev => !prev)}
                                            onSave={onSave}/>
                </Dialog>
                : null
            }
            <div className={cl.container}>
                {isLoading
                    ? <div className={cl.loaderContainer}>
                        <div className='loader'></div>
                    </div>
                    : null
                }
                {opinions !== null && opinions.length > 0
                    ? <table className={cl.table}>
                        <tbody>
                        <tr>
                            <th style={{width: '180px'}}>{t("poll.form.opinion-scale-name")}</th>
                            <th style={{width: 'calc(100% - 410px)'}}>{t("poll.form.additional-question")}</th>
                            <th style={{width: '180px'}}>{t("poll.form.type")}</th>
                            <th style={{width: '50px'}}></th>
                            <th style={{width: '50px'}}></th>
                        </tr>
                        {opinions.sort((first, second) => first.point - second.point).map(p => {
                            return (
                                <tr key={p.point}>
                                    <td>
                                        {getOpinionScaleTextByPoint(p.point)}
                                    </td>
                                    <td>
                                        {p.questionText !== null
                                            ? p.questionText
                                            : null
                                        }
                                    </td>
                                    <td>
                                        {getPollTypeName(t, p.type)}
                                    </td>
                                    <td>
                                        <FontAwesomeIcon className={cl.edit} onClick={() => editItem(p)} icon={faEdit}/>
                                    </td>
                                    <td>
                                        <FontAwesomeIcon className={cl.reset} onClick={() => resetItem(p)}
                                                         icon={faTrashCanArrowUp}/>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    : null
                }
            </div>
        </>
    );
};

export default PollQuestionOpinionScaleList;