import React from 'react';
import {useTranslation} from "react-i18next";
import DropdownGridFilter from "../../../../ui/components/Grid/filters/DropdownGridFilter";

const UserLogActivityCodeFilter = ({onFilterChange, ...rest}) => {
    const {t} = useTranslation();

    const activityCodeFilterData = [
        {id: '0', text: t("userLog.userLogActivityCode.0")},
        {id: '1', text: t("userLog.userLogActivityCode.1")},
        {id: '2', text: t("userLog.userLogActivityCode.2")},
        {id: '3', text: t("userLog.userLogActivityCode.3")},
        {id: '4', text: t("userLog.userLogActivityCode.4")},
        {id: '5', text: t("userLog.userLogActivityCode.5")},
        {id: '6', text: t("userLog.userLogActivityCode.6")},
        {id: '7', text: t("userLog.userLogActivityCode.7")},
        {id: '8', text: t("userLog.userLogActivityCode.8")},
        {id: '9', text: t("userLog.userLogActivityCode.9")},
        {id: '10', text: t("userLog.userLogActivityCode.10")},
        {id: '11', text: t("userLog.userLogActivityCode.11")},
        {id: '12', text: t("userLog.userLogActivityCode.12")},
        {id: '13', text: t("userLog.userLogActivityCode.13")},
        {id: '14', text: t("userLog.userLogActivityCode.14")},
        {id: '15', text: t("userLog.userLogActivityCode.15")},
    ];

    return <DropdownGridFilter onChange={onFilterChange} {...rest} data={activityCodeFilterData}/>
};

export default UserLogActivityCodeFilter;