import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSelectors";
import {useNavigate} from "react-router-dom";
import PermissionType from "../../../../data/enums/PermissionType";
import GridTabSection, {GridTabSectionType} from "../../../ui/components/Grid/GridTab/GridTabSection/GridTabSection";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GridTabItem from "../../../ui/components/Grid/GridTab/GridTabItem/GridTabItem";
import {faAdd, faRefresh} from "@fortawesome/free-solid-svg-icons";
import GridTab from "../../../ui/components/Grid/GridTab/GridTab";
import Loader from './../../../ui/components/Loader/Loader';
import {Query} from "@syncfusion/ej2-data";
import {ColumnDirective, ColumnsDirective, Filter, Inject, Page as Pager, Sort} from "@syncfusion/ej2-react-grids";
import GridComponent from "../../../ui/components/Grid/GridComponent";

const HomeOwnerPoll = () => {
    const {t} = useTranslation();
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();


    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [filterTrigger, setFilterTrigger] = useState(null);
    const [query, setQuery] = useState(null);

    useEffect(() => {
        setQuery((prev) => {


            const q = new Query();

            if (prev && prev.params && prev.params.length > 0) {

                for (const item of prev.params) {
                    q.addParams(item.key, item.value);
                }
            }


            return q;
        });
    }, []);


    const onTabItemClick = ({id, queryParameterName, queryKey}) => {
        if (id) {
            if (id === 'refresh-btn') {
                setRefreshTrigger(prev => prev + 1);
            } else if (id === 'add-btn') {
                navigate('/management/homeownerpolls/homeownerpoll/');
            }

        }
    }

    const onRowDoubleClick = ev => {
        if (ev && ev.rowData) {
            navigate(`/management/homeownerpolls/homeownerpoll/${ev.rowData.id}`);
        }
    }

    return (
        <div className='list-container'>
                  <GridTab onClick={onTabItemClick}>

                <GridTabSection type={GridTabSectionType.Button}>

                    <GridTabItem id='refresh-btn'
                                 text={t("btn.refresh")}
                                 icon={<FontAwesomeIcon icon={faRefresh}/>}>

                    </GridTabItem>
                </GridTabSection>
            </GridTab>
            {!query
                ? <Loader/>
                : <GridComponent id='grid'
                                 container='.list-container'
                                 url='/api/homeowner/getHomeOwnerPollList'
                                 query={query}
                                 filterStoreEntityName={'homeOwnerPoll'}
                                 onRowClick={onRowDoubleClick}
                                 refresh={refreshTrigger}
                                 filter={filterTrigger}
                                 sortSettings={{columns: [{field: 'createdOn', direction: 'Descending'}]}}>
                    <ColumnsDirective>
                        <ColumnDirective field='id'
                                         visible={false}
                                         textAlign="center"
                                         isPrimaryKey={true}
                                         type='number'/>


                        <ColumnDirective field='name'
                                         headerText={t("homeowner.grid.name")}
                                         width='350'
                                         textAlign='center'/>

                        <ColumnDirective field='quizeUrl'
                                         headerText={t("homeowner.grid.quize")}
                                         width='200'
                                         textAlign='center'/>

                    </ColumnsDirective>
                    <Inject services={[Pager, Sort, Filter]}/>
                </GridComponent>
            }
        </div>
    );
}
export default HomeOwnerPoll;
