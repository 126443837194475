import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSelectors";
import React, {useState} from "react";
import {Query} from "@syncfusion/ej2-data";
import {useTranslation} from "react-i18next";
import GridTab from "../../../ui/components/Grid/GridTab/GridTab";
import GridTabSection, {GridTabSectionType} from "../../../ui/components/Grid/GridTab/GridTabSection/GridTabSection";
import PermissionType from "../../../../data/enums/PermissionType";
import GridTabItem from "../../../ui/components/Grid/GridTab/GridTabItem/GridTabItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faRefresh} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../ui/components/Loader/Loader";
import GridComponent from "../../../ui/components/Grid/GridComponent";
import {ColumnDirective, ColumnsDirective, Filter, Inject, Page, Sort} from "@syncfusion/ej2-react-grids";
import {useNavigate} from "react-router-dom";
import {selectSettingFilters} from "../../../../features/filter/filterSelectors";

const Setting = () => {
    const user = useSelector(selectCurrentUser);
    const [query] = useState(new Query());
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const onTabItemClick = ({id}) => {
        if (id) {
            if (id === 'refresh-btn') {
                setRefreshTrigger(prev => prev + 1);
            }

            if (id === 'add-btn') {
                navigate(`/management/settings/setting/`);
            }
        }
    }

    const onRowDoubleClick = ev => {
        if (ev && ev.rowData) {
            if (ev.rowData.innerName === 'NEWSGRABBER_SETTINGS') {
                navigate(`/management/settings/grabber`);
            } else {
                navigate(`/management/settings/setting/${ev.rowData.id}`);
            }
        }
    }

    return (
        <div className='list-container'>
            <GridTab onClick={onTabItemClick}>
                <GridTabSection type={GridTabSectionType.Button}>
                    {user.permission.settings === PermissionType.Editing
                        ? (
                            <GridTabItem id='add-btn'
                                         text={t("btn.add")}
                                         icon={<FontAwesomeIcon icon={faAdd}/>}>

                            </GridTabItem>
                        )
                        : null
                    }
                    <GridTabItem id='refresh-btn'
                                 text={t("btn.refresh")}
                                 icon={<FontAwesomeIcon icon={faRefresh}/>}>

                    </GridTabItem>
                </GridTabSection>
            </GridTab>
            {!query
                ? <Loader/>
                : <GridComponent id='grid'
                                 filterStoreEntityName={'setting'}
                                 container='.list-container'
                                 url='/api/setting/getSettings'
                                 query={query}
                                 onRowClick={onRowDoubleClick}
                                 refresh={refreshTrigger}>
                    <ColumnsDirective>
                        <ColumnDirective field='id'
                                         visible={false}
                                         textAlign="center"
                                         isPrimaryKey={true}
                                         type='number'/>
                        <ColumnDirective field='name'
                                         headerText={t("setting.grid.name")}
                                         width='50%'
                                         textAlign="center"/>
                        <ColumnDirective field='value'
                                         headerText={t("setting.grid.value")}
                                         width='50%'
                                         textAlign="center"/>
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter]}/>
                </GridComponent>
            }
        </div>
    );
}

export default Setting;