import React from 'react';
import cl from './UsersChartSection.module.css';
import {Col, Row} from "react-bootstrap";
import UsersRegistrationsChart from "./charts/UsersRegistrationsChart";
import UsersRegistrationsPieChart from "./charts/UsersRegistrationsPieChart";

const UsersChartSection = ({isLoading, data}) => {
    return (
        <div className={cl.chartContainer}>
            <Row>
                <Col>
                    <UsersRegistrationsChart data={data.reg} isLoading={isLoading}/>
                </Col>
            </Row>
            <Row style={{marginTop: '10px'}}>
                <Col>
                    <UsersRegistrationsPieChart data={data.regBySection} isLoading={isLoading}/>
                </Col>
                <Col>
                </Col>
            </Row>
        </div>
    );
};

export default UsersChartSection;