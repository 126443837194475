import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useServerError} from "../../../../../../hooks/useServerError";
import {useFormik} from "formik";
import * as Yup from "yup";
import apiAxios from "../../../../../../app/api/apiAxios";
import ResultCode from "../../../../../../data/enums/ResultCode";
import {toast} from "react-toastify";
import getServerErrorResourceName from "../../../../../../data/apiErrors/apiErrors";
import FormLayout from "../../../../../ui/layouts/FormLayout/FormLayout";
import Button, {ButtonType} from "../../../../../ui/components/Button/Button";
import {Col, Container, Row} from "react-bootstrap";
import Input from "../../../../../ui/components/Input/Input";

const AccountSyncForm = ({close, onSyncSuccess}) => {
    const {t} = useTranslation();
    const [serverError, setServerError] = useServerError(null);
    const [isLoading, setIsLoading] = useState(false);

    const form = useFormik({
        initialValues: {
            number: ''
        },
        validationSchema: Yup.object({
            number: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("account.grid.number"))),
        }),
        onSubmit: async (values, actions) => {
            try {
                setServerError(null);
                actions.setSubmitting(true);
                setIsLoading(prev => !prev);

                const response = await apiAxios.post(`${process.env.REACT_APP_BE_DOMAIN}/api/account/syncAccountFromCrmByNumber`, {
                    number: values.number
                });
                if (response) {
                    if (response.status === 200 && response.data) {
                        if (response.data.resultCode === ResultCode.Success) {
                            const timeOut = setTimeout(() => {
                                clearTimeout(timeOut);
                                toast.success(t("account.sync.sync-label"));

                                if (onSyncSuccess) {
                                    actions.setSubmitting(false);
                                    setIsLoading(prev => !prev);

                                    onSyncSuccess();
                                }
                            }, 10000);
                        } else {
                            setServerError(t(getServerErrorResourceName({
                                model: 'account',
                                resultCode: response.data.resultCode,
                                description: response.data.description
                            })));

                            actions.setSubmitting(false);
                            setIsLoading(prev => !prev);
                        }
                    }
                }
            } catch (err) {
                if (err && err.message) {
                    setServerError(`${t(getServerErrorResourceName({
                        model: 'account',
                        resultCode: ResultCode.ServerError
                    }))} Message: (${err.message})`);
                }
                actions.setSubmitting(false);
                setIsLoading(prev => !prev);
            }
        }
    });

    return (
        <FormLayout isLoading={form.isSubmitting || isLoading}
                    header={(
                        <div className='header-form-layout'>
                            {t("account.sync.sync-from-crm-label")}
                        </div>
                    )}
                    footer={
                        <div className='footer-form-layout'>
                            <Button buttonType={ButtonType.Warning}
                                    width='100%'
                                    onClick={() => close()}
                                    disabled={form.isSubmitting}>
                                {t("btn.cancel")}
                            </Button>

                            <Button buttonType={ButtonType.Default}
                                    width='100%'
                                    form='sync-form'
                                    type='submit'
                                    disabled={form.isSubmitting}>
                                {t("account.btn.sync-from-crm-short")}
                            </Button>
                        </div>
                    }>
            <div className='default-form-layout-container'>
                {serverError &&
                    <div className='server-error-container'>
                        <div>
                            {serverError}
                        </div>
                    </div>
                }
                <Container fluid>
                    <form id='sync-form'
                          onSubmit={form.handleSubmit}>
                        <Row>
                            <Col>
                                <div className='input-container'>
                                    <label className='label required'>
                                        {t("account.grid.number")}
                                    </label>
                                    <Input name='number'
                                           type='text'
                                           placeholder={t("account.grid.number")}
                                           disabled={form.isSubmitting}
                                           onChange={form.handleChange}
                                           autoComplete='off'
                                           value={form.values.phone}/>
                                    {form.touched.number && form.errors.number ?
                                        <div className='field-error'>{form.errors.number}</div> : null}
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Container>
            </div>
        </FormLayout>
    );
};

export default AccountSyncForm;