import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Col, Container, Row} from "react-bootstrap";
import ImageUploader from "../../../ui/components/ImageUploader/ImageUploader";
import Target from "../../../ui/components/Target/Target";
import cl from './PostSourceForm.module.css';
import Input from "../../../ui/components/Input/Input";
import TextArea from "../../../ui/components/TextArea/TextArea";
import DropDownList from "../../../ui/components/DropDownList/DropDownList";
import {Query} from "@syncfusion/ej2-data";

const PostSourceForm = ({
                            name,
                            isBlocked,
                            postSourceDescription,
                            requireManualModeration,
                            postCategoryId,
                            postSourceTypeId,
                            logo,
                            logoName,
                            logoSize,
                            targets,
                            onSubmit
                        }) => {
    const {t} = useTranslation();
    const [postCategoryQuery] = useState(new Query().addParams('isBlocked', 'active'));
    const [postSourceTypeQuery] = useState(new Query().addParams('isBlocked', 'active'));

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name,
            isBlocked: isBlocked,
            postSourceDescription: postSourceDescription ? postSourceDescription : '',
            requireManualModeration: requireManualModeration,
            postCategoryId: postCategoryId,
            postSourceTypeId: postSourceTypeId,
            image: {
                image: logo,
                name: logoName,
                size: logoSize
            },
            targets: targets,
            cropperSettings: postCategoryId === 5
                ? {width: 720, height: 320}
                : {width: 320, height: 320}
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("postSource.form.name"))),
            postCategoryId: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("postSource.form.category"))),
            postSourceTypeId: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("postSource.grid.type"))),
        }),
        onSubmit: onSubmit
    });

    return (
        <Container fluid>
            <form id='post-source-form' onSubmit={form.handleSubmit}>
                <Row>
                    <Col>
                        <div className='input-container'>
                            <label className='label required'>
                                {t("postSource.form.name")}
                            </label>
                            <Input name='name'
                                   type='text'
                                   placeholder={t("postSource.form.name")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.name}/>
                            {form.touched.name && form.errors.name ?
                                <div className='field-error'>{form.errors.name}</div> : null}
                        </div>

                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label required'>
                                {t("postSource.form.category")}
                            </label>
                            <DropDownList url='/api/postCategory/getPostCategoriesList'
                                          popupHeight='250px'
                                          query={postCategoryQuery}
                                          change={(ev) => form.setFieldValue('postCategoryId', ev.itemData ? ev.itemData.id : null)}
                                          enabled={!form.isSubmitting}
                                          placeholder={t("postSource.form.category")}
                                          value={form.values.postCategoryId}
                                          showClearButton={true}/>
                            {form.touched.postCategoryId && form.errors.postCategoryId ?
                                <div className='field-error'>{form.errors.postCategoryId}</div> : null}
                        </div>

                        <div className='input-textarea-container'>
                            <label className='label'>
                                {t("postSource.form.description")}
                            </label>
                            <TextArea name='postSourceDescription'
                                      placeholder={t("postSource.form.description")}
                                      disabled={form.isSubmitting}
                                      onChange={form.handleChange}
                                      value={form.values.postSourceDescription}/>
                        </div>
                    </Col>
                    <Col>
                        <div className={`input-container ${cl.checkboxesContainer}`}>
                            <div className='input-container checkbox'>
                                <label className='label'>
                                    {t("postSource.form.blocked")}
                                </label>
                                <Input name='isBlocked'
                                       type='checkbox'
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       checked={form.values.isBlocked}/>
                            </div>

                            <div className='input-container checkbox'>
                                <label className='label'>
                                    {t("postSource.form.manual-moderation")}
                                </label>
                                <Input name='requireManualModeration'
                                       type='checkbox'
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       checked={form.values.requireManualModeration}/>
                            </div>
                        </div>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label required'>
                                {t("postSource.grid.type")}
                            </label>

                            <DropDownList url='/api/postSourceType/getPostSourceTypesList'
                                          popupHeight='250px'
                                          query={postSourceTypeQuery}
                                          change={(ev) => form.setFieldValue('postSourceTypeId', ev.itemData ? ev.itemData.id : null)}
                                          enabled={!form.isSubmitting}
                                          placeholder={t("postSource.grid.type")}
                                          value={form.values.postSourceTypeId}
                                          showClearButton={true}/>
                            {form.touched.postSourceTypeId && form.errors.postSourceTypeId ?
                                <div className='field-error'>{form.errors.postSourceTypeId}</div> : null}
                        </div>
                    </Col>
                </Row>
            </form>
            <Row className={cl.formRow}>
                <Col>
                    {form.errors.image?.image ?
                        <div className='field-error'>{form.errors.image.image}</div> : null}
                    <ImageUploader {...form.values.image}
                                   disabled={form.isSubmitting}
                                   cropperSettings={form.values.cropperSettings}
                                   change={ev => form.setFieldValue('image', ev)}/>
                </Col>
                <Col>
                    <Target targets={form.values.targets}
                            disabled={form.isSubmitting}
                            change={(items) => form.setFieldValue('targets', items)}/>
                </Col>
            </Row>
        </Container>
    );
};

export default PostSourceForm;
