import React, {memo, useState} from 'react';
import {Query} from "@syncfusion/ej2-data";
import {useTranslation} from "react-i18next";
import AuthRemoteDropDownListComponent
    from "../../../../../../ui/components/AuthRemoteDropDownListComponent/AuthRemoteDropDownListComponent";

const PostSourceDropDown = memo(({isSubmitting, value, onChange}) => {
    const {t} = useTranslation();
    const [query] = useState(new Query().addParams('isBlocked', 'active'));

    const onValueChange = (ev) => {
        if (ev && onChange) {
            if (ev.itemData) {
                onChange('sourceId', ev.itemData.id);
            } else {
                onChange('sourceId', null);
            }
        }
    }

    return (
        <AuthRemoteDropDownListComponent url='/api/postSource/getPostSourcesList'
                                         popupHeight='250px'
                                         enabled={!isSubmitting}
                                         change={onValueChange}
                                         placeholder={t("setting.form.source")}
                                         value={value}
                                         showClearButton={true}
                                         query={query}>

        </AuthRemoteDropDownListComponent>
    );
});

export default PostSourceDropDown;