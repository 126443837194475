import React, {useCallback, useEffect, useState} from "react";
import PostCategorySelector from "./selectors/PostCategorySelector";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSelectors";
import {useNavigate} from "react-router-dom";
import PermissionType from "../../../../data/enums/PermissionType";
import GridTabSection, {GridTabSectionType} from "../../../ui/components/Grid/GridTab/GridTabSection/GridTabSection";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GridTabItem from "../../../ui/components/Grid/GridTab/GridTabItem/GridTabItem";
import {faAdd, faRefresh} from "@fortawesome/free-solid-svg-icons";
import GridTab from "../../../ui/components/Grid/GridTab/GridTab";
import Loader from './../../../ui/components/Loader/Loader';
import {Query} from "@syncfusion/ej2-data";
import {ColumnDirective, ColumnsDirective, Filter, Inject, Page as Pager, Sort} from "@syncfusion/ej2-react-grids";
import GridComponent from "../../../ui/components/Grid/GridComponent";
import PageCheckboxFilter from "./filters/PageCheckboxFilter";
import PageDateTemplate from "./templates/PageDateTemplate";
import DateRangeGridFilter from "../../../ui/components/Grid/filters/DateRangeGridFilter";

const Page = () => {
    const {t} = useTranslation();
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();

    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [tab, setTab] = useState(null);
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [filterTrigger, setFilterTrigger] = useState(null);
    const [query, setQuery] = useState(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        setTab(urlParams.get('tab') ? urlParams.get('tab') : '0');
    }, []);

    useEffect(() => {
        setQuery((prev) => {
            if(!tab || !selectedCategoryId){
                return null;
            }

            const q = new Query();

            if (prev && prev.params && prev.params.length > 0) {
                const params = prev.params.filter(item => item.key !== 'category' && item.key !== 'tab');
                for (const item of params) {
                    q.addParams(item.key, item.value);
                }
            }

            q.addParams('tab', tab.toString()).addParams('category', selectedCategoryId.toString());

            return q;
        });
    }, [tab, selectedCategoryId]);

    const onPostCategoryChange = useCallback(id => {
        setSelectedCategoryId(id);
    }, [])

    const onTabItemClick = ({id, queryParameterName, queryKey}) => {
        if (queryParameterName && queryKey) {
            setTab(queryKey);
        } else if (id) {
            if (id === 'refresh-btn') {
                setRefreshTrigger(prev => prev + 1);
            } else if (id === 'add-btn') {
                navigate('/news/pages/page/', {
                    state: {
                        category: selectedCategoryId
                    }
                });
            }
        }
    }

    const onRowDoubleClick = ev => {
        if (ev && ev.rowData) {
            navigate(`/news/pages/page/${ev.rowData.id}`);
        }
    }

    return (
        <div className='list-container'>
            <PostCategorySelector onChange={onPostCategoryChange}/>
            <GridTab onClick={onTabItemClick}>
                <GridTabSection type={GridTabSectionType.Tab}
                                showRightSeparator={true}
                                queryParameterName='tab'>
                    <GridTabItem id='available-tab'
                                 text={t("page.grid.available-tab")}
                                 queryKey='0'
                                 isDefaultActive={true}>

                    </GridTabItem>
                    <GridTabItem id='all-tab'
                                 text={t("shared.grid.all-tab")}
                                 queryKey='1'>

                    </GridTabItem>
                    <GridTabItem id='not-published-tab'
                                 text={t("page.grid.not-published-tab")}
                                 queryKey='2'>

                    </GridTabItem>
                    <GridTabItem id='blocked-tab'
                                 text={t("shared.grid.blocked-tab")}
                                 queryKey='3'>

                    </GridTabItem>
                </GridTabSection>
                <GridTabSection type={GridTabSectionType.Button}>
                    {user.permission.views === PermissionType.Editing
                        ? (
                            <GridTabItem id='add-btn'
                                         text={t("btn.add")}
                                         icon={<FontAwesomeIcon icon={faAdd}/>}>

                            </GridTabItem>
                        )
                        : null
                    }
                    <GridTabItem id='refresh-btn'
                                 text={t("btn.refresh")}
                                 icon={<FontAwesomeIcon icon={faRefresh}/>}>

                    </GridTabItem>
                </GridTabSection>
            </GridTab>
            {!query
                ? <Loader/>
                : <GridComponent id='grid'
                                 container='.list-container'
                                 url='/api/page/getPages'
                                 query={query}
                                 filterStoreEntityName={'page'}
                                 onRowClick={onRowDoubleClick}
                                 refresh={refreshTrigger}
                                 filter={filterTrigger}
                                 sortSettings={{columns: [{field: 'createdOn', direction: 'Descending'}]}}>
                    <ColumnsDirective>
                        <ColumnDirective field='id'
                                         visible={false}
                                         textAlign="center"
                                         isPrimaryKey={true}
                                         type='number'/>
                        <ColumnDirective field='createdOn'
                                         headerText={t("page.grid.created-on")}
                                         width='150'
                                         textAlign="center"
                                         filterTemplate={(props) => <DateRangeGridFilter
                                             onChange={(ev, fieldName) => setFilterTrigger({
                                                 ev,
                                                 fieldName
                                             })} filterStoreEntityName={'page'} {...props}/>}
                                         template={(props) => <PageDateTemplate {...props}/>}/>
                        <ColumnDirective field='publicationDate'
                                         headerText={t("page.grid.publish-date")}
                                         width='150'
                                         textAlign='center'
                                         filterTemplate={(props) => <DateRangeGridFilter
                                             onChange={(ev, fieldName) => setFilterTrigger({
                                                 ev,
                                                 fieldName
                                             })} filterStoreEntityName={'page'} {...props}/>}
                                         template={(props) => <PageDateTemplate {...props}/>}/>
                        <ColumnDirective field='title'
                                         headerText={t("page.grid.title")}
                                         width='350'
                                         textAlign='center'/>
                        <ColumnDirective field='targetLabel'
                                         headerText={t("page.grid.filter")}
                                         width='200'
                                         textAlign='center'
                                         allowSorting={false}
                                         allowFiltering={false}/>
                        <ColumnDirective field='isDraft'
                                         headerText={t("page.grid.draft")}
                                         width='200'
                                         displayAsCheckBox={true}
                                         textAlign="center"
                                         filterTemplate={(props) => <PageCheckboxFilter
                                             onChange={(ev, fieldName) => setFilterTrigger({
                                                 ev,
                                                 fieldName
                                             })} filterStoreEntityName={'page'} {...props}/>}/>
                        <ColumnDirective field='isBlocked'
                                         headerText={t("page.grid.blocked")}
                                         width='200'
                                         displayAsCheckBox={true}
                                         textAlign="center"
                                         filterTemplate={(props) => <PageCheckboxFilter
                                             onChange={(ev, fieldName) => setFilterTrigger({
                                                 ev,
                                                 fieldName
                                             })} filterStoreEntityName={'page'} {...props}/>}/>
                    </ColumnsDirective>
                    <Inject services={[Pager, Sort, Filter]}/>
                </GridComponent>
            }
        </div>
    );
}
export default Page;