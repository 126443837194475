import React, {useEffect, useState} from 'react';
import UsersChartSelectorSection from "./SelectorSection/UsersChartSelectorSection";
import UsersChartSection from "./ChartSection/UsersChartSection";
import apiAxios from "../../../../app/api/apiAxios";
import moment from "moment/moment";
import {useDownloadFile} from "../../../../hooks/useDownloadFile";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import ProgressBarToast from "../../../ui/components/ProgressBarToast/ProgressBarToast";

const UsersChart = () => {
    const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [endDate, setEndDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [percentage, setPercentage] = useState(0);
    const [show, setShow] = useState(false);
    const {t} = useTranslation();

    const createDownloadFileRequest = () => {
        const date = JSON.stringify({
            sDate: `${moment(startDate).format("DD.MM.YYYY")} 00:00`,
            eDate: `${moment(endDate).format("DD.MM.YYYY")} 23:59`,
        });

        return apiAxios.get(`${process.env.REACT_APP_BE_DOMAIN}/api/analytics/getUserRegistrationExcelReport?createdOn=${date}`, {
            responseType: 'blob',
            onDownloadProgress(ev) {
                let progress = Math.round((ev.loaded / ev.total) * 100);
                if (progress) {
                    setPercentage(progress);
                }
            }
        });
    }

    const {download} = useDownloadFile({
        request: createDownloadFileRequest,
        beforeDownload: () => setShow(prev => !prev),
        afterDownload: () => {
            setShow(prev => !prev);

            const timeoutId = setTimeout(() => {
                setPercentage(0);

                clearTimeout(timeoutId);
            }, 1000);
        },
        actionFailure: (ev) => {
            if (ev && ev.response) {
                if (ev.response.status === 500) {
                    toast.error(t("errors.download-file-server-error"));
                } else if (ev.response.status === 404) {
                    toast.error(t("analytics.errors.download-file-error-not-found"));
                }
            }

            setPercentage(0);
            setShow(prev => !prev);
        },
        getFileName: () => {
            const dates = {
                sDate: `${moment(startDate).format("DD.MM.YYYY")}`,
                eDate: `${moment(endDate).format("DD.MM.YYYY")}`
            };
            return `Регистрации пользователей[Дата][${dates.sDate} - ${dates.eDate}].xlsx`
        }
    });

    const onIntervalChange = (ev) => {
        if (ev && ev.startDate && ev.endDate) {
            setStartDate(ev.startDate);

            setEndDate(ev.endDate);
        }
    }

    useEffect(() => {
        setIsLoading(true);

        const date = JSON.stringify({
            sDate: `${moment(startDate).format("DD.MM.YYYY")} 00:00`,
            eDate: `${moment(endDate).format("DD.MM.YYYY")} 23:59`,
        });

        let endpoints = [
            `${process.env.REACT_APP_BE_DOMAIN}/api/analytics/getUsersRegistrationAnalyticsBySection?createdOn=${date}`,
            `${process.env.REACT_APP_BE_DOMAIN}/api/analytics/getUsersRegistrationAnalytics?createdOn=${date}`,
        ];


        Promise.all(endpoints.map((endpoint) => apiAxios.get(endpoint))).then(([{data: regBySection}, {data: reg}]) => {
            setData({
                regBySection,
                reg
            });

            setIsLoading(false);
        }).catch(function () {
            setData({
                regBySection: null,
                reg: null
            })
            setIsLoading(false);
        });
    }, [startDate, endDate]);

    return (
        <div className='list-container'>
            <ProgressBarToast show={show} percentage={percentage}/>
            <UsersChartSelectorSection isLoading={isLoading}
                                       startDate={startDate}
                                       endDate={endDate}
                                       onDateChange={onIntervalChange}
                                       onExportBtnClick={download}/>
            <UsersChartSection isLoading={isLoading} data={data}/>
        </div>
    );
};

export default UsersChart;
