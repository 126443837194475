import React from 'react';
import {useTranslation} from "react-i18next";
import DropdownGridFilter from "../../../../ui/components/Grid/filters/DropdownGridFilter";
import PostImageTemplate from "../PostImageTemplate";



const PostImageTemplateStateFilter = ({onFilterChange, ...rest}) => {
    const {t} = useTranslation();

    const stateFilterData = [
        {id: 'active', text: t("shared.grid.active")},
        {id: 'blocked', text: t("shared.grid.blocked")},
    ];

    return <DropdownGridFilter onChange={onFilterChange} {...rest} data={stateFilterData}/>
};

export default PostImageTemplateStateFilter;
