import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useServerError} from "../../../../../../hooks/useServerError";
import {useFormik} from "formik";
import FormLayout from "../../../../layouts/FormLayout/FormLayout";
import Button, {ButtonType} from "../../../Button/Button";
import {TabComponent} from "@syncfusion/ej2-react-navigations";
import SectionSection from "./SectionSection/SectionSection";
import HomeOwnerSection from "./HomeOwnerSection/HomeOwnerSection";
import SubSectionSection from "./SubSectionSection/SubSectionSection";
import {isTabDisabled, isTargetValid} from "../../utils";
import apiAxios from "../../../../../../app/api/apiAxios";
import ResultCode from "../../../../../../data/enums/ResultCode";
import getServerErrorResourceName from "../../../../../../data/apiErrors/apiErrors";
import {forEach} from "react-bootstrap/ElementChildren";


const TargetForm = ({target, close, save}) => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [serverError, setServerError] = useServerError(null);
    let ref;
    const [subSectionsData, setSubSectionsData] = useState([]);
    const [ownersData, setOwnersData] = useState([]);
    useEffect(() => {
        if (ref && target && target.targetType) {
            ref.select(target.targetType);
        }
    }, [target]);


    useEffect(() => {
       (async () => await loadOwners())();
        (async () => await loadSubSections())();
    }, []);
    const loadSubSections = async () => {

        // setIsLoading(true);
        const url = `${process.env.REACT_APP_BE_DOMAIN}/api/target/getSubSections`;

        try {
            const response = await apiAxios.get(url);

            if (response) {
                if (response.status === 200 && response.data) {


                    setSubSectionsData(response.data);
                }
            }

            // setIsLoading(false);
        } catch (e) {
            if (e && e.message) {
                setServerError(`${t(getServerErrorResourceName({
                    model: 'subSectionSection',
                    resultCode: ResultCode.ServerError
                }))} Message: (${e.message})`);
            }
            // setIsLoading(false);
        }

    };
    const loadOwners = async () => {

       // setIsLoading(true);
        const url = `${process.env.REACT_APP_BE_DOMAIN}/api/target/getHomeOwners`;

        try {
            const response = await apiAxios.get(url);

            if (response) {
                if (response.status === 200 && response.data) {


                    setOwnersData(response.data);
                }
            }

           // setIsLoading(false);
        } catch (e) {
            if (e && e.message) {
                setServerError(`${t(getServerErrorResourceName({
                    model: 'homeOwnerSection',
                    resultCode: ResultCode.ServerError
                }))} Message: (${e.message})`);
            }
           // setIsLoading(false);
        }

    };
    const form = useFormik({
        enableReinitialize: false,
        initialValues: target,
        onSubmit: async (values, actions) => {
            setIsLoading(prev => !prev);
            actions.setSubmitting(true);

            if (isTargetValid(values)) {
                save(values);
            } else {
                setServerError(t("locationTarget.not-valid"));
            }

            actions.setSubmitting(false);
            setIsLoading(prev => !prev);
        }
    });

    useEffect(() => {
        if (ref) {
            ref.enableTab(0, !isTabDisabled('0', form.values));
            ref.enableTab(1, !isTabDisabled('1', form.values));
            ref.enableTab(2, !isTabDisabled('2', form.values));
        }
    }, [form.values.section, form.values.homeOwner, form.values.subSection])

    return (
        <FormLayout header={<div className='default-form-layout-header'>{t("locationTarget.label")}</div>}
                    footer={(
                        <div className='default-form-layout-footer'>
                            <Button buttonType={ButtonType.Warning}
                                    onClick={close}
                                    disabled={form.isSubmitting || isLoading}>
                                {t("btn.close")}
                            </Button>
                            <Button buttonType={ButtonType.Default}
                                    type='submit'
                                    form='target-form'
                                    disabled={form.isSubmitting || isLoading}>
                                {t("btn.save")}
                            </Button>
                        </div>
                    )}
                    isLoading={isLoading}>
            <form onSubmit={form.handleSubmit}
                  id='target-form'>
                {serverError &&
                    <div className='server-error-container' style={{zIndex: '1001'}}>
                        <div>
                            {serverError}
                        </div>
                    </div>
                }
                <TabComponent id='target-tabs'
                              heightAdjustMode='Auto'
                              ref={rf => ref = rf}>
                    <div className="e-tab-header">
                        <div>{t("locationTarget.section")}</div>
                        <div>{t("locationTarget.home-owner")}</div>
                        <div>{t("locationTarget.sub-section")}</div>
                    </div>
                    <div className="e-content">
                        <div>
                            <SectionSection isLoading={isLoading}
                                            parking={form.values.parking}
                                           sectionValue={form.values.section.key}
                                           buildingTypeValue={form.values.buildingType}
                                           streetValue={form.values.street.key}
                                           buildingNumberValue={form.values.building.key}
                                           onBuildingChange={ev => form.setFieldValue('building', {
                                               key: ev && ev.itemData ? ev.itemData.id : null,
                                               value: ev && ev.itemData ? ev.itemData.text : null,
                                           })}
                                           onSectionChange={ev => form.setFieldValue('section', {
                                               key: ev && ev.itemData ? ev.itemData.id : null,
                                               value: ev && ev.itemData ? ev.itemData.text : null,
                                           })}
                                           onStreetChange={ev => form.setFieldValue('street', {
                                               key: ev && ev.itemData ? ev.itemData.id : null,
                                               value: ev && ev.itemData ? ev.itemData.text : null,
                                           })}
                                            onParkingChange={ev => form.setFieldValue('parking',  ev.target.checked)}
                                           onBuildingTypeChange={ev => form.setFieldValue('buildingType', ev && ev.itemData ? ev.itemData.id : null)}/>
                        </div>
                        <div>
                            <HomeOwnerSection isLoading={isLoading} data={ownersData}
                                           //  value={form.values.homeOwner.key}
                                             onHomeOwnerChange={ev =>

                                             {


                                                 form.setFieldValue('homeOwner', ev)

                                                 //form.setFieldValue('homeOwner', {
                                  //key: ev && ev.itemData ? ev.itemData.id : null,
                                    //           value: ev && ev.itemData ? ev.itemData.text : null,
                            //               })
                            }
                                             }
                            />
                        </div>
                        <div>
                            <SubSectionSection isLoading={isLoading} data={subSectionsData}
                                               parking={form.values.parking}
                                              //value={form.values.subSection.key}
                                               onParkingChange={ev => form.setFieldValue('parking', ev.target.checked)}
                                              onSubSectionChange={ev =>  form.setFieldValue('subSection', ev)/*form.setFieldValue('subSection', {
                                                  key: ev && ev.itemData ? ev.itemData.id : null,
                                                  value: ev && ev.itemData ? ev.itemData.text : null,
                                              })*/}/>
                        </div>
                    </div>
                </TabComponent>
            </form>
        </FormLayout>
    );
};

export default TargetForm;
