import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import moment from "moment/moment";
import * as Yup from "yup";
import {Container, Row, Col} from "react-bootstrap";
import Target from "../../../ui/components/Target/Target";
import cl from './LinkForm.module.css';
import {Query} from "@syncfusion/ej2-data";
import DropDownList from "../../../ui/components/DropDownList/DropDownList";
import Input from "../../../ui/components/Input/Input";
import {DateTimePickerComponent, Inject, MaskedDateTime} from "@syncfusion/ej2-react-calendars";
import {HtmlEditor, Link, Image, RichTextEditorComponent, Toolbar} from "@syncfusion/ej2-react-richtexteditor";



const LinkForm = ({
                      id,
                      createdOn,
                      value,
                      name,
                      isBlocked,
                      targets,
                      onSubmit
                  }) => {
    const [postCategoryQuery] = useState(new Query().addParams('isBlocked', 'active').addParams('name', '0'));

    const {t} = useTranslation();

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: id,
            createdOn: createdOn !== null ? moment.utc(createdOn).toDate() : null,
            isBlocked: isBlocked,
            value: value,
            name: name,
            targets: targets
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("link.form.category"))),
        }),
        onSubmit: onSubmit
    });

    return (
        <Container fluid>
            <form id='page-form' onSubmit={form.handleSubmit}>
                <Row>
                    <Col>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label required'>
                                {t("link.form.category")}
                            </label>
                            <DropDownList url='/api/link/getLinkNames'
                                          popupHeight='250px'
                                          query={postCategoryQuery}
                                          change={(ev) => form.setFieldValue('name', ev.itemData ? ev.itemData.id : null)}
                                          enabled={!form.isSubmitting && id <= 0}
                                          placeholder={t("link.form.category")}
                                          value={form.values.name}
                                          showClearButton={false}/>
                            {form.touched.name && form.errors.name ?
                                <div className='field-error'>{form.errors.name}</div> : null}
                        </div>
                    </Col>
                    <Col className={cl.checkboxesContainer}>
                        <div className='input-container checkbox'>
                            <label className='label'>
                                {t("link.form.blocked")}
                            </label>
                            <Input name='isBlocked'
                                   type='checkbox'
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   checked={form.values.isBlocked}/>
                        </div>

                    </Col>
                </Row>
                <Row className={cl.formRow}>
                    <Col>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label required'>
                                {t("link.form.value")}
                            </label>
                            <Input name='value'
                                   type='text'
                                   placeholder={t("link.form.value")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.value}/>
                        </div>
                    </Col>

                </Row>


            </form>
            <Row className={cl.formRow}>

                <Col>
                    <Target targets={form.values.targets}
                            disabled={form.isSubmitting}
                            change={(items) => form.setFieldValue('targets', items)}/>
                </Col>
            </Row>
        </Container>
    );
};

export default LinkForm;
