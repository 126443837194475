import React from 'react';
import Dialog from "../../../Dialog/Dialog";
import "cropperjs/dist/cropper.css";
import CropForm from "../CropForm/CropForm";

const CropDialog = ({open, ...rest}) => {
    return (
        <Dialog width='70%'
                height='60%'
                open={open}>
            <CropForm {...rest}/>
        </Dialog>
    );
};

export default CropDialog;