import React from 'react';
import cl from './SidebarItem.module.css';
import {NavLink} from "react-router-dom";

const SidebarItem = ({icon, text, path, ...rest}) => {
    return (
        <div className={cl.sidebarItem}>
            <NavLink to={path} {...rest}
                     className={({ isActive }) => isActive ? (icon ? cl.active : `${cl.active} ${cl.indent}`) : (icon ? '' : cl.indent)}>
                {icon &&
                    <span>
                        {icon}
                    </span>
                }
                {text}
            </NavLink>
        </div>
    );
};

export default SidebarItem;