import cl from './ActiveUsersChartSection.module.css';
import {
    ChartComponent,
    ColumnSeries,
    Legend,
    SeriesCollectionDirective,
    SeriesDirective,
    Tooltip,
    DataLabel,
    Category,
    LineSeries
} from "@syncfusion/ej2-react-charts";
import {Inject} from "@syncfusion/ej2-react-grids";

const ActiveUsersChartSection = ({data, isLoading}) => {
    const primaryXAxis = {
        valueType: 'Category',
        enableTrim: true,
        maximumLabelWidth: '100',
        labelIntersectAction: 'Rotate90',
        interval: 1,
    };
    const primaryYAxis = {
        minimum: 0,
        interval: 10,
    };

    const marker = {
        dataLabel:
            {
                visible: true,
                position: 'Top',
                font:
                    {
                        fontWeight: '600',
                        color: '#ffffff'
                    }
            }
    };

    return (
        <div className={cl.chartContainer}>
            {isLoading &&
                <div className={cl.loaderContainer}>
                    <div className='loader light'>

                    </div>
                </div>
            }
            <ChartComponent id='activity-analytics-chart'
                            primaryXAxis={primaryXAxis}
                            primaryYAxis={primaryYAxis}
                            palettes={["#7ac834"]}
                            height='100%'
                            tooltip={{enable: true}}>
                <Inject services={[ColumnSeries, Legend, Tooltip, DataLabel, Category, LineSeries]}/>
                <SeriesCollectionDirective>
                    <SeriesDirective type='Column'
                                     dataSource={data}
                                     columnWidth={0.7}
                                     pointColor={'lightblue'}
                                     xName='xValue'
                                     yName='yValue'
                                     marker={marker}/>
                </SeriesCollectionDirective>
            </ChartComponent>
        </div>
    );
}


export default ActiveUsersChartSection;