export const getPollTypeName = (t, type) => {
    switch (type) {
        case 0:
            return t("poll.poll-type.points");
        case 1:
            return t("poll.poll-type.string-answer");
        case 2:
            return t("poll.poll-type.option-answer");
        case 3:
            return t("poll.poll-type.opinion-scale");
        default:
            return '';
    }
};

export const getPollTypes = (t) => {
    return [
        {id: 0, text: t("poll.poll-type.points")},
        {id: 1, text: t("poll.poll-type.string-answer")},
        {id: 2, text: t("poll.poll-type.option-answer")},
        {id: 3, text: t("poll.poll-type.opinion-scale")},
    ];
};

export const getPointsQuantityTypes = () => {
    return [
        {id: 0, text: '5'},
        {id: 1, text: '10'}
    ];
}

export const validatePollQuestionList = (pollQuestionList, type = undefined) => {
    if (pollQuestionList === null || pollQuestionList === undefined ||
        !Array.isArray(pollQuestionList) || pollQuestionList.length <= 0) {
        if (type !== undefined && type === 1)
            return true;

        return false;
    }

    let isValid = true;

    if (type !== 0 && type !== 3) {
        for (let item in pollQuestionList) {
            isValid = validatePollQuestion(pollQuestionList[item]);

            if (!isValid) {
                return isValid;
            }
        }
    }

    return isValid;
}


export const validatePollQuestion = (pollQuestion) => {
    if (pollQuestion === null)
        return false;

    let isValid = true;

    if (pollQuestion.questionText === null ||
        pollQuestion.questionText === undefined ||
        pollQuestion.type === null ||
        pollQuestion.type === undefined)
        isValid = false;

    switch (pollQuestion.type) {
        //points
        case 0:
            if (pollQuestion.pointsQuantity === null ||
                pollQuestion.pointsQuantity === undefined)
                isValid = false;
            break;
        //simple string answer
        case 1:

            break;
        //option answer
        case 2:

            break;
        //opinion scale
        case 3:

            break;
        //answer list item
        case 4:

            break;
        default:

            break;
    }

    if (pollQuestion.type === 0 || pollQuestion.type === 3)
        return isValid;

    if (isValid &&
        pollQuestion.type !== 1 &&
        pollQuestion.pollQuestions !== null &&
        pollQuestion.pollQuestions !== undefined &&
        Array.isArray(pollQuestion.pollQuestions) &&
        pollQuestion.pollQuestions.length > 0) {
        isValid = validatePollQuestionList(pollQuestion.pollQuestions, pollQuestion.type);
    }


    return isValid;
}