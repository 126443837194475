import React from 'react';
import Dialog from "../../../Dialog/Dialog";
import TargetForm from "../TargetForm/TargetForm";

const TargetDialog = ({open, close, ...rest}) => {
    return (
        <Dialog width='600px'
                height='420px'
                open={open}
                onOverlayClick={close}>
            <TargetForm close={close} {...rest} />
        </Dialog>
    );
};

export default TargetDialog;