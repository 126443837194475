import React, {useContext, useEffect, useState} from 'react';
import GridTabItem from "../GridTabItem/GridTabItem";
import {v4 as uuidv4} from 'uuid';
import {GridTabContext} from "../GridTab";
import cl from './GridTabSection.module.css';
import {useSearchParams} from "react-router-dom";

export const GridTabSectionType = {
    Button: 'button',
    Tab: 'tab'
}

const GridTabSection = ({children, type, showRightSeparator, queryParameterName, setQueryParams = true}) => {
    const [items, setItems] = useState([]);
    const [activeTab, setActiveTab] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const {onClick} = useContext(GridTabContext);

    useEffect(() => {
        const tabs = [];

        React.Children.forEach(children, elem => {
            if (!React.isValidElement(elem))
                return

            tabs.push({
                id: elem.props.id,
                uuid: uuidv4(),
                text: elem.props.text,
                queryKey: elem.props.queryKey,
                isDefaultActive: elem.props.isDefaultActive,
                icon: elem.props.icon,
                color: elem.props.color
            });
        });

        if (tabs) {
            setItems(tabs);

            if (type === GridTabSectionType.Tab) {
                if (searchParams.get(queryParameterName) && tabs.find(e => e.queryKey === searchParams.get(queryParameterName))) {
                    setActiveTab(tabs.find(e => e.queryKey === searchParams.get(queryParameterName)).uuid);
                } else {
                    const activeItem = tabs.find(item => item.isDefaultActive);

                    if (activeItem) {
                        setActiveTab(activeItem.uuid);

                    } else {
                        setActiveTab(tabs[0].uuid);
                    }

                    if (activeItem.queryKey && queryParameterName && setQueryParams) {
                        setSearchParams(prev => {
                            let p = new URLSearchParams();

                            for (let item of prev) {
                                p.append(item[0], item[1]);
                            }

                            p.set(queryParameterName, activeItem.queryKey);

                            return p;
                        });
                    }
                }
            }
        }

    }, []);

    useEffect(() => {
        if (items && items.length > 0) {
            const tabs = [];

            React.Children.forEach(children, elem => {
                if (!React.isValidElement(elem))
                    return

                tabs.push({
                    id: elem.props.id,
                    uuid: uuidv4(),
                    text: elem.props.text,
                    queryKey: elem.props.queryKey,
                    isDefaultActive: elem.props.isDefaultActive,
                    icon: elem.props.icon,
                    color: elem.props.color
                });
            });

            if (tabs.length > 0) {
                const tabsToAdd = tabs.filter(item => !items.find(i => i.id === item.id));
                const tabsToDelete = items.filter(item => !tabs.find(tab => tab.id === item.id));

                if (tabsToAdd && tabsToAdd.length > 0) {
                    setItems(prev => {
                        return [...prev, ...tabsToAdd];
                    })
                }

                if (tabsToDelete && tabsToDelete.length > 0) {
                    setItems(prev => {
                        return [...prev.filter(item => !tabsToDelete.find(tab => tab.id === item.id))];
                    })
                }
            }
        }
    }, [children]);

    const onTabItemClick = (id) => {
        const item = items.find(item => item.uuid === id);
        if (item) {
            if (type === GridTabSectionType.Tab && activeTab !== id) {
                setActiveTab(id);

                if (queryParameterName && item.queryKey && setQueryParams) {
                    setSearchParams(prev => {
                        let p = new URLSearchParams();

                        for (let item of prev) {
                            p.append(item[0], item[1]);
                        }

                        p.set(queryParameterName, item.queryKey);

                        return p;
                    });
                }
            }

            onClick({
                id: item.id,
                queryKey: item.queryKey,
                queryParameterName
            });
        }
    }

    return (
        <div className={cl.section} data-role={type}>
            {items && items.map(item => {
                return (
                    <GridTabItem onClick={onTabItemClick}
                                 key={item.uuid}
                                 type={type}
                                 isDefaultActive={item.uuid === activeTab}
                                 text={item.text}
                                 id={item.id}
                                 color={item.color}
                                 uuid={item.uuid}
                                 icon={item.icon}>

                    </GridTabItem>
                )
            })}
            {showRightSeparator && <div className={cl.separator}></div>}
        </div>
    );
};

export default GridTabSection;