import React from 'react';
import {useTranslation} from "react-i18next";
import DropdownGridFilter from "../../../../ui/components/Grid/filters/DropdownGridFilter";

const PostCategoryMenuFilter = ({onFilterChange, ...rest}) => {
    const {t} = useTranslation();
    const postCategoryMenuFilterData = [
        {id: '0', text: t("postCategory.postCategoryMenu.0")},
        {id: '1', text: t("postCategory.postCategoryMenu.1")},
        {id: '2', text: t("postCategory.postCategoryMenu.2")},
        {id: '3', text: t("postCategory.postCategoryMenu.3")}
    ];

    return <DropdownGridFilter onChange={onFilterChange} data={postCategoryMenuFilterData} {...rest} />
};

export default PostCategoryMenuFilter;