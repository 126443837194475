import React, {useState} from 'react';
import {Query} from "@syncfusion/ej2-data";
import {useTranslation} from "react-i18next";
import GridTab from "../../../../../ui/components/Grid/GridTab/GridTab";
import GridTabSection, {
    GridTabSectionType
} from "../../../../../ui/components/Grid/GridTab/GridTabSection/GridTabSection";
import GridTabItem from "../../../../../ui/components/Grid/GridTab/GridTabItem/GridTabItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../../../ui/components/Loader/Loader";
import GridComponent from "../../../../../ui/components/Grid/GridComponent";
import {ColumnDirective, ColumnsDirective, Filter, Inject, Page, Sort} from "@syncfusion/ej2-react-grids";
import UserLogDateTemplate from "../../../../grid/UserLog/templates/UserLogDateTemplate";
import UserLogActivityCodeTemplate from "../../../../grid/UserLog/templates/UserLogActivityCodeTemplate";
import UserLogResultCodeTemplate from "../../../../grid/UserLog/templates/UserLogResultCodeTemplate";

const UserLogsGrid = ({userId, phone, surname}) => {
    const [query] = useState(new Query().addParams('userId', userId).addParams('phone', phone).addParams('surname', surname));
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const {t} = useTranslation();

    const onTabItemClick = ({id}) => {
        if (id) {
            if (id === 'refresh-btn') {
                setRefreshTrigger(prev => prev + 1);
            }
        }
    }

    return (
        <>
            <GridTab onClick={onTabItemClick}>
                <GridTabSection type={GridTabSectionType.Button}>
                    <GridTabItem id='refresh-btn'
                                 text={t("btn.refresh")}
                                 icon={<FontAwesomeIcon icon={faRefresh}/>}>

                    </GridTabItem>
                </GridTabSection>
            </GridTab>
            {
                !query
                    ? <Loader/>
                    : <GridComponent id='grid'
                                     container='.user-form-container'
                                     url='/api/userLog/getUserLogs'
                                     query={query}
                                     heightMinus={2}
                                     refresh={refreshTrigger}
                                     allowFiltering={false}>
                        <ColumnsDirective>
                            <ColumnDirective field='id'
                                             visible={false}
                                             textAlign="center"
                                             isPrimaryKey={true}
                                             type='number'/>
                            <ColumnDirective field='fullName'
                                             headerText={t("userLog.grid.name")}
                                             width='200'
                                             textAlign="center"/>
                            <ColumnDirective field='phone'
                                             headerText={t("userLog.grid.phone")}
                                             width='150'
                                             textAlign="center"/>
                            <ColumnDirective field='createdOn'
                                             headerText={t("userLog.grid.date")}
                                             width='150'
                                             textAlign="center"
                                             template={(props) => <div><UserLogDateTemplate {...props} /></div>}/>
                            <ColumnDirective field='activityCode'
                                             headerText={t("userLog.grid.activity-code")}
                                             width='300'
                                             textAlign="center"
                                             template={(props) => <div><UserLogActivityCodeTemplate {...props}/></div>}/>
                            <ColumnDirective field='resultCode'
                                             headerText={t("userLog.grid.result-code")}
                                             width='150'
                                             textAlign="center"
                                             template={(props) => <div><UserLogResultCodeTemplate {...props}/></div>}/>
                            <ColumnDirective field='resultNotes'
                                             headerText={t("userLog.grid.notes")}
                                             width='600'
                                             textAlign="center"/>
                        </ColumnsDirective>
                        <Inject services={[Page, Sort, Filter]}/>
                    </GridComponent>
            }
        </>
    );
};

export default UserLogsGrid;