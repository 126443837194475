import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {selectCurrentToken} from "../../../../../features/authentication/authenticationSelectors";
import {DataManager, Query, WebApiAdaptor} from "@syncfusion/ej2-data";
import DropdownRemoteGridFilter from "../../../../ui/components/Grid/filters/DropdownRemoteGridFilter";

const StreetFilter = ({onFilterChange, ...rest}) => {
    const token = useSelector(selectCurrentToken);
    const [query, setQuery] = useState(new Query().addParams('isBlocked', 'active'));

    const data = new DataManager({
        adaptor: new WebApiAdaptor(),
        url: `${process.env.REACT_APP_BE_DOMAIN}/api/street/getStreetsList`,
        headers: [
            {
                'Authorization': `bearer ${token}`
            }
        ]
    });

    return <DropdownRemoteGridFilter query={query} onChange={onFilterChange} {...rest} dataSource={data}/>
};

export default StreetFilter;