import React from 'react';
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";
import {useSelector} from "react-redux";
import {selectEntityFilterValue} from "../../../../../features/filter/filterSelectors";

const DropdownGridFilter = ({data, column, onChange, filterStoreEntityName, ...rest}) => {
    const value = useSelector(state => selectEntityFilterValue(state, {
        type: filterStoreEntityName,
        field: column.field
    }));

    return (
        <DropDownListComponent enabled={column.allowFiltering}
                               id={column.field}
                               popupHeight='250px'
                               dataSource={data}
                               value={value ? value.value : null}
                               change={(ev) => onChange(ev, column.field)}
                               fields={{text: 'text', value: 'id'}}
                               showClearButton={true}
                               {...rest}>

        </DropDownListComponent>
    );
};

export default DropdownGridFilter;
