import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useServerError} from "../../../../hooks/useServerError";
import apiAxios from "../../../../app/api/apiAxios";
import ResultCode from "../../../../data/enums/ResultCode";
import getServerErrorResourceName from "../../../../data/apiErrors/apiErrors";
import {toast} from "react-toastify";
import FormLayout from "../../../ui/layouts/FormLayout/FormLayout";
import Button, {ButtonType} from "../../../ui/components/Button/Button";
import HomeOwnerPollForm from "./HomeOwnerPollForm";

const initialState = {
    id: 0,
    createdOn: null,
    isBlocked: false,
    value: null,
    name: null,
}

const HomeOwnerPoll = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(initialState);
    const [serverError, setServerError] = useServerError(null);
    const {state} = useLocation();

    useEffect(() => {
        (async () => await load(id))();
    }, [id]);

    const load = async (id) => {
        if (id && id > 0) {
            setServerError(null);
            setPage(initialState);
            setIsLoading(true);

            const url = `${process.env.REACT_APP_BE_DOMAIN}/api/HomeOwner/getHomeOwnerPoll?id=${id}`;

            try {
                const response = await apiAxios.get(url);

                if (response) {
                    if (response.status === 200 && response.data) {
                        if (response.data.resultCode === ResultCode.Success) {
                            setPage(response.data);
                        } else {
                            setServerError(t(getServerErrorResourceName({
                                model: 'homeOwnerPoll',
                                resultCode: response.data.resultCode
                            })));
                        }
                    }
                }

                setIsLoading(false);
            } catch (e) {
                if (e && e.message) {
                    setServerError(`${t(getServerErrorResourceName({
                        model: 'homeOwnerPoll',
                        resultCode: ResultCode.ServerError
                    }))} Message: (${e.message})`);
                }
                setIsLoading(false);
            }
        } else {
            if (state && state.name) {
                setPage({
                    ...initialState,
                    name: state.name
                });
            }
        }
    };

    const save = async (values, actions) => {
        try {
            setServerError(null);
            setIsLoading(true);
            actions.setSubmitting(true);

            const response = await apiAxios.post(`${process.env.REACT_APP_BE_DOMAIN}/api/HomeOwner/savePoll`, {
                id: page.id,
                quizeUrl: values.quizeUrl,
                name: values.name,
            });

            if (response) {
                if (response.status === 200 && response.data) {
                    if (response.data.resultCode === ResultCode.Success) {
                        toast.success(t("page.form.saved-label"));

                        if (response.data.description) {
                            await load(+response.data.description);
                        }
                    } else {
                        setServerError(t(getServerErrorResourceName({
                            model: 'homeOwnerPoll',
                            resultCode: response.data.resultCode,
                            description: response.data.description
                        })));
                    }
                }
            }

            setIsLoading(false);
            actions.setSubmitting(false);
        } catch (err) {
            if (err && err.message) {
                setServerError(`${t(getServerErrorResourceName({
                    model: 'homeOwnerPoll',
                    resultCode: ResultCode.ServerError
                }))} Message: (${err.message})`);
            }
            setIsLoading(false);
            actions.setSubmitting(false);
        }
    };


    return (
        <FormLayout header={<div className='default-form-layout-header'>{t("page.form.form-label")}</div>}
                    footer={(
                        <div className='default-form-layout-footer'>
                            <Button buttonType={ButtonType.Back}
                                    onClick={() => navigate(-1)}
                                    disabled={isLoading}>
                                {t("btn.back")}
                            </Button>
                            <div>


                                <Button buttonType={ButtonType.Default}
                                        form='page-form'
                                        type='submit'
                                        disabled={isLoading}>
                                    {t("btn.save")}
                                </Button>
                            </div>
                        </div>
                    )}
                    footerPosition='top'
                    isLoading={isLoading}>
            <div className='default-form-layout-container'>
                {serverError &&
                    <div className='server-error-container'>
                        <div>
                            {serverError}
                        </div>
                    </div>
                }
                <HomeOwnerPollForm {...page} onSubmit={save}/>
            </div>
        </FormLayout>
    );
};

export default HomeOwnerPoll;
