import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {decode, encode} from "js-base64"
import ResultCode from "../../data/enums/ResultCode";

const initialState = {
    user: null,
    token: null,
    refreshToken: null,
    expiry: null,
}

const getLocalStorageInitialState = () => {
    if (!localStorage.getItem('_d')) {
        return initialState;
    }

    try {
        const parsedData = JSON.parse(decode(localStorage.getItem('_d')));
        if (!parsedData || (typeof parsedData !== 'object')) {
            return initialState;
        }

        return {
            user: parsedData.user,
            token: parsedData.token,
            refreshToken: parsedData.refreshToken,
            expiry: parsedData.expiry
        }
    } catch (error) {
        return initialState;
    }
}

export const refreshToken = createAsyncThunk(
    `${process.env.REACT_APP_BE_DOMAIN}/api/authentication/refreshToken`,
    async function (_, {dispatch, getState}) {
        const state = getState();

        const refreshResponse = await fetch(`${process.env.REACT_APP_BE_DOMAIN}/api/authentication/refreshToken`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: state.authentication.token,
                    refreshToken: state.authentication.refreshToken
                })
            });

        const data = await refreshResponse.json();

        if (refreshResponse.ok && data?.resultCode === ResultCode.Success && data.token && data.refreshToken) {

            const {token, refreshToken, validTo} = data;

            localStorage.setItem('_d', encode(JSON.stringify({
                user: state.authentication.user,
                refreshToken: refreshToken,
                token: token,
                expiry: validTo,
            })));

            dispatch(setCredentials({
                user: state.authentication.user,
                token,
                refreshToken,
                expiry: validTo
            }));
        } else {
            localStorage.removeItem('_d');

            dispatch(logOut());
        }
    }
);

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: getLocalStorageInitialState(),
    reducers: {
        setCredentials: (state, action) => {
            const {user, token, refreshToken, expiry} = action.payload;

            state.token = token;
            state.user = user;
            state.refreshToken = refreshToken;
            state.expiry = expiry;
        },
        logOut: (state, _) => {
            state.user = null;
            state.token = null;
            state.refreshToken = null;
            state.expiry = null
        },
        setUser: (state, action) => {
            state.user = action.payload.user;
        }
    }
});

export const {setCredentials, logOut, setUser} = authenticationSlice.actions;

export default authenticationSlice.reducer;