import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSelectors";
import {useNavigate} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {Query} from "@syncfusion/ej2-data";
import PostCategorySelector from "./selectors/PostCategorySelector";
import GridTabItem from "../../../ui/components/Grid/GridTab/GridTabItem/GridTabItem";
import GridTabSection, {GridTabSectionType} from "../../../ui/components/Grid/GridTab/GridTabSection/GridTabSection";
import GridTab from "../../../ui/components/Grid/GridTab/GridTab";
import PermissionType from "../../../../data/enums/PermissionType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faRefresh} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../ui/components/Loader/Loader";
import {ColumnDirective, ColumnsDirective, Filter, Inject, Sort, Page as Pager} from "@syncfusion/ej2-react-grids";
import PostCheckboxFilter from "./filters/PostCheckboxFilter";
import DateRangeGridFilter from "../../../ui/components/Grid/filters/DateRangeGridFilter";
import PostDateTemplate from "./templates/PostDateTemplate";
import PostSourceFilter from "./filters/PostSourceFilter";
import GridComponent from "../../../ui/components/Grid/GridComponent";
import PostTargetSelector from "./Components/PostTargetSelector/PostTargetSelector";
import {selectEntityFilterValue} from "../../../../features/filter/filterSelectors";

const Post = () => {
    const {t} = useTranslation();
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();

    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [tab, setTab] = useState(null);
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [filterTrigger, setFilterTrigger] = useState(null);
    const [query, setQuery] = useState(null);

    const valueSubSection = useSelector(state => selectEntityFilterValue(state, {
        type: 'post',
        field: 'grid-sub-section'
    }));

    const valueSection = useSelector(state => selectEntityFilterValue(state, {
        type: 'post',
        field: 'grid-section'
    }));

    const valueHomeOwner = useSelector(state => selectEntityFilterValue(state, {
        type: 'post',
        field: 'grid-home-owner'
    }));


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        setTab(urlParams.get('tab') ? urlParams.get('tab') : '0');
    }, []);

    useEffect(() => {
        setQuery((prev) => {
            if (!tab || !selectedCategoryId) {
                return null;
            }

            const q = new Query();

            if (prev && prev.params && prev.params.length > 0) {
                const params = prev.params.filter(item =>
                    item.key !== 'category' &&
                    item.key !== 'tab' &&
                    item.key !== 'homeOwner' &&
                    item.key !== 'section' &&
                    item.key !== 'subSection');
                for (const item of params) {
                    q.addParams(item.key, item.value);
                }
            }

            q.addParams('tab', tab.toString()).addParams('category', selectedCategoryId.toString());

            if (valueSection !== undefined && valueSection !== null &&
                valueSection.value !== undefined && valueSection.value !== null) {
                q.addParams('section', valueSection.value);
            }

            if (valueHomeOwner !== undefined && valueHomeOwner !== null &&
                valueHomeOwner.value !== undefined && valueHomeOwner.value !== null) {
                q.addParams('homeOwner', valueHomeOwner.value);
            }

            if (valueSubSection !== undefined && valueSubSection !== null &&
                valueSubSection.value !== undefined && valueSubSection.value !== null) {
                q.addParams('subSection', valueSubSection.value);
            }

            return q;
        });
    }, [tab, selectedCategoryId, valueSection, valueSubSection, valueHomeOwner]);

    const onTabItemClick = ({id, queryParameterName, queryKey}) => {
        if (queryParameterName && queryKey) {
            setTab(queryKey);
        } else if (id) {
            if (id === 'refresh-btn') {
                setRefreshTrigger(prev => prev + 1);
            }

            if (id === 'add-btn') {
                navigate('/news/posts/post/', {
                    state: {category: selectedCategoryId}
                });
            }
        }
    }

    const onPostCategoryChange = useCallback(id => {
        setSelectedCategoryId(id);
    }, [])

    const onRowDoubleClick = ev => {
        if (ev && ev.rowData) {
            navigate(`/news/posts/post/${ev.rowData.id}`);
        }
    }

    return (
        <div className='list-container'>
            <PostCategorySelector onChange={onPostCategoryChange}/>
            <GridTab onClick={onTabItemClick}>
                <GridTabSection type={GridTabSectionType.Tab}
                                showRightSeparator={true}
                                queryParameterName='tab'>
                    <GridTabItem id='available-tab'
                                 text={t("post.grid.available-tab")}
                                 queryKey='0'
                                 isDefaultActive={true}>

                    </GridTabItem>
                    <GridTabItem id='important-tab'
                                 text={t("post.grid.important-tab")}
                                 queryKey='5'>

                    </GridTabItem>
                    <GridTabItem id='all-tab'
                                 text={t("shared.grid.all-tab")}
                                 queryKey='1'>

                    </GridTabItem>
                    <GridTabItem id='not-published-tab'
                                 text={t("post.grid.not-published-tab")}
                                 queryKey='2'>

                    </GridTabItem>
                    <GridTabItem id='delay-publish-tab'
                                 text={t("post.grid.delayed-publish-tab")}
                                 queryKey='3'>

                    </GridTabItem>
                    <GridTabItem id='blocked-tab'
                                 text={t("shared.grid.blocked-tab")}
                                 queryKey='4'>

                    </GridTabItem>
                </GridTabSection>
                <GridTabSection type={GridTabSectionType.Button}>
                    {user.permission.news === PermissionType.Editing
                        ? (
                            <GridTabItem id='add-btn'
                                         text={t("btn.add")}
                                         icon={<FontAwesomeIcon icon={faAdd}/>}>

                            </GridTabItem>
                        )
                        : null
                    }
                    <GridTabItem id='refresh-btn'
                                 text={t("btn.refresh")}
                                 icon={<FontAwesomeIcon icon={faRefresh}/>}>

                    </GridTabItem>
                </GridTabSection>
            </GridTab>
            <PostTargetSelector/>
            {!query
                ? <Loader/>
                : <GridComponent id='grid'
                                 container='.list-container'
                                 url='/api/post/getPosts'
                                 query={query}
                                 refresh={refreshTrigger}
                                 filterStoreEntityName={'post'}
                                 onRowClick={onRowDoubleClick}
                                 filter={filterTrigger}
                                 sortSettings={{columns: [{field: 'createdOn', direction: 'Descending'}]}}>
                    <ColumnsDirective>
                        <ColumnDirective field='id'
                                         visible={false}
                                         textAlign="center"
                                         isPrimaryKey={true}
                                         type='number'/>
                        <ColumnDirective field='createdOn'
                                         headerText={t("post.grid.created-on")}
                                         width='150'
                                         textAlign="center"
                                         filterTemplate={(props) => <DateRangeGridFilter
                                             onChange={(ev, fieldName) => setFilterTrigger({
                                                 ev,
                                                 fieldName
                                             })} filterStoreEntityName={'post'} {...props}/>}
                                         template={(props) => <PostDateTemplate {...props}/>}/>
                        <ColumnDirective field='publicationDate'
                                         headerText={t("post.grid.publish-date")}
                                         width='150'
                                         textAlign='center'
                                         filterTemplate={(props) => <DateRangeGridFilter
                                             onChange={(ev, fieldName) => setFilterTrigger({
                                                 ev,
                                                 fieldName
                                             })} filterStoreEntityName={'post'} {...props}/>}
                                         template={(props) => <PostDateTemplate {...props}/>}/>
                        <ColumnDirective field='title'
                                         headerText={t("post.grid.title")}
                                         width='350'
                                         textAlign='center'/>
                        <ColumnDirective field='postSource'
                                         headerText={t("post.grid.post-source")}
                                         width='200'
                                         textAlign='center'
                                         filterTemplate={(props) => <PostSourceFilter
                                             onChange={(ev, fieldName) => setFilterTrigger({
                                                 ev,
                                                 fieldName
                                             })} filterStoreEntityName={'post'} {...props}/>}/>
                        <ColumnDirective field='extUrl'
                                         headerText={t("post.grid.link")}
                                         width='200'
                                         textAlign='center'/>
                        <ColumnDirective field='targetLabel'
                                         headerText={t("post.grid.filter")}
                                         width='200'
                                         textAlign='center'
                                         allowSorting={false}
                                         allowFiltering={false}/>
                        <ColumnDirective field='isDraft'
                                         headerText={t("post.grid.draft")}
                                         width='200'
                                         displayAsCheckBox={true}
                                         textAlign="center"
                                         filterTemplate={(props) => <PostCheckboxFilter
                                             onChange={(ev, fieldName) => setFilterTrigger({
                                                 ev,
                                                 fieldName
                                             })} filterStoreEntityName={'post'} {...props}/>}/>
                        <ColumnDirective field='isBlocked'
                                         headerText={t("post.grid.blocked")}
                                         width='200'
                                         displayAsCheckBox={true}
                                         textAlign="center"
                                         filterTemplate={(props) => <PostCheckboxFilter
                                             onChange={(ev, fieldName) => setFilterTrigger({
                                                 ev,
                                                 fieldName
                                             })} filterStoreEntityName={'post'} {...props}/>}/>
                        <ColumnDirective field='isImportant'
                                         headerText={t("post.grid.important")}
                                         width='200'
                                         displayAsCheckBox={true}
                                         textAlign="center"
                                         filterTemplate={(props) => <PostCheckboxFilter
                                             onChange={(ev, fieldName) => setFilterTrigger({
                                                 ev,
                                                 fieldName
                                             })} filterStoreEntityName={'post'} {...props}/>}/>
                        <ColumnDirective field='position'
                                         headerText={t("post.grid.position")}
                                         width='200'
                                         textAlign='center'/>
                        <ColumnDirective field='endDate'
                                         headerText={t("post.grid.end-date")}
                                         width='150'
                                         textAlign='center'
                                         filterTemplate={(props) => <DateRangeGridFilter
                                             onChange={(ev, fieldName) => setFilterTrigger({
                                                 ev,
                                                 fieldName
                                             })} filterStoreEntityName={'post'} {...props}/>}
                                         template={(props) => <PostDateTemplate {...props}/>}/>
                    </ColumnsDirective>
                    <Inject services={[Pager, Sort, Filter]}/>
                </GridComponent>
            }
        </div>
    );
}

export default Post;
/*import React, {useCallback, useEffect, useState} from "react";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSelectors";
import {useSelector} from "react-redux";
import {Query} from "@syncfusion/ej2-data";
import {useTranslation} from "react-i18next";
import GridTab from "../../../ui/components/Grid/GridTab/GridTab";
import GridTabSection, {GridTabSectionType} from
"../../../ui/components/Grid/GridTab/GridTabSection/GridTabSection";
import GridTabItem from "../../../ui/components/Grid/GridTab/GridTabItem/GridTabItem";
import PermissionType from "../../../../data/enums/PermissionType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faRefresh} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../ui/components/Loader/Loader";
import GridComponent from "../../../ui/components/Grid/GridComponent";
import {ColumnDirective, ColumnsDirective, Filter, Inject, Sort} from "@syncfusion/ej2-react-grids";
import DateRangeGridFilter from "../../../ui/components/Grid/filters/DateRangeGridFilter";
import PostDateTemplate from "./templates/PostDateTemplate";
import {Page as PageService} from "@syncfusion/ej2-grids/src/grid/actions/page";
import PostCategoryFilter from "./filters/PostCategoryFilter";
import PostCheckboxFilter from "./filters/PostCheckboxFilter";
import PostSourceFilter from "./filters/PostSourceFilter";
import {useNavigate} from "react-router-dom";

const Post = () => {
const [selectedCategoryId, setSelectedCategoryId] = useState(null);
const [query, setQuery] = useState(null);
const [tab, setTab] = useState('0');
const [refreshTrigger, setRefreshTrigger] = useState(0);
const [filterTrigger, setFilterTrigger] = useState(null);
const {t} = useTranslation();
const user = useSelector(selectCurrentUser);
const navigate = useNavigate();


const onTabItemClick = ({id, queryParameterName, queryKey}) => {
if (queryParameterName && queryKey) {
setTab(queryKey);
} else if (id) {
if (id === 'refresh-btn') {
setRefreshTrigger(prev => prev + 1);
}

if (id === 'add-btn') {
navigate('/news/posts/post/', {
state: {category: selectedCategoryId}
});
}
}
}

useEffect(() => {
setQuery(prev => {
if (!prev)
return prev;

let q = new Query();

if (prev && prev.params && prev.params.length > 0) {
const params = prev.params.filter(item => item.key !== 'tab');
for (const item of params) {
q.addParams(item.key, item.value);
}
}

q.addParams('tab', tab);

return q;
});
}, [tab]);

useEffect(() => {
setQuery(prev => {
if (!selectedCategoryId)
return null;

let q = new Query();

if (prev && prev.params && prev.params.length > 0) {
const params = prev.params;
for (const item of params) {
q.addParams(item.key, item.value);
}
}

if (q.params.find(item => item.key === 'category'))
q.params = q.params.filter(item => item.key !== 'category');
q.addParams('category', selectedCategoryId.toString());

if (q.params.find(item => item.key === 'tab'))
q.params = q.params.filter(item => item.key !== 'tab');

const urlParams = new URLSearchParams(window.location.search);
if (urlParams.get('tab')) {
q.addParams('tab', urlParams.get('tab'));
} else {
q.addParams('tab', tab);
}

return q;
});
}, [selectedCategoryId])

const onPostCategoryChange = useCallback((id) => {
setSelectedCategoryId(id);
}, []);

const onRowDoubleClick = ev => {
if (ev && ev.rowData) {
navigate(`/news/posts/post/${ev.rowData.id}`);
}
}

return (
<div className='list-container'>
<PostCategoryFilter onCategoryChange={onPostCategoryChange}/>
<GridTab onClick={onTabItemClick}>
<GridTabSection type={GridTabSectionType.Tab}
showRightSeparator={true}
queryParameterName='tab'>
<GridTabItem id='available-tab'
text={t("post.grid.available-tab")}
queryKey='0'
isDefaultActive={true}>

</GridTabItem>
<GridTabItem id='all-tab'
text={t("shared.grid.all-tab")}
queryKey='1'>

</GridTabItem>
<GridTabItem id='not-published-tab'
text={t("post.grid.not-published-tab")}
queryKey='2'>

</GridTabItem>
<GridTabItem id='delay-publish-tab'
text={t("post.grid.delayed-publish-tab")}
queryKey='3'>

</GridTabItem>
<GridTabItem id='blocked-tab'
text={t("shared.grid.blocked-tab")}
queryKey='4'>

</GridTabItem>
</GridTabSection>
<GridTabSection type={GridTabSectionType.Button}>
{user.permission.news === PermissionType.Editing
? (
<GridTabItem id='add-btn'
text={t("btn.add")}
icon={<FontAwesomeIcon icon={faAdd}/>}>

</GridTabItem>
)
: null
}
<GridTabItem id='refresh-btn'
text={t("btn.refresh")}
icon={<FontAwesomeIcon icon={faRefresh}/>}>

</GridTabItem>
</GridTabSection>
</GridTab>
{!query
? <Loader/>
: <GridComponent id='grid'
container='.list-container'
url='/api/post/getPosts'
query={query}
refresh={refreshTrigger}
onRowClick={onRowDoubleClick}
filter={filterTrigger}
sortSettings={{columns: [{field: 'createdOn', direction: 'Descending'}]}}>
<ColumnsDirective>
<ColumnDirective field='id'
visible={false}
textAlign="center"
isPrimaryKey={true}
type='number'/>
<ColumnDirective field='createdOn'
headerText={t("post.grid.created-on")}
width='150'
textAlign="center"
filterTemplate={(props) => <DateRangeGridFilter
onChange={(ev, fieldName) => setFilterTrigger({
ev,
fieldName
})} {...props}/>}
template={(props) => <PostDateTemplate {...props}/>}/>
<ColumnDirective field='publicationDate'
headerText={t("post.grid.publish-date")}
width='150'
textAlign='center'
filterTemplate={(props) => <DateRangeGridFilter
onChange={(ev, fieldName) => setFilterTrigger({
ev,
fieldName
})} {...props}/>}
template={(props) => <PostDateTemplate {...props}/>}/>
<ColumnDirective field='title'
headerText={t("post.grid.title")}
width='350'
textAlign='center'/>
<ColumnDirective field='postSource'
headerText={t("post.grid.post-source")}
width='200'
textAlign='center'
filterTemplate={(props) => <PostSourceFilter
onChange={(ev, fieldName) => setFilterTrigger({
ev,
fieldName
})} {...props}/>}/>
<ColumnDirective field='extUrl'
headerText={t("post.grid.link")}
width='200'
textAlign='center'/>
<ColumnDirective field='targetLabel'
headerText={t("post.grid.filter")}
width='200'
textAlign='center'
allowSorting={false}
allowFiltering={false}/>
<ColumnDirective field='isDraft'
headerText={t("post.grid.draft")}
width='200'
displayAsCheckBox={true}
textAlign="center"
filterTemplate={(props) => <PostCheckboxFilter
onChange={(ev, fieldName) => setFilterTrigger({
ev,
fieldName
})} {...props}/>}/>
<ColumnDirective field='isBlocked'
headerText={t("post.grid.blocked")}
width='200'
displayAsCheckBox={true}
textAlign="center"
filterTemplate={(props) => <PostCheckboxFilter
onChange={(ev, fieldName) => setFilterTrigger({
ev,
fieldName
})} {...props}/>}/>
</ColumnsDirective>
<Inject services={[PageService, Sort, Filter]}/>
</GridComponent>
}
</div>
);
}

export default Post;*/
