import React from 'react';
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSelectors";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {ColumnDirective, ColumnsDirective, GridComponent} from "@syncfusion/ej2-react-grids";
import PermissionType from "../../../../../../data/enums/PermissionType";

const RoleTemplate = (props) => {
    const {t} = useTranslation();

    return (
        <div>
            {t(`user.form.account.user-role.${props.role}`)}
        </div>
    );
}

const DeleteTemplate = ({item, onDelete}) => {
    const {t} = useTranslation();

    return (
        <span title={t("btn.delete")} style={{color: 'red', cursor: 'pointer', fontSize: '15px'}}
              onClick={() => onDelete(item)}>
            <FontAwesomeIcon icon={faTrash}/>
        </span>
    );
}

const AccountFormUsersGrid = ({users, onUserDelete}) => {
    const user = useSelector(selectCurrentUser);
    const {t} = useTranslation();

    return (
        <GridComponent dataSource={users}
                       id='account-user-grid'
                       height='300px'
                       locale='ru-RU'
                       allowSelection={false}
                       width='100%'>
            <ColumnsDirective>
                <ColumnDirective field='id'
                                 visible={false}
                                 textAlign="center"
                                 isPrimaryKey={true}
                                 type='number'/>
                <ColumnDirective field='userFullName'
                                 headerText={t("user.form.name")}
                                 width='40%'
                                 textAlign="center"/>
                <ColumnDirective field='userPhone'
                                 headerText={t("user.form.phone")}
                                 width='40%'
                                 textAlign="center"/>
                <ColumnDirective field='userEmail'
                                 headerText={t("user.form.email")}
                                 width='40%'
                                 textAlign="center"/>
                <ColumnDirective field='role'
                                 headerText={t("user.form.account.role")}
                                 width='40%'
                                 template={props => <RoleTemplate {...props}/>}
                                 textAlign="center"/>
                {user !== null &&
                    user.permission !== null &&
                    user.permission.userLog === PermissionType.Editing &&
                    <ColumnDirective width='20%'
                                     template={props => <DeleteTemplate item={props}
                                                                        onDelete={onUserDelete}/>}
                                     textAlign="center"/>
                }
            </ColumnsDirective>
        </GridComponent>
    );
};

export default AccountFormUsersGrid;