import React from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import Button, {ButtonType} from "../../../../../ui/components/Button/Button";
import FormLayout from "../../../../../ui/layouts/FormLayout/FormLayout";
import {DateRangePickerComponent} from "@syncfusion/ej2-react-calendars";
import cl from './UsersExportForm.module.css';
import moment from "moment/moment";
import apiAxios from "../../../../../../app/api/apiAxios";
import {useDownloadFile} from "../../../../../../hooks/useDownloadFile";
import {toast} from "react-toastify";

const UsersExportForm = ({close}) => {
    const {t} = useTranslation();

    const createDownloadFileRequest = () => {
        const date = {
            sDate: `${moment(form.values.startDate).format("DD.MM.YYYY")} 00:00`,
            eDate: `${moment(form.values.endDate).format("DD.MM.YYYY")} 23:59`,
        };

        return apiAxios.get(`${process.env.REACT_APP_BE_DOMAIN}/api/user/getUsersExcelDocument?sDate=${date.sDate}&eDate=${date.eDate}`, {
            responseType: 'blob'
        });
    };

    const {download} = useDownloadFile({
        request: createDownloadFileRequest,
        actionFailure: (ev) => {
            if (ev && ev.response) {
                if (ev.response.status === 500) {
                    toast.error(t("errors.download-file-server-error"));
                } else if (ev.response.status === 404) {
                    toast.error(t("analytics.errors.download-file-error-not-found"));
                }
            }
        },
        getFileName: () => {
            const dates = {
                sDate: `${moment(form.values.startDate).format("DD.MM.YYYY")}`,
                eDate: `${moment(form.values.endDate).format("DD.MM.YYYY")}`
            };
            return `Пользователи[${dates.sDate} - ${dates.eDate}].xlsx`
        }
    });

    const form = useFormik({
        initialValues: {
            startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
            endDate: new Date(),
        },
        validationSchema: Yup.object({
            startDate: Yup.date()
                .required(t("errors.required-error").replace('{0}', t("analytics.grid.period"))),
            endDate: Yup.date()
                .required(t("errors.required-error").replace('{0}', t("analytics.grid.period"))),
        }),
        onSubmit: async (_, actions) => {
            actions.setSubmitting(true);

            download()
                .then();
        }
    });

    const onIntervalChange = (ev) => {
        if (ev && ev.startDate && ev.endDate) {
            form.setFieldValue('startDate', ev.startDate);

            form.setFieldValue('endDate', ev.endDate);
        }
    }

    return (
        <FormLayout
            isLoading={form.isSubmitting}
            header={(
                <div className='header-form-layout'>
                    {t("btn.export")}
                </div>
            )}
            footer={
                <div className='footer-form-layout'>
                    <Button buttonType={ButtonType.Warning}
                            width='100%'
                            onClick={() => close()}
                            disabled={form.isSubmitting}>
                        {t("btn.cancel")}
                    </Button>

                    <Button buttonType={ButtonType.Default}
                            width='100%'
                            form='export-form'
                            type='submit'
                            disabled={form.isSubmitting}>
                        {t("btn.export")}
                    </Button>
                </div>
            }>
            <form id='export-form'
                  className={cl.form}
                  onSubmit={form.handleSubmit}>
                <div style={{width: '100%'}} className='syncfusion-control-input-container'>
                    <label className='label required'>
                        {t("analytics.grid.period")}
                    </label>

                    <DateRangePickerComponent id='chart-date-range-picker'
                                              width='100%'
                                              change={onIntervalChange}
                                              startDate={form.values.startDate}
                                              endDate={form.values.endDate}
                                              showClearButton={false}
                                              format='dd.MM.yyyy'
                                              enabled={!form.isSubmitting}/>

                    {form.touched.startDate && form.errors.startDate ?
                        <div className='field-error'>{form.errors.startDate}</div> : null}
                    {form.touched.endDate && form.errors.endDate ?
                        <div className='field-error'>{form.errors.endDate}</div> : null}
                </div>
            </form>
        </FormLayout>
    );
};

export default UsersExportForm;