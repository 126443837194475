import React, {createContext} from 'react';
import cl from './GridTab.module.css';

export const GridTabContext = createContext(null);

const GridTab = ({children, onClick}) => {
    return (
        <GridTabContext.Provider value={{onClick}}>
            <div className={cl.tabsContainer}>
                {children}
            </div>
        </GridTabContext.Provider>
    );
};

export default GridTab;