import React, {useCallback} from 'react';
import {useTranslation} from "react-i18next";
import Input from "../../../../Input/Input";
import {CheckBoxSelection, Inject, MultiSelectComponent} from "@syncfusion/ej2-react-dropdowns";
import {Query} from "@syncfusion/ej2-data";

const SubSectionSection = ({isLoading, parking, value, data, onSubSectionChange, onParkingChange}) => {
    const {t} = useTranslation();


    const onChange = (ev) => {
        if (onSubSectionChange) {
            let values =[];

            ev.value.forEach (evKey  => {

                values.push({id: evKey, label: data.find(e => e.id + "" === evKey + "").text})
            })
            onSubSectionChange(values);
        }
    };
    const  fields = { text: 'text', value: 'id' };


    function onFiltering(args) {
        let query = new Query();
        // frame the query based on search string with filter type.
        query = (args.text !== "") ? query.where("text", "contains", args.text, true) : query;
        // pass the filter data source, filter query to updateData method.
        args.updateData(data, query);
    }
    return (
        <>
        <div className='input-container syncfusion-control-input-container target-form-container'>
            <label className='label required'>
                {t("locationTarget.sub-section")}
            </label>
            <MultiSelectComponent filtering={onFiltering} allowFiltering={true}
                                  popupHeight='250px'
                                  change={onChange}
                                  enabled={!isLoading}
                                  placeholder={t("locationTarget.undefined")}
                                  fields={fields}
                                  dataSource={data} mode="CheckBox"
                //  value={value}
                                  showClearButton={true}> <Inject services={[CheckBoxSelection]}/></MultiSelectComponent>

        </div>
    <div className='target-form-container'>

        <Input name={'subsectionParking'} type={"checkbox"} checked={parking} disabled={isLoading}  onChange={onParkingChange} />
        <label style={{marginLeft:'5px',verticalAlign: 'top'}} className='label'>
            {t("locationTarget.parking")}
        </label>

    </div>
        </>
    );
};

export default SubSectionSection;
