import React, {useEffect, useState} from 'react';
import {UploaderComponent} from "@syncfusion/ej2-react-inputs";
import {detach, L10n, select} from "@syncfusion/ej2-base";
import {useTranslation} from "react-i18next";
import cl from './ImageUploader.module.css';
import Button, {ButtonType} from "../Button/Button";
import CropDialog from "./components/CropDialog/CropDialog";
import {toast} from "react-toastify";
import {v4 as uuidv4} from 'uuid';

L10n.load({
    'en-EN': {
        "uploader": {
            "Browse": "Select image",
            "Clear": "Clear",
            "Upload": "Upload",
            "cancel": "Cancel",
            "delete": "Delete image",
            "dropFilesHint": "Or drop image here",
            "inProgress": "Progress",
            "invalidFileType": "Invalid file type",
            "invalidMaxFileSize": "The file size exceeds the allowed size.",
            "invalidMinFileSize": "The file size is too small.",
            "readyToUploadMessage": "Ready to download",
            "remove": "Remove",
            "removedFailedMessage": "The file could not be deleted",
            "removedSuccessMessage": "File deleted successfully",
            "uploadFailedMessage": "Unable to upload file",
            "uploadSuccessMessage": "File uploaded successfully",
        }
    },
    'ru-RU': {
        "uploader": {
            "Browse": "Выбрать",
            "Clear": "Очистить",
            "Upload": "Загрузить",
            "cancel": "Отменить",
            "delete": "Удалить изображение",
            "dropFilesHint": "или перетащите файл в текущую зону.",
            "inProgress": "Прогресс",
            "invalidFileType": "Неверный тип файла",
            "invalidMaxFileSize": "Размер файла превышает допустимый размер.",
            "invalidMinFileSize": "Размер файла слишком мал.",
            "readyToUploadMessage": "Готово к скачиванию",
            "remove": "Удалить",
            "removedFailedMessage": "Файл не может быть удален",
            "removedSuccessMessage": "Файл успешно удален",
            "uploadFailedMessage": "Невозможно загрузить файл",
            "uploadSuccessMessage": "Файл загружен успешно",
        }
    }
});

const apiPath = {
    saveUrl: `${process.env.REACT_APP_BE_DOMAIN}/api/file/upload`,
    removeUrl: `${process.env.REACT_APP_BE_DOMAIN}/api/file/remove`,
};

const ImageUploader = ({image, name, size, change, disabled, cropperSettings, title, imagePreview = true}) => {
    const [id] = useState(uuidv4());
    const [uploadedImage, setUploadedImage] = useState(null);
    const [currentImage, setCurrentImage] = useState(null);
    const [bytes, setBytes] = useState(null);
    const [isDialogShow, setIsDialogShow] = useState(false);
    const {t, i18n} = useTranslation();
    let uploaderRef, dropAreaRef;

    useEffect(() => {
        if (image && name && size) {
            setUploadedImage([{
                name: name,
                size: size,
                type: name.slice(name.lastIndexOf('.'), name.length)
            }]);

            setCurrentImage({
                name: name,
                size: size,
                type: name.slice(name.lastIndexOf('.'), name.length)
            });
        }
    }, [image])

    function onCreated() {
        uploaderRef.dropArea = dropAreaRef;
        uploaderRef.dataBind();
    }

    const dropAreaClick = (args) => {
        if (!disabled) {
            const target = args.target;
            if (target.classList.contains('e-file-delete-btn')) {
                for (const i of uploaderRef.getFilesData()) {
                    if (target.closest('li').getAttribute('data-file-name') === i.name) {
                        uploaderRef.remove(uploaderRef.getFilesData()[uploaderRef.getFilesData().indexOf(i)]);
                    }
                }
            } else if (target.classList.contains('e-file-remove-btn')) {
                detach(target.closest('li'));
            }
        }
    }

    const browseClick = (args) => {
        if (!disabled) {
            let container = document.getElementById(id);
            if (container !== null && container !== undefined) {
                const wrapperEle = select('.e-file-select-wrap button', container);
                wrapperEle.click();
                args.preventDefault();
            }
        }
    }

    const onSelected = (args) => {
        let reader = new FileReader();

        reader.addEventListener('load', () => {
            setBytes(reader.result.slice(reader.result.indexOf(',') + 1));

            setIsDialogShow(prev => !prev);
        }, false);

        if (args.filesData && args.filesData.length > 0) {
            reader.readAsDataURL(args.filesData[0].rawFile);

            setCurrentImage({
                name: args.filesData[0].name,
                size: args.filesData[0].size,
                type: args.filesData[0].type
            });
        }

        if (uploaderRef) {
            uploaderRef.clearAll();
        }

        args.cancel = true;
    }

    const onImageCropped = bytes => {
        if (change) {
            setIsDialogShow(prev => !prev);

            setBytes(bytes.slice(bytes.indexOf(',') + 1));

            fetch(bytes)
                .then(data => data.blob())
                .then(res => {
                    if (res.size > 1048576) {
                        toast.error('File size more than 1048576');

                        change({
                            image: null,
                            name: null,
                            size: null
                        });
                    } else {
                        change({
                            image: (bytes.slice(bytes.indexOf(',') + 1)),
                            name: currentImage.name,
                            size: res.size
                        });
                    }
                });
        }
    }

    const onImageCrop = () => {
        if (image) {
            setBytes(image);

            setIsDialogShow(prev => !prev);
        }
    }

    const onRemove = () => {
        if (change) {
            change({
                image: null,
                name: null,
                size: null
            });
        }
    }

    return (
        <div className='image-uploader' id={id}>
            <div className='uploader-section'>
                <div id='dropArea'
                     onClick={dropAreaClick}
                     className={cl.uploaderSelectAreaContainer}
                     ref={dropElem => dropAreaRef = dropElem}>
                    <div className={cl.uploaderHeader}>
                        <div>
                            {title !== null && title !== undefined
                                ? <>{title}</>
                                : <>{t('banner.form.image')}</>
                            }
                        </div>
                        <div>
                            <Button buttonType={ButtonType.Back}
                                    style={{padding: '3px 15px'}}
                                    disabled={disabled}
                                    onClick={browseClick}>
                                {t('btn.select')}
                            </Button>
                        </div>
                    </div>
                    <div className={cl.uploaderDropArea}>
                        <div>
                            {t('shared.components.uploader-drop-area-label')}
                        </div>
                    </div>
                    <UploaderComponent ref={upload => uploaderRef = upload}
                                       files={uploadedImage}
                                       asyncSettings={apiPath}
                                       enabled={!disabled}
                                       created={onCreated}
                                       removing={onRemove}
                                       selected={onSelected}
                                       allowedExtensions='.png, .jpeg, .jpg'
                                       maxFileSize={1048576}
                                       locale={`${i18n.language.toLocaleLowerCase()}-${i18n.language.toLocaleUpperCase()}`}
                                       multiple={false}>

                    </UploaderComponent>
                </div>
            </div>
            {image && imagePreview &&
                <div className={cl.imageViewSection}
                     onClick={onImageCrop}>
                    <img alt='Banner logo' src={`data:image/png;base64,${image}`}/>
                </div>
            }
            <CropDialog open={isDialogShow}
                        change={onImageCropped}
                        cropperSettings={cropperSettings}
                        close={() => setIsDialogShow(prev => !prev)}
                        bytes={bytes}/>
        </div>
    );
}

export default ImageUploader;