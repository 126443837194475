import {useCallback, useEffect, useState} from "react";

export const useServerError = (initialState = null) => {
    const [state, setState] = useState(initialState);

    const setError = useCallback((err) => {
        setState(err);
    }, []);

    useEffect(() => {
        let timeoutId;
        if (state) {
            timeoutId = setTimeout(() => {
                setState(null);
            }, 5000);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        }
    }, [state]);

    return [state, setError];
}