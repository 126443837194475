import {L10n} from "@syncfusion/ej2-base";
import React from "react";
import {DataManager, Query, WebApiAdaptor} from "@syncfusion/ej2-data";
import {GridComponent as Grid} from "@syncfusion/ej2-react-grids/src/grid/grid.component";
import {refreshToken} from "../../../../features/authentication/authenticationSlice";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {appendFilter, removeFilter} from "../../../../features/filter/filterSlice";

L10n.load({
    'ru-RU': {
        'grid': {
            'EmptyDataSourceError': 'DataSource не должен быть пустым при начальной загрузке, так как столбцы генерируются из dataSource в AutoGenerate Column Grid.',
            'EmptyRecord': 'Нет данных для отображения',
            'Item': 'Элемент',
            'Items': 'Элементы',
        },
        'pager': {
            'currentPageInfo': '{0} из {1} страниц',
            'firstPageTooltip': 'К первой странице',
            'lastPageTooltip': 'К последней странице',
            'nextPageTooltip': 'К следующей странице',
            'nextPagerTooltip': 'Перейти к следующим элементам пейджера',
            'previousPageTooltip': 'К предыдущей странице',
            'previousPagerTooltip': 'Перейти к предыдущим элементам пейджера',
            'totalItemsInfo': '({0} элементов)'
        },
        'daterangepicker': {
            placeholder: 'Выберите промежуток',
            startLabel: 'Начало',
            endLabel: 'Окончание',
            applyText: 'Применить',
            cancelText: 'Отменить',
            selectedDays: 'Выбранные дни',
            days: 'дней(день)',
            customRange: 'Промежуток'
        }
    },
    'en-EN': {
        'grid': {
            'EmptyDataSourceError': 'DataSource must not be empty at initial load since columns are generated from dataSource in AutoGenerate Column Grid',
            'EmptyRecord': 'No records to display',
            'Item': 'Item',
            'Items': 'Items',
        },
        'pager': {
            'currentPageInfo': '{0} of {1} pages',
            'firstPageTooltip': 'Go to first page',
            'lastPageTooltip': 'Go to last page',
            'nextPageTooltip': 'Go to next page',
            'nextPagerTooltip': 'Go to next pager items',
            'previousPageTooltip': 'Go to previous page',
            'previousPagerTooltip': 'Go to previous pager items',
            'totalItemsInfo': '({0} items)'
        },
        'daterangepicker': {
            placeholder: 'Select date interval',
            startLabel: 'Select a start date for the interval',
            endLabel: 'Select the end date of the interval',
            applyText: 'Apply',
            cancelText: 'Cancel',
            selectedDays: 'Selected days',
            days: 'days(day)',
            customRange: 'interval'
        }
    }
});

class GridComponent extends React.Component {
    constructor(props) {
        super(props);

        let filterSettings = this.props.filterSettings
            ? this.props.filterSettings
            : {mode: 'Immediate', immediateModeDelay: 1000};

        if (props.filters && props.filters[this.props.filterStoreEntityName] && props.filters[this.props.filterStoreEntityName].length > 0) {
            let existFilters = props.filters[this.props.filterStoreEntityName];

            filterSettings.columns = existFilters.filter(item => {
                return item.ignoreInGrid === undefined || !item.ignoreInGrid;
            });
        }

        this.state = {
            query: this.props.query ? this.props.query : new Query(),
            dataSource: new DataManager({
                adaptor: new WebApiAdaptor(),
                url: `${process.env.REACT_APP_BE_DOMAIN}${this.props.url}`,
                headers: [
                    {
                        'Authorization': `bearer ${this.props.token}`
                    }
                ]
            }),
            locale: `${this.props.i18n.language}-${this.props.i18n.language.toUpperCase()}`,
            filterSettings: filterSettings,
            pageSettings: this.props.pageSettings
                ? this.props.pageSettings
                : {
                    enableExternalMessage: false,
                    pageSize: 30,
                },
            sortSettings: this.props.sortSettings,
            selectionSettings: this.props.selectionSettings,
            heightMinus: this.props.hasOwnProperty('heightMinus') ? this.props.heightMinus : 0,
            height: '100%',
            width: '100%',
            selectedRowsIds: this.props.selectedRowsIds,
            allowPaging: this.props.hasOwnProperty('allowPaging') ? this.props.allowPaging : true,
            allowSorting: this.props.hasOwnProperty('allowSorting') ? this.props.allowSorting : true,
            allowFiltering: this.props.hasOwnProperty('allowFiltering') ? this.props.allowFiltering : true,
            allowSelection: this.props.hasOwnProperty('allowSelection') ? this.props.allowSelection : true,
            filterStoreEntityName: this.props.filterStoreEntityName
        };

        this.onActionFailure = this.onActionFailure.bind(this);
        this.onActionBegin = this.onActionBegin.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onCreated = this.onCreated.bind(this);
        this.rowSelected = this.rowSelected.bind(this);
        this.rowDeselected = this.rowDeselected.bind(this);
        this.onRowDoubleClick = this.onRowDoubleClick.bind(this);
        this.onDataBound = this.onDataBound.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.token !== this.props.token) {
            this.setState({
                dataSource: new DataManager({
                    adaptor: new WebApiAdaptor(),
                    url: `${process.env.REACT_APP_BE_DOMAIN}${this.props.url}`,
                    headers: [
                        {
                            'Authorization': `bearer ${this.props.token}`
                        }
                    ]
                }),
            })
        }
        if (prevProps.query !== this.props.query) {
            this.setState({
                query: this.props.query
            });
        }
        if (prevProps.refresh !== this.props.refresh) {
            if (this.gridRef) {
                this.gridRef.refresh()
            }
        }

        if (prevProps.filter !== this.props.filter) {
            if (this.gridRef && this.props.filter) {
                if (this.props.filter.ev.value === null || this.props.filter.ev.value === undefined) {
                    this.props.removeFilter({
                        type: this.state.filterStoreEntityName,
                        field: this.props.filter.fieldName
                    });

                    let q = this.state.query;

                    if (q.params.find(item => item.key === this.props.filter.fieldName))
                        q.params = q.params.filter(item => item.key !== this.props.filter.fieldName);

                    this.setState({
                        query: q
                    });

                    this.gridRef.clearFiltering([this.props.filter.fieldName]);
                } else {
                    this.gridRef.filterByColumn(this.props.filter.fieldName, 'startswith', this.props.filter.ev.value);
                }
            }
        }

        if (prevProps.loader !== this.props.loader) {
            if (this.gridRef) {
                if (this.props.loader === 1) {
                    this.gridRef.showSpinner();
                } else if (this.props.loader === 0) {
                    this.gridRef.hideSpinner();
                }
            }
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize = () => {
        this.resizeGrid();
    };

    onActionFailure(ev) {
        if (ev.name && ev.error && ev.error.error && ev.name === 'actionFailure' && ev.error.error.status === 401) {
            this.props.refreshToken();
        }
    }

    onRowDoubleClick(ev) {
        if (this.props.onRowClick) {
            this.props.onRowClick(ev);
        }
    }

    onLoad() {
        this.resizeGrid();
    }

    onCreated() {
        this.resizeGrid();

        if (this.props.onGridCreated) {
            this.props.onGridCreated();
        }
    }

    onActionBegin(ev) {
        if (ev.action === 'filter' && ev.currentFilterObject) {
            this.props.appendFilter({
                type: this.state.filterStoreEntityName,
                value: {
                    field: ev.currentFilterObject.field,
                    operator: 'startswith',
                    value: ev.currentFilterObject.value
                }
            });

            if (this.state.query) {
                let q = this.state.query;

                if (q.params.find(item => item.key === ev.currentFilterObject.field))
                    q.params = q.params.filter(item => item.key !== ev.currentFilterObject.field);

                if (ev.currentFilterObject.value)
                    q.addParams(ev.currentFilterObject.field, ev.currentFilterObject.value);


                this.setState({
                    query: q
                });

            } else {
                this.setState({
                    query: new Query().addParams(ev.currentFilterObject.field, ev.currentFilterObject.value)
                });
            }
        }

        if (ev.action === 'clearFilter' && ev.currentFilterColumn && ev.currentFilterColumn.field) {
            this.props.removeFilter({
                type: this.state.filterStoreEntityName,
                field: ev.currentFilterColumn.field
            });

            let q = this.state.query;

            if (q.params.find(item => item.key === ev.currentFilterObject.field))
                q.params = q.params.filter(item => item.key !== ev.currentFilterObject.field);

            this.setState({
                query: q
            });
        }
    }

    resizeGrid() {
        let gridElement = document.getElementById(this.props.id);
        if (gridElement && gridElement.ej2_instances[0]) {
            let gridInstance = gridElement.ej2_instances[0];

            const heightToMinus = Array.from(document.querySelector(this.props.container).children)
                .filter(item => item.id !== this.props.id)
                .reduce((acc, elem) => acc + elem.clientHeight, 0);

            let pagerHeight = 0;
            if (document.querySelector('.e-pager'))
                pagerHeight = document.querySelector('.e-pager').clientHeight;

            let headerHeight = 0;
            if (document.querySelector('.e-gridheader'))
                headerHeight = document.querySelector('.e-gridheader').clientHeight;

            gridInstance.height = (document.querySelector(this.props.container).clientHeight - heightToMinus - pagerHeight - headerHeight - this.state.heightMinus - 4) + 'px';
        }
    }

    rowSelected(ev) {
        if (this.props.rowSelected) {
            this.props.rowSelected(ev);
        }
    }

    rowDeselected(ev) {
        if (this.props.rowDeselected) {
            this.props.rowDeselected(ev);
        }
    }

    onDataBound(ev) {
        if (this.gridRef && this.props.selectedRowsIds && this.props.selectedRowsIds.length > 0) {
            let indexes = [];
            for (let i of this.props.selectedRowsIds) {
                indexes.push(this.gridRef.getRowIndexByPrimaryKey(+i));
            }

            if (indexes.length > 0) {
                this.gridRef.selectRows(indexes);
            }
        }

        if (this.props.dataBound) {
            this.props.dataBound(ev);
        }
    }

    render() {
        return (
            <Grid ref={rf => this.gridRef = rf}
                  id={this.props.id}
                  locale={this.state.locale}
                  query={this.state.query}
                  dataSource={this.state.dataSource}
                  filterSettings={this.state.filterSettings}
                  sortSettings={this.state.sortSettings}
                  pageSettings={this.state.pageSettings}
                  selectionSettings={this.state.selectionSettings}
                  loadingIndicator={{indicatorType: 'Shimmer'}}
                  height={this.state.height}
                  width={this.state.width}
                  allowPaging={this.state.allowPaging}
                  allowSorting={this.state.allowSorting}
                  allowFiltering={this.state.allowFiltering}
                  allowSelection={this.state.allowSelection}
                  rowSelected={this.rowSelected}
                  rowDeselected={this.rowDeselected}
                  actionFailure={this.onActionFailure}
                  actionBegin={this.onActionBegin}
                  load={this.onLoad}
                  created={this.onCreated}
                  recordDoubleClick={this.onRowDoubleClick}
                  dataBound={this.onDataBound}>
                {this.props.children}
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    token: state.authentication.token,
    filters: state.filter
});

const mapDispatchToProps = dispatch => ({
    refreshToken: () => dispatch(refreshToken()),
    appendFilter: (payload) => dispatch(appendFilter(payload)),
    removeFilter: (payload) => dispatch(removeFilter(payload))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(GridComponent));