import React, {useState} from 'react';
import FormLayout from "../../../../ui/layouts/FormLayout/FormLayout";
import cl from "./ChangePasswordForm.module.css";
import Button, {ButtonType} from "../../../../ui/components/Button/Button";
import Input from "../../../../ui/components/Input/Input";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";
import {useChangePasswordMutation} from "../../../../../features/application/applicationApiSlice";
import getServerErrorResourceName from "../../../../../data/apiErrors/apiErrors";
import ResultCode from "../../../../../data/enums/ResultCode";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../../../../features/authentication/authenticationSelectors";

const ChangePasswordForm = ({close}) => {
    const {t} = useTranslation();
    const user = useSelector(selectCurrentUser);
    const [serverError, setServerError] = useState(null);
    const [changePassword] = useChangePasswordMutation();

    const form = useFormik({
        initialValues: {
            password: '',
            repeatPassword: ''
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("password.labels.password"))),
            repeatPassword: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("password.labels.password-repeat")))
                .oneOf([Yup.ref('password'), null], t("password.errors.match-error"))
        }),
        onSubmit: async (values, actions) => {
            try {
                setServerError(null);

                const response = await changePassword(
                    {
                        password: values.password,
                        passwordConfirmation: values.repeatPassword
                    });

                if (response.data) {
                    if (response.data.resultCode === ResultCode.Success) {
                        toast.success(t("password.labels.password-changed-success"));

                        actions.setSubmitting(false);

                        close();
                    } else {
                        setServerError(t(getServerErrorResourceName({
                            model: 'password',
                            resultCode: response.data.resultCode,
                            description: response.data.description
                        })));
                    }
                } else if (response.error) {
                    setServerError(t(getServerErrorResourceName({
                        model: 'password',
                        resultCode: ResultCode.ServerError
                    })));
                }
            } catch (error) {
                setServerError(t(getServerErrorResourceName({
                    model: 'password',
                    resultCode: ResultCode.ServerError
                })));
            }
        }
    });

    return (
        <FormLayout
            isLoading={form.isSubmitting}
            header={(
                <div className='header-form-layout'>
                    {t("password.labels.password-changing")}
                </div>
            )}
            footer={
                <div className='footer-form-layout'>
                    <Button buttonType={ButtonType.Warning}
                            width='100%'
                            onClick={() => close()}
                            disabled={form.isSubmitting}>
                        {t("btn.cancel")}
                    </Button>

                    <Button buttonType={ButtonType.Default}
                            width='100%'
                            form='change-password-form'
                            type='submit'
                            disabled={form.isSubmitting}>
                        {t("btn.submit")}
                    </Button>
                </div>
            }>
            <form id='change-password-form'
                  className={cl.form}
                  onSubmit={form.handleSubmit}>
                {serverError &&
                    <div className={cl.serverErrorContainer}>
                        <div>
                            {serverError}
                        </div>
                    </div>
                }
                <input style={{display:'none'}} autoComplete='username login' type='text' name='login' id='login' defaultValue={user.login} />
                <div className={cl.controlContainer}>
                    <label className='label required'>
                        {t("password.labels.password")}
                    </label>
                    <Input name='password'
                           type='password'
                           placeholder={t("password.labels.password")}
                           disabled={form.isSubmitting}
                           onChange={form.handleChange}
                           value={form.values.password}
                           autoComplete='new-password'/>
                    {form.touched.password && form.errors.password ?
                        <div className={cl.error}>{form.errors.password}</div> : null}
                </div>
                <div className={cl.controlContainer}>
                    <label className='label required'>
                        {t("password.labels.password-repeat")}
                    </label>
                    <Input name='repeatPassword'
                           type='password'
                           placeholder={t("password.labels.password-repeat")}
                           disabled={form.isSubmitting}
                           onChange={form.handleChange}
                           value={form.values.repeatPassword}
                           autoComplete='new-password'/>
                    {form.touched.repeatPassword && form.errors.repeatPassword ?
                        <div className={cl.error}>{form.errors.repeatPassword}</div> : null}
                </div>
            </form>
        </FormLayout>
    );
};

export default ChangePasswordForm;