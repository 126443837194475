import React from 'react';
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";
import {refreshToken} from "../../../../../features/authentication/authenticationSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectEntityFilterValue} from "../../../../../features/filter/filterSelectors";

const DropdownRemoteGridFilter = ({dataSource, column, onChange, query, filterStoreEntityName, ...rest}) => {
    const value = useSelector(state => selectEntityFilterValue(state, {
        type: filterStoreEntityName,
        field: column.field
    }));

    const dispatch = useDispatch();
    const onActionFailure = (ev) => {
        if (ev.name && ev.error && ev.error.error && ev.name === 'actionFailure' && ev.error.error.status === 401) {
            dispatch(refreshToken());
        }
    }

    return (
        <DropDownListComponent enabled={column.allowFiltering}
                               id={column.field}
                               actionFailure={onActionFailure}
                               popupHeight='250px'
                               dataSource={dataSource}
                               change={(ev) => onChange(ev, column.field)}
                               fields={{text: 'text', value: 'id'}}
                               showClearButton={true}
                               value={value ? value.value : null}
                               query={query}
                               {...rest}>

        </DropDownListComponent>
    );
};

export default DropdownRemoteGridFilter;