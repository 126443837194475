import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import {Container, Row, Col} from "react-bootstrap";
import cl from './PostForm.module.css';
import Input from "../../../ui/components/Input/Input";
import {HtmlEditor, Table, Image, Link, RichTextEditorComponent,PasteCleanup, Toolbar} from "@syncfusion/ej2-react-richtexteditor";
import {DatePickerComponent, DateTimePickerComponent, Inject, MaskedDateTime} from "@syncfusion/ej2-react-calendars";
import moment from "moment";
import Target from "../../../ui/components/Target/Target";
import * as Yup from "yup";
import {Query} from "@syncfusion/ej2-data";
import DropDownList from "../../../ui/components/DropDownList/DropDownList";
import {RadioButtonComponent} from "@syncfusion/ej2-react-buttons";
import ImageUploader from "../../../ui/components/ImageUploader/ImageUploader";
import apiAxios from "../../../../app/api/apiAxios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareArrowUpRight} from "@fortawesome/free-solid-svg-icons";
import useIsFirstRender from "../../../../hooks/useIsFirstRender";
import {L10n} from "@syncfusion/ej2-base";


const toolbarItems = {
    enableFloating: false,
    items: ['CreateLink', '|','Bold', 'Italic', 'Underline', 'StrikeThrough',  'FontColor',
        'BackgroundColor', 'LowerCase', 'UpperCase', '|', 'Formats', 'Alignments', 'OrderedList',
        'UnorderedList', 'Outdent', 'Indent', '|',  'ClearFormat', 'Print',
        'SourceCode', '|', 'Undo', 'Redo','|', 'CreateTable','RemoveTable']
};

L10n.load({
    'ru-RU': {
        'richtexteditor': {
            alignments: 'Выравнивание',
            justifyLeft: 'Выровнять по левому краю',
            justifyCenter: 'Выровнять по центру',
            justifyRight: 'Выровнять по правому краю',
            justifyFull: 'Выровнять по ширине',
            fontName: 'Название шрифта',
            fontSize: 'Размер шрифта',
            fontColor: 'Цвет шрифта',
            backgroundColor: 'Цвет фона',
            bold: 'Жирный',
            italic: 'Курсив',
            underline: 'Подчеркнутый',
            strikethrough: 'Зачеркнутый',
            clearFormat: 'Очистить формат',
            clearAll: 'Очистить все',
            cut: 'Вырезать',
            copy: 'Копировать',
            paste: 'Вставить',
            unorderedList: 'Маркированный список',
            orderedList: 'Нумерованный список',
            indent: 'Увеличить отступ',
            outdent: 'Уменьшить отступ',
            undo: 'Отменить',
            redo: 'Повторить',
            superscript: 'Верхний индекс',
            subscript: 'Нижний индекс',
            createLink: 'Вставить ссылку',
            openLink: 'Открыть ссылку',
            editLink: 'Изменить ссылку',
            removeLink: 'Удалить ссылку',
            image: 'Вставить изображение',
            replace: 'Заменить',
            align: 'Выровнять',
            caption: 'Подпись к изображению',
            remove: 'Удалить',
            insertLink: 'Вставить ссылку',
            display: 'Отобразить',
            altText: 'Альтернативный текст',
            dimension: 'Изменить размер',
            fullscreen: 'Развернуть',
            maximize: 'Развернуть',
            minimize: 'Свернуть',
            lowerCase: 'Нижний регистр',
            upperCase: 'Верхний регистр',
            print: 'Печать',
            formats: 'Форматы',
            sourcecode: 'Просмотр кода',
            preview: 'Предварительный просмотр',
            viewside: 'ViewSide',
            insertCode: 'Вставить код',
            linkText: 'Отображение текста',
            linkTooltipLabel: 'Заголовок',
            linkWebUrl: 'Веб-адрес',
            linkTitle: 'Введите заголовок',
            linkurl: 'http://example.com',
            linkOpenInNewWindow: 'Открыть ссылку в новом окне',
            linkHeader: 'Вставить ссылку',
            dialogInsert: 'Вставить',
            dialogCancel: 'Отмена',
            dialogUpdate: 'Обновить',
            imageHeader: 'Вставить изображение',
            imageLinkHeader: 'Вы также можете предоставить ссылку из Интернета',
            mdimageLink: 'Пожалуйста, укажите URL для вашего изображения',
            imageUploadMessage: 'Перетащите изображение сюда или выберите для загрузки',
            imageDeviceUploadMessage: 'Нажмите здесь для загрузки',
            imageAlternateText: 'Альтернативный текст',
            alternateHeader: 'Альтернативный текст',
            browse: 'Обзор',
            imageUrl: 'http://example.com/image.png',
            imageCaption: 'Подпись',
            imageSizeHeader: 'Размер изображения',
            imageHeight: 'Высота',
            imageWidth: 'Ширина',
            textPlaceholder: 'Введите текст',
            inserttablebtn: 'Вставить таблицу',
            tabledialogHeader: 'Вставить таблицу',
            tableWidth: 'Ширина',
            cellpadding: 'Отступ ячейки',
            cellspacing: 'Интервал ячейки',
            columns: 'Количество столбцов',
            rows: 'Количество строк',
            tableRows: 'Строки таблицы',
            tableColumns: 'Столбцы таблицы',
            tableCellHorizontalAlign: 'Горизонтальное выравнивание ячейки таблицы',
            tableCellVerticalAlign: 'Горизонтальное выравнивание ячейки таблицы',
            createTable: 'Создать таблицу',
            removeTable: 'Удалить таблицу',
            tableHeader: 'Заголовок таблицы',
            tableRemove: 'Удалить таблицу',
            tableCellBackground: 'Фон ячейки таблицы',
            tableEditProperties: 'Свойства редактирования таблицы',
            styles: 'Стили',
            insertColumnLeft: 'Вставить столбец слева',
            insertColumnRight: 'Вставить столбец справа',
            deleteColumn: 'Удалить столбец',
            insertRowBefore: 'Вставить строку перед',
            insertRowAfter: 'Вставить строку после',
            deleteRow: 'Удалить строку',
            tableEditHeader: 'Редактировать таблицу',
            TableHeadingText: 'Заголовок',
            TableColText: 'Столбец',
            imageInsertLinkHeader:'Вставить ссылку',
            editImageHeader:'Редактировать изображение',
            alignmentsDropDownLeft: 'Выровнять влево',
            alignmentsDropDownCenter: 'Выровнять по центру',
            alignmentsDropDownRight: 'Выровнять вправо',
            alignmentsDropDownJustify: 'Выровнять по ширине',
            imageDisplayDropDownInline: 'Встроенный',
            imageDisplayDropDownBreak: 'Break',
            tableInsertRowDropDownBefore: 'Вставить строку перед',
            tableInsertRowDropDownAfter: 'Вставить строку после',
            tableInsertRowDropDownDelete: 'Удалить строку',
            tableInsertColumnDropDownLeft: 'Вставить столбец слева',
            tableInsertColumnDropDownRight: 'Вставить столбец справа',
            tableInsertColumnDropDownDelete: 'Удалить столбец',
            tableVerticalAlignDropDownTop: 'Выровнять по верху',
            tableVerticalAlignDropDownMiddle: 'Выровнять по середине',
            tableVerticalAlignDropDownBottom: 'Выровнять по низу',
            tableStylesDropDownDashedBorder: 'Пунктирные границы',
            tableStylesDropDownAlternateRows: 'Альтернативные строки',
            pasteFormat: 'Вставить формат',
            pasteFormatContent: 'Выберите действие форматирования',
            plainText: 'Простой текст',
            cleanFormat: 'Очистить форматирование',
            keepFormat: 'Сохранить форматирование',
            pasteDialogOk: 'OK',
            pasteDialogCancel: 'Отмена',
            fileManager: 'Диспетчер файлов',
            fileDialogHeader: 'Обозреватель файлов',
            formatsDropDownParagraph: 'Абзац',
            formatsDropDownCode: 'Код',
            formatsDropDownQuotation: 'Цитата',
            formatsDropDownHeading1: 'Заголовок 1',
            formatsDropDownHeading2: 'Заголовок 2',
            formatsDropDownHeading3: 'Заголовок 3',
            formatsDropDownHeading4: 'Заголовок 4',
            fontNameSegoeUI: 'Segoe UI',
            fontNameArial: 'Arial',
            fontNameGeorgia: 'Georgia',
            fontNameImpact: 'Impact',
            fontNameTahoma: 'Tahoma',
            fontNameTimesNewRoman: 'Times New Roman',
            fontNameVerdana: 'Verdana',
            numberFormatListNumber: 'Число',
            numberFormatListLowerAlpha: 'LowerAlpha',
            numberFormatListUpperAlpha: 'UpperAlpha',
            numberFormatListLowerRoman: 'LowerRoman',
            numberFormatListUpperRoman: 'UpperRoman',
            numberFormatListLowerGreek: 'LowerGreek',
            bulletFormatListDisc: 'Disc',
            bulletFormatListCircle: 'Circle',
            bulletFormatListSquare: 'Square',
            numberFormatListNone: 'Нет',
            bulletFormatListNone:'Нет',
            formatPainter: 'Форматировать по образцу',
            emojiPicker: 'Выбор эмодзи',
            embeddedCode: 'Встроенный код',
            pasteEmbeddedCodeHere: 'Вставить встроенный код сюда',
            emojiPickerTypeToFind: 'Введите для поиска',
            emojiPickerNoResultFound: 'Результаты не найдены',
            emojiPickerTrySomethingElse: 'Результаты не найдены',
        }
    }
});

const PostForm = ({
                      id,
                      createdOn,
                      publicationDate,
                      targets,
                      isBlocked,
                      title,
                      draftTitle,
                      content,
                      draftContent,
                      postCategoryId,
                      showToResidents,
                      postSourceId,
                      otherPostSource,
                      showToAnonymous,
                      extUrl,
                      externalLink,
                      additionalInfo,
                      hasCustomFilter,
                      hasImageTemplate,
                      imageTemplateId,
                      postPhoto,
                      sourceImage,
                      postCategoryListType,
                      postCategoryType,
                      isImportant,
                      onSubmit,
                      resetTrigger,
                      position,
                      endDate
                  }) => {
        const {t} = useTranslation();
        const [postCategoryQuery] = useState(new Query().addParams('isBlocked', 'active').addParams('postCategoryType', '1'));
        const isFirstRender = useIsFirstRender();
        const [postImageTemplateQuery] = useState(new Query().addParams('isBlocked', 'active'));

        const pasteCleanupSettings = {
            allowedStyleProps: [],
            keepFormat: false,
            plainText: false,
            prompt: true
        };

        const form = useFormik({
            //enableReinitialize: true,
            initialValues: {
                createdOn: createdOn !== null ? moment.utc(createdOn).toDate() : null,
                publicationDate: publicationDate !== null ? moment.utc(publicationDate).toDate() : null,
                isBlocked: isBlocked,
                postCategoryId: postCategoryId,
                targets: targets,
                title: title === null ? '' : title,
                draftTitle: draftTitle === null ? '' : draftTitle,
                content: content,
                showToAnonymous: showToAnonymous,
                showToResidents: showToResidents,
                draftContent: draftContent,
                postSourceId: postSourceId,
                otherPostSource: otherPostSource === null ? '' : otherPostSource,
                extUrl: extUrl === null ? '' : extUrl,
                hasCustomFilter: hasCustomFilter,
                imageTemplateId: imageTemplateId,
                hasImageTemplate: hasImageTemplate,
                additionalInfo: additionalInfo === null ? '' : additionalInfo,
                externalLink: externalLink === null ? '' : externalLink,
                isImportant: isImportant,
                position: position === null ? '' : position,
                endDate: endDate !== null ? moment.utc(endDate).toDate() : null,
                postPhoto: postPhoto ?? {
                    id: 0,
                    icon: null,
                    iconName: null,
                    iconSize: null,
                    photo: null,
                    photoName: null,
                    photoSize: null
                },
                sourceImage: sourceImage,
                cropperSettings: postCategoryListType === 1 && postCategoryType === 1
                    ? {width: 720, height: 320}
                    : {width: 320, height: 320}
            },
            validationSchema: Yup.object({
                draftTitle: Yup.string()
                    .required(t("errors.required-error").replace('{0}', t("post.form.title"))),
                draftContent: Yup.string()
                    .required(t("errors.required-error").replace('{0}', t("post.form.draft-content"))),
                postCategoryId: Yup.string()
                    .required(t("errors.required-error").replace('{0}', t("post.form.category"))),
                postSourceId: Yup.number()
                    .nullable()
                    .transform((_, val) => val === Number(val) ? val : null)
                    .test("postSourceId", t("post.errors.post-source-required"), function (value) {
                        const {otherPostSource} = this.parent;

                        if (!otherPostSource)
                            return value !== null;

                        return true;
                    })
            }),
            onSubmit: onSubmit
        });

        useEffect(() => {
            if (!isFirstRender) {
                form.resetForm({
                    values: {
                        createdOn: createdOn !== null ? moment.utc(createdOn).toDate() : null,
                        publicationDate: publicationDate !== null ? moment.utc(publicationDate).toDate() : null,
                        isBlocked: isBlocked,
                        postCategoryId: postCategoryId,
                        targets: targets,
                        title: title === null ? '' : title,
                        draftTitle: draftTitle === null ? '' : draftTitle,
                        content: content,
                        showToAnonymous: showToAnonymous,
                        showToResidents: showToResidents,
                        draftContent: draftContent,
                        postSourceId: postSourceId,
                        otherPostSource: otherPostSource === null ? '' : otherPostSource,
                        extUrl: extUrl === null ? '' : extUrl,
                        hasCustomFilter: hasCustomFilter,
                        imageTemplateId: imageTemplateId,
                        hasImageTemplate: hasImageTemplate,
                        additionalInfo: additionalInfo === null ? '' : additionalInfo,
                        externalLink: externalLink === null ? '' : externalLink,
                        isImportant: isImportant,
                        position: position === null ? '' : position,
                        endDate: endDate !== null ? moment.utc(endDate).toDate() : null,
                        postPhoto: postPhoto ?? {
                            id: 0,
                            icon: null,
                            iconName: null,
                            iconSize: null,
                            photo: null,
                            photoName: null,
                            photoSize: null
                        },
                        sourceImage: sourceImage,
                        cropperSettings: postCategoryListType === 1 && postCategoryType === 1
                            ? {width: 720, height: 320}
                            : {width: 320, height: 320}
                    }
                });
            }
        }, [resetTrigger]);

        const [postSourceQuery, setPostSourceQuery] = useState(new Query().addParams('isBlocked', 'active').addParams("category", postCategoryId));

        const onPostSourceChange = (ev) => {
            if (ev && ev.itemData) {
                (async () => {
                        form.setSubmitting(true);

                        const url = `${process.env.REACT_APP_BE_DOMAIN}/api/postSource/getPostSource?id=${ev.itemData.id}`;

                        const response = await apiAxios.get(url);

                        if (response) {
                            if (response.status === 200 &&
                                response.data &&
                                response.data &&
                                response.data.logo !== null &&
                                response.data.logo.length > 0) {
                                await form.setFieldValue('sourceImage', response.data.logo);
                            }
                        }

                        form.setSubmitting(false);
                    }
                )();
            } else {
                form.setFieldValue('sourceImage', null);
            }

            form.setFieldValue('postSourceId', ev.itemData ? ev.itemData.id : null)
        };

        const onPostCategoryChange = (ev) => {
            if (ev && ev.itemData) {
                (async () => {
                    form.setSubmitting(true);

                    const url = `${process.env.REACT_APP_BE_DOMAIN}/api/postCategory/getPostCategory?id=${ev.itemData.id}`;

                    const response = await apiAxios.get(url);

                    if (response) {
                        if (response.status === 200 && response.data) {
                            if (response.data.listType !== null && response.data.listType === 1 && response.data.postType === 1) {
                                await form.setFieldValue('cropperSettings', {width: 720, height: 320});
                            } else {
                                await form.setFieldValue('cropperSettings', {width: 320, height: 320});
                            }
                        }
                    }

                    form.setSubmitting(false);
                })();
            } else {
                form.setFieldValue('cropperSettings', {width: 320, height: 320});
            }

            form.setFieldValue('postCategoryId', ev.itemData ? ev.itemData.id : null);
            form.setFieldValue('postSourceId', null);
            form.setFieldValue('sourceImage', null);

            if (ev.itemData) {
                setPostSourceQuery(new Query().addParams('isBlocked', 'active').addParams('category', ev.itemData.id.toString()));
            }
        };


        return (
            <Container fluid>
                <form id='post-form' onSubmit={form.handleSubmit}>
                    <Row>
                        <Col>
                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label required'>
                                    {t("post.form.category")}
                                </label>
                                <DropDownList url='/api/postCategory/getPostCategoriesList'
                                              popupHeight='250px'
                                              query={postCategoryQuery}
                                              change={onPostCategoryChange}
                                              enabled={!form.isSubmitting && id <= 0}
                                              placeholder={t("post.form.category")}
                                              value={form.values.postCategoryId}
                                              showClearButton={false}/>
                                {form.touched.postCategoryId && form.errors.postCategoryId ?
                                    <div className='field-error'>{form.errors.postCategoryId}</div> : null}
                            </div>
                        </Col>
                        <Col className={cl.checkboxesContainer}>
                            <div className='input-container checkbox'>
                                <label className='label'>
                                    {t("post.form.blocked")}
                                </label>
                                <Input name='isBlocked'
                                       type='checkbox'
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       checked={form.values.isBlocked}/>
                            </div>
                            <div className='input-container checkbox'>
                                <label className='label'>
                                    {t("post.form.show-to-anonymous")}
                                </label>
                                <Input name='showToAnonymous'
                                       type='checkbox'
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       checked={form.values.showToAnonymous}/>
                            </div>
                            <div className='input-container checkbox'>
                                <label className='label'>
                                    {t("post.form.show-to-residents")}
                                </label>
                                <Input name='showToResidents'
                                       type='checkbox'
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       checked={form.values.showToResidents}/>
                            </div>
                            <div className='input-container checkbox'>
                                <label className='label'>
                                    {t("post.grid.important-tab")}
                                </label>
                                <Input name='isImportant'
                                       type='checkbox'
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       checked={form.values.isImportant}/>
                            </div>
                        </Col>
                    </Row>
                    <Row className={cl.formRow}>
                        <Col>
                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label'>
                                    {t("post.form.created-on")}
                                </label>
                                <DateTimePickerComponent enabled={false}
                                                         placeholder={t("page.form.created-on")}
                                                         value={form.values.createdOn}
                                                         enableMask={true}
                                                         format='dd.MM.yyyy HH:mm'
                                                         timeFormat='HH:mm'
                                                         maskPlaceholder={{
                                                             day: 'dd',
                                                             month: 'MM',
                                                             year: 'yyyy',
                                                             hour: 'HH',
                                                             minute: 'mm',
                                                             second: 'ss'
                                                         }}>
                                    <Inject services={[MaskedDateTime]}/>
                                </DateTimePickerComponent>
                            </div>
                        </Col>
                        <Col>
                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label'>
                                    {t("post.form.end-date")}
                                </label>
                                <DateTimePickerComponent enabled={!form.isSubmitting && form.values.isImportant}
                                                         placeholder={t("post.form.end-date")}
                                                         value={form.values.endDate}
                                                         enableMask={true}
                                                         change={ev => {
                                                             form.setFieldValue('endDate', ev.value)
                                                         }}
                                                         format='dd.MM.yyyy HH:mm'
                                                         timeFormat='HH:mm'
                                                         maskPlaceholder={{
                                                             day: 'dd',
                                                             month: 'MM',
                                                             year: 'yyyy',
                                                             hour: 'HH',
                                                             minute: 'mm',
                                                             second: 'ss'
                                                         }}>
                                    <Inject services={[MaskedDateTime]}/>
                                </DateTimePickerComponent>
                            </div>
                        </Col>
                    </Row>
                    <Row className={cl.formRow}>
                        <Col>
                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label'>
                                    {t("post.form.publication-date")}
                                </label>
                                <DateTimePickerComponent enabled={!form.isSubmitting}
                                                         placeholder={t("page.form.publication-date")}
                                                         value={form.values.publicationDate}
                                                         enableMask={true}
                                                         change={ev => {
                                                             form.setFieldValue('publicationDate', ev.value)
                                                         }}
                                                         format='dd.MM.yyyy HH:mm'
                                                         timeFormat='HH:mm'
                                                         maskPlaceholder={{
                                                             day: 'dd',
                                                             month: 'MM',
                                                             year: 'yyyy',
                                                             hour: 'HH',
                                                             minute: 'mm',
                                                             second: 'ss'
                                                         }}>
                                    <Inject services={[MaskedDateTime]}/>
                                </DateTimePickerComponent>
                            </div>
                        </Col>
                        <Col>
                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label'>
                                    {t("post.form.position")}
                                </label>
                                <Input name='position'
                                       type='text'
                                       placeholder={t("post.form.position")}
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       autoComplete='off'
                                       value={form.values.position}/>
                            </div>
                        </Col>
                    </Row>
                    <Row className={cl.formRow}>
                        <Col>
                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label required'>
                                    {t("post.form.post-source")}
                                </label>
                                <DropDownList url='/api/postSource/getPostSourcesList'
                                              popupHeight='250px'
                                              query={postSourceQuery}
                                              skipIfNoQueryParams={true}
                                              change={onPostSourceChange}
                                              enabled={!form.isSubmitting && form.values.postCategoryId !== null}
                                              placeholder={t("post.form.post-source")}
                                              value={form.values.postSourceId}
                                              showClearButton={true}/>
                            </div>

                            <div className='input-container'>
                                <label className='label required'>
                                    {t("post.form.other-post-source")}
                                </label>
                                <Input name='otherPostSource'
                                       type='text'
                                       placeholder={t("post.form.other-post-source")}
                                       disabled={form.isSubmitting || form.values.postSourceId !== null}
                                       onChange={form.handleChange}
                                       autoComplete='off'
                                       value={form.values.otherPostSource}/>
                                {form.touched.postSourceId && form.errors.postSourceId ?
                                    <div className='field-error'>{form.errors.postSourceId}</div> : null}
                            </div>
                        </Col>
                        <Col>
                            <div className='input-container'>
                                <label className='label'>
                                    {t("post.form.external-link")}
                                </label>
                                <div className={cl.relative}>
                                    <Input name='externalLink'
                                           type='text'
                                           placeholder={t("post.form.external-link")}
                                           disabled={true}
                                           autoComplete='off'
                                           value={form.values.externalLink}/>
                                    {form.values.externalLink &&
                                        <a href={form.values.externalLink}
                                           target={'_blank'}
                                           className={cl.redirect}
                                           title={t("btn.open-link")}>
                                            <FontAwesomeIcon icon={faSquareArrowUpRight}/>
                                        </a>
                                    }
                                </div>
                            </div>

                            <div className='input-container'>
                                <label className='label'>
                                    {t("post.form.additional-info")}
                                </label>
                                <Input name='additionalInfo'
                                       type='text'
                                       placeholder={t("post.form.additional-info")}
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       autoComplete='off'
                                       value={form.values.additionalInfo}/>
                            </div>

                            <div className='input-container'>
                                <label className='label'>
                                    {t("post.form.ext-url")}
                                </label>
                                <Input name='extUrl'
                                       type='text'
                                       placeholder={t("post.form.ext-url")}
                                       disabled={form.isSubmitting}
                                       onChange={form.handleChange}
                                       autoComplete='off'
                                       value={form.values.extUrl}/>
                            </div>
                        </Col>
                    </Row>
                    <div className={cl.htmlEditorRow}>
                        <div>
                            <div className='input-container' style={{height: '530px'}}>
                                {form.touched.draftContent && form.errors.draftContent ?
                                    <div className='field-error'>{form.errors.draftContent}</div> : null}
                                {form.touched.draftTitle && form.errors.draftTitle ?
                                    <div className='field-error'>{form.errors.draftTitle}</div> : null}

                                <div className={cl.relative}>
                                    <label className='label'>
                                        {t("post.form.draft-content")}
                                    </label>
                                    <Input name='draftTitle'
                                           type='text'
                                           placeholder={t("post.form.draft-content")}
                                           disabled={form.isSubmitting}
                                           onChange={form.handleChange}
                                           autoComplete='off'
                                           value={form.values.draftTitle}/>
                                    {form.values.externalLink &&
                                        <a href={`${form.values.externalLink}&isDraft=true`}
                                           target={'_blank'}
                                           className={cl.redirect}
                                           title={t("btn.open-link")}>
                                            <FontAwesomeIcon icon={faSquareArrowUpRight}/>
                                        </a>
                                    }
                                </div>

                                <div className={cl.contentContainer}>
                                    <RichTextEditorComponent value={form.values.draftContent} locale={'ru-RU'}
                                                             height={447}
                                                             pasteCleanupSettings={pasteCleanupSettings}
                                                             width='100%'
                                                             change={(ev) => form.setFieldValue('draftContent', ev && ev.value ? ev.value : null)}
                                                             enabled={!form.isSubmitting}
                                                             toolbarSettings={toolbarItems}>

                                        <Inject services={[Toolbar, Image, Link, HtmlEditor, Table, PasteCleanup]}/>
                                    </RichTextEditorComponent>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='input-container' style={{height: '530px'}}>
                                <div className={cl.relative}>
                                    <label className='label'>
                                        {t("post.form.content")}
                                    </label>
                                    <Input name='title'
                                           type='text'
                                           placeholder={t("post.form.content")}
                                           disabled={true}
                                           onChange={form.handleChange}
                                           autoComplete='off'
                                           value={form.values.title}/>
                                    {form.values.externalLink &&
                                        <a href={form.values.externalLink}
                                           target={'_blank'}
                                           className={cl.redirect}
                                           title={t("btn.open-link")}>
                                            <FontAwesomeIcon icon={faSquareArrowUpRight}/>
                                        </a>
                                    }
                                </div>

                                <div className={cl.contentContainer}>
                                    <div className={`${cl.content}`}
                                         dangerouslySetInnerHTML={{__html: form.values.content}}>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Row className={cl.formRow}>
                    <Col>

                        <ImageUploader title={t("post.form.list-icon")}
                                       image={
                                           form.values.postPhoto !== null && form.values.postPhoto.icon !== null
                                               ? form.values.postPhoto.icon
                                               : null
                                       }
                                       name={
                                           form.values.postPhoto !== null && form.values.postPhoto.iconName !== null
                                               ? form.values.postPhoto.iconName
                                               : null
                                       }
                                       size={
                                           form.values.postPhoto !== null && form.values.postPhoto.iconSize !== null
                                               ? form.values.postPhoto.iconSize
                                               : null
                                       }
                                       disabled={form.isSubmitting}
                                       cropperSettings={form.values.cropperSettings}
                                       change={ev => {
                                           form.setFieldValue('postPhoto.icon', ev.image);
                                           form.setFieldValue('postPhoto.iconName', ev.name);
                                           form.setFieldValue('postPhoto.iconSize', ev.size);
                                       }}/>
                        {form.values.sourceImage !== null &&
                            form.values.sourceImage.length > 0 &&
                            (form.values.postPhoto === null || form.values.postPhoto.icon === null) &&
                            <div className={cl.imageViewSection}>
                                <img alt='Banner logo' src={`data:image/png;base64,${form.values.sourceImage}`}/>
                            </div>
                        }

                    </Col>
                    <Col>
                        <div className={cl.radioButtonsContainer}>
                            <RadioButtonComponent name='has-custom-filter'
                                                  checked={!form.values.hasCustomFilter}
                                                  disabled={form.isSubmitting}
                                                  change={() => form.setFieldValue('hasCustomFilter', false)}
                                                  label={t("post.form.has-no-custom-filter")}>

                            </RadioButtonComponent>
                            <RadioButtonComponent name='has-custom-filter'
                                                  checked={form.values.hasCustomFilter}
                                                  change={() => form.setFieldValue('hasCustomFilter', true)}
                                                  disabled={form.isSubmitting}
                                                  label={t("post.form.has-custom-filter")}>

                            </RadioButtonComponent>
                        </div>
                        {form.values.hasCustomFilter &&
                            <Target targets={form.values.targets}
                                    disabled={form.isSubmitting}
                                    change={(items) => form.setFieldValue('targets', items)}/>
                        }
                        <div style={{
                            margin: '10px 0'
                        }}>

                            {form.values.postCategoryId == 5 && <div className={cl.radioButtonsContainer}>
                                <RadioButtonComponent name='has-image-template'
                                                      checked={form.values.hasImageTemplate}
                                                      disabled={form.isSubmitting}
                                                      change={() => form.setFieldValue('hasImageTemplate', true)}
                                                      label={t("post.form.has-image-template")}>

                                </RadioButtonComponent>
                                <RadioButtonComponent name='has-image-template'
                                                      checked={!form.values.hasImageTemplate}
                                                      change={() => form.setFieldValue('hasImageTemplate', false)}
                                                      disabled={form.isSubmitting}
                                                      label={t("post.form.has-image")}>

                                </RadioButtonComponent>


                            </div>}
                            {form.values.hasImageTemplate && <div className='input-container syncfusion-control-input-container'>
                                <label className='label'>
                                    {t("post.form.template")}
                                </label>

                                <DropDownList url='/api/postImageTemplate/getPostImageTemplatesList'
                                              popupHeight='250px'
                                              query={postImageTemplateQuery}
                                              change={(ev) => form.setFieldValue('imageTemplateId', ev.itemData ? ev.itemData.id : null)}
                                              enabled={!form.isSubmitting}
                                              placeholder={t("post.form.template")}
                                              value={form.values.imageTemplateId}
                                              showClearButton={true}/>

                            </div>}
                            <ImageUploader
                                image={
                                    form.values.postPhoto !== null && form.values.postPhoto.photo !== null
                                        ? form.values.postPhoto.photo
                                        : null
                                }
                                name={
                                    form.values.postPhoto !== null && form.values.postPhoto.photoName !== null
                                        ? form.values.postPhoto.photoName
                                        : null
                                }
                                size={
                                    form.values.postPhoto !== null && form.values.postPhoto.photoSize !== null
                                        ? form.values.postPhoto.photoSize
                                        : null
                                }
                                disabled={form.isSubmitting || form.values.hasImageTemplate}
                                cropperSettings={{width: 720, height: 320}}
                                change={ev => {
                                    form.setFieldValue('postPhoto.photo', ev.image);
                                    form.setFieldValue('postPhoto.photoName', ev.name);
                                    form.setFieldValue('postPhoto.photoSize', ev.size);
                                }}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
;

export default PostForm;
