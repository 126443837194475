import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useServerError} from "../../../../../../hooks/useServerError";
import apiAxios from "../../../../../../app/api/apiAxios";
import ResultCode from "../../../../../../data/enums/ResultCode";
import getServerErrorResourceName from "../../../../../../data/apiErrors/apiErrors";
import Button, {ButtonType} from "../../../../../ui/components/Button/Button";
import FormLayout from "../../../../../ui/layouts/FormLayout/FormLayout";
import NewsGrabberSettingDetails from "./NewsGrabberSettingDetails";
import {toast} from "react-toastify";

const initialState = {
    id: null,
    site: '',
    siteCode: '',
    sourceId: null,
    isBlocked: false
}

const NewsGrabberSettingForm = ({close, id}) => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [setting, setSetting] = useState(initialState);
    const [serverError, setServerError] = useServerError(null);

    useEffect(() => {
        (async () => await load(id))();
    }, [id]);

    const load = async (id) => {
        if (id) {
            setServerError(null);
            setSetting(initialState);
            setIsLoading(true);

            const url = `${process.env.REACT_APP_BE_DOMAIN}/api/setting/getGrabberSetting?id=${id}`;

            try {
                const response = await apiAxios.get(url);

                if (response && response.status === 200 && response.data) {
                    if (response.data.resultCode === ResultCode.Success) {
                        setSetting(response.data);
                    }
                }

                setIsLoading(false);
            } catch (e) {
                if (e && e.message) {
                    setServerError(`${t(getServerErrorResourceName({
                        model: 'setting',
                        resultCode: ResultCode.ServerError
                    }))} Message: (${e.message})`);
                }
                setIsLoading(false);
            }
        }
    }

    const save = async (values, actions) => {
        try {
            setServerError(null);
            setIsLoading(true);
            actions.setSubmitting(true);

            const response = await apiAxios.post(`${process.env.REACT_APP_BE_DOMAIN}/api/setting/saveGrabberSetting`, {
                id: setting.id,
                site: values.site,
                siteCode: values.siteCode,
                sourceId: values.sourceId,
                isBlocked: values.isBlocked
            });

            if (response && response.status === 200 && response.data) {
                if (response.data.resultCode === ResultCode.Success) {
                    toast.success(t("setting.form.saved-label"));

                    close();
                } else {
                    setServerError(t(getServerErrorResourceName({
                        model: 'setting',
                        resultCode: response.data.resultCode,
                        description: response.data.description
                    })));
                }
            }

            setIsLoading(false);
            actions.setSubmitting(false);
        } catch (err) {
            if (err && err.message) {
                setServerError(`${t(getServerErrorResourceName({
                    model: 'setting',
                    resultCode: ResultCode.ServerError
                }))} Message: (${err.message})`);
            }
            setIsLoading(false);
            actions.setSubmitting(false);
        }
    }

    const deleteItem = () => {
        (async () => {
            try {
                setServerError(null);
                setIsLoading(true);

                const response = await apiAxios.post(`${process.env.REACT_APP_BE_DOMAIN}/api/setting/deleteGrabberSetting`, {
                    id: setting.id
                });

                if (response && response.status === 200 && response.data) {
                    if (response.data.resultCode === ResultCode.Success) {
                        toast.success(t("setting.form.deleted-label"));

                        close();
                    } else {
                        setServerError(t(getServerErrorResourceName({
                            model: 'setting',
                            resultCode: response.data.resultCode,
                            description: response.data.description
                        })));
                    }
                }

                setIsLoading(false);
            } catch (err) {
                if (err && err.message) {
                    setServerError(`${t(getServerErrorResourceName({
                        model: 'setting',
                        resultCode: ResultCode.ServerError
                    }))} Message: (${err.message})`);
                }
                setIsLoading(false);
            }
        })();
    }

    return (
        <FormLayout header={<div className='default-form-layout-header'>{t("setting.form.grabber-setting-label")}</div>}
                    footer={(
                        <div className='default-form-layout-footer'>
                            <div>
                                <Button buttonType={ButtonType.Back}
                                        onClick={close}
                                        disabled={isLoading}>
                                    {t("btn.cancel")}
                                </Button>
                                {setting && setting.id &&
                                    <Button buttonType={ButtonType.Warning}
                                            disabled={isLoading}
                                            onClick={deleteItem}
                                            style={{marginLeft: '10px'}}>
                                        {t("btn.delete")}
                                    </Button>
                                }
                            </div>
                            <Button buttonType={ButtonType.Default}
                                    form='grabber-setting-form'
                                    type='submit'
                                    disabled={isLoading}>
                                {t("btn.save")}
                            </Button>
                        </div>
                    )}
                    isLoading={isLoading}>
            <div className='default-form-layout-container'>
                {serverError &&
                    <div className='server-error-container'>
                        <div>
                            {serverError}
                        </div>
                    </div>
                }
                <NewsGrabberSettingDetails {...setting} onSubmit={save}/>
            </div>
        </FormLayout>
    );
};

export default NewsGrabberSettingForm;