import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import moment from "moment/moment";
import * as Yup from "yup";
import {Container, Row, Col} from "react-bootstrap";
import Target from "../../../ui/components/Target/Target";
import cl from './PageForm.module.css';
import {Query} from "@syncfusion/ej2-data";
import DropDownList from "../../../ui/components/DropDownList/DropDownList";
import Input from "../../../ui/components/Input/Input";
import {DateTimePickerComponent, Inject, MaskedDateTime} from "@syncfusion/ej2-react-calendars";
import {HtmlEditor, Link, Image, RichTextEditorComponent, Toolbar} from "@syncfusion/ej2-react-richtexteditor";

const toolbarItems = {
    enableFloating: false,
    items: [
        'Bold',
        'Italic',
        'Underline',
        'StrikeThrough',
        'FontName',
        'FontSize',
        'FontColor',
        'BackgroundColor',
        'LowerCase',
        'UpperCase',
        '|',
        'Formats',
        'Alignments',
        'OrderedList',
        'UnorderedList',
        'Outdent',
        'Indent', '|',
        'CreateLink',
        'Image', '|',
        'ClearFormat',
        'Print',
        'SourceCode',
        '|',
        'Undo',
        'Redo']
};

const PageForm = ({
                      id,
                      createdOn,
                      publicationDate,
                      isBlocked,
                      title,
                      draftTitle,
                      content,
                      draftContent,
                      isDark,
                      postCategoryId,
                      targets,
                      showToAnonym,
                      showToResidents,
                      onSubmit
                  }) => {
    const [postCategoryQuery] = useState(new Query().addParams('isBlocked', 'active').addParams('postCategoryType', '0'));

    const {t} = useTranslation();

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: id,
            createdOn: createdOn !== null ? moment.utc(createdOn).toDate() : null,
            publicationDate: publicationDate !== null ? moment.utc(publicationDate).toDate() : null,
            isBlocked: isBlocked,
            title: title,
            draftTitle: draftTitle,
            content: content,
            draftContent: draftContent,
            isDark: isDark,
            postCategoryId: postCategoryId,
            targets: targets,
            showToResidents: showToResidents,
            showToAnonym: showToAnonym
        },
        validationSchema: Yup.object({
            draftTitle: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("page.form.title"))),
            draftContent: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("page.form.draft-content"))),
            postCategoryId: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("page.form.category"))),
        }),
        onSubmit: onSubmit
    });

    return (
        <Container fluid>
            <form id='page-form' onSubmit={form.handleSubmit}>
                <Row>
                    <Col>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label required'>
                                {t("page.form.category")}
                            </label>
                            <DropDownList url='/api/postCategory/getPostCategoriesList'
                                          popupHeight='250px'
                                          query={postCategoryQuery}
                                          change={(ev) => form.setFieldValue('postCategoryId', ev.itemData ? ev.itemData.id : null)}
                                          enabled={!form.isSubmitting && id <= 0}
                                          placeholder={t("page.form.category")}
                                          value={form.values.postCategoryId}
                                          showClearButton={false}/>
                            {form.touched.postCategoryId && form.errors.postCategoryId ?
                                <div className='field-error'>{form.errors.postCategoryId}</div> : null}
                        </div>
                    </Col>
                    <Col className={cl.checkboxesContainer}>
                        <div className='input-container checkbox'>
                            <label className='label'>
                                {t("page.form.blocked")}
                            </label>
                            <Input name='isBlocked'
                                   type='checkbox'
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   checked={form.values.isBlocked}/>
                        </div>
                        <div className='input-container checkbox'>
                            <label className='label'>
                                {t("page.form.show-to-anonymous")}
                            </label>
                            <Input name='showToAnonym'
                                   type='checkbox'
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   checked={form.values.showToAnonym}/>
                        </div>
                        <div className='input-container checkbox'>
                            <label className='label'>
                                {t("page.form.show-to-residents")}
                            </label>
                            <Input name='showToResidents'
                                   type='checkbox'
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   checked={form.values.showToResidents}/>
                        </div>
                    </Col>
                </Row>
                <Row className={cl.formRow}>
                    <Col>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label'>
                                {t("page.form.created-on")}
                            </label>
                            <DateTimePickerComponent enabled={false}
                                                     placeholder={t("page.form.created-on")}
                                                     value={form.values.createdOn}
                                                     enableMask={true}
                                                     format='dd.MM.yyyy HH:mm'
                                                     timeFormat='HH:mm'
                                                     maskPlaceholder={{
                                                         day: 'dd',
                                                         month: 'MM',
                                                         year: 'yyyy',
                                                         hour: 'HH',
                                                         minute: 'mm',
                                                         second: 'ss'
                                                     }}>
                                <Inject services={[MaskedDateTime]}/>
                            </DateTimePickerComponent>
                        </div>
                    </Col>
                    <Col>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label'>
                                {t("page.form.publication-date")}
                            </label>
                            <DateTimePickerComponent enabled={false}
                                                     placeholder={t("page.form.publication-date")}
                                                     value={form.values.publicationDate}
                                                     enableMask={true}
                                                     format='dd.MM.yyyy HH:mm'
                                                     timeFormat='HH:mm'
                                                     maskPlaceholder={{
                                                         day: 'dd',
                                                         month: 'MM',
                                                         year: 'yyyy',
                                                         hour: 'HH',
                                                         minute: 'mm',
                                                         second: 'ss'
                                                     }}>
                                <Inject services={[MaskedDateTime]}/>
                            </DateTimePickerComponent>
                        </div>
                    </Col>
                </Row>
                <Row className={cl.formRow}>
                    <Col>
                        <div className='input-container checkbox'>
                            <Input name='isDark'
                                   type='checkbox'
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   checked={form.values.isDark}/>
                            <label className='label'>
                                {t("page.form.is-dark")}
                            </label>
                        </div>
                    </Col>
                </Row>
                <div className={cl.htmlEditorRow}>
                    <div>
                        <div className='input-container' style={{height: '530px'}}>
                            {form.touched.draftContent && form.errors.draftContent ?
                                <div className='field-error'>{form.errors.draftContent}</div> : null}
                            {form.touched.draftTitle && form.errors.draftTitle ?
                                <div className='field-error'>{form.errors.draftTitle}</div> : null}
                            <label className='label'>
                                {t("page.form.draft-content")}
                            </label>
                            <Input name='draftTitle'
                                   type='text'
                                   placeholder={t("page.form.draft-content")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.draftTitle}/>
                            <div className={cl.contentContainer}>
                                <RichTextEditorComponent value={form.values.draftContent}
                                                         height={447}
                                                         width='100%'
                                                         change={(ev) => form.setFieldValue('draftContent', ev && ev.value ? ev.value : null)}
                                                         enabled={!form.isSubmitting}
                                                         toolbarSettings={toolbarItems}>

                                    <Inject services={[Toolbar, Image, Link, HtmlEditor]}/>
                                </RichTextEditorComponent>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='input-container' style={{height: '530px'}}>
                            <label className='label'>
                                {t("page.form.content")}
                            </label>
                            <Input name='title'
                                   type='text'
                                   placeholder={t("page.form.content")}
                                   disabled={true}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.title}/>
                            <div className={cl.contentContainer}>
                                <div className={`${cl.content} ${form.values.isDark ? cl.dark : ''}`}
                                     dangerouslySetInnerHTML={{__html: form.values.content}}>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <Row className={cl.formRow}>
                <Col></Col>
                <Col>
                    <Target targets={form.values.targets}
                            disabled={form.isSubmitting}
                            change={(items) => form.setFieldValue('targets', items)}/>
                </Col>
            </Row>
        </Container>
    );
};

export default PageForm;