import React from 'react';
import {ColumnDirective, ColumnsDirective, GridComponent} from '@syncfusion/ej2-react-grids';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../../../../features/authentication/authenticationSelectors";
import PermissionType from "../../../../../data/enums/PermissionType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

const RoleTemplate = (props) => {
    const {t} = useTranslation();

    return (
        <div>
            {t(`user.form.account.user-role.${props.role}`)}
        </div>
    );
}

const DeleteTemplate = ({item, onDelete}) => {
    const {t} = useTranslation();

    return (
        <span title={t("btn.delete")} style={{color: 'red', cursor: 'pointer', fontSize: '15px'}}
              onClick={() => onDelete(item)}>
            <FontAwesomeIcon icon={faTrash}/>
        </span>
    );
}

const UserFormAccountsGrid = ({accounts, onAccountDelete}) => {
    const user = useSelector(selectCurrentUser);
    const {t} = useTranslation();

    return (
        <GridComponent dataSource={accounts}
                       id='user-accounts-grid'
                       height='300px'
                       locale='ru-RU'
                       allowSelection={false}
                       width='100%'>
            <ColumnsDirective>
                <ColumnDirective field='id'
                                 visible={false}
                                 textAlign="center"
                                 isPrimaryKey={true}
                                 type='number'/>
                <ColumnDirective field='accountNumber'
                                 headerText={t("user.form.account.account")}
                                 width='40%'
                                 textAlign="center"/>
                <ColumnDirective field='role'
                                 headerText={t("user.form.account.role")}
                                 width='40%'
                                 template={props => <RoleTemplate {...props}/>}
                                 textAlign="center"/>
                <ColumnDirective field='isVerificationAllowed'
                                 headerText={t("user.form.account.verification-allowed")}
                                 width='20%'
                                 displayAsCheckBox={true}
                                 textAlign="center"/>
                <ColumnDirective field='isBlocked'
                                 headerText={t("user.form.account.blocked")}
                                 width='20%'
                                 displayAsCheckBox={true}
                                 textAlign="center"/>
                {user !== null &&
                    user.permission !== null &&
                    user.permission.userLog === PermissionType.Editing &&
                    <ColumnDirective width='20%'
                                     template={props => <DeleteTemplate item={props}
                                                                        onDelete={onAccountDelete}/>}
                                     textAlign="center"/>
                }
            </ColumnsDirective>
        </GridComponent>
    );
};

export default UserFormAccountsGrid;