import React, {memo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentToken} from "../../../../features/authentication/authenticationSelectors";
import {DataManager, WebApiAdaptor} from "@syncfusion/ej2-data";
import {refreshToken} from "../../../../features/authentication/authenticationSlice";
import {ComboBoxComponent} from "@syncfusion/ej2-react-dropdowns";

const ComboBox = memo(({value, url, onChange, ...rest}) => {
    const dispatch = useDispatch();
    const token = useSelector(selectCurrentToken);
    const [dataSource] = useState(new DataManager({
        adaptor: new WebApiAdaptor(),
        url: `${process.env.REACT_APP_BE_DOMAIN}${url}`,
        headers: [
            {
                'Authorization': `bearer ${token}`
            }
        ]
    }));

    const onActionFailure = (ev) => {
        if (ev.name && ev.error && ev.error.error && ev.name === 'actionFailure' && ev.error.error.status === 401) {
            dispatch(refreshToken());
        }
    }

    return (
        <ComboBoxComponent {...rest}
                           value={value}
                           fields={{text: 'text', value: 'id'}}
                           dataSource={dataSource}
                           actionFailure={onActionFailure}>

        </ComboBoxComponent>
    );
});

export default ComboBox;