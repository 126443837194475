import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import FormLayout from "../../../../../ui/layouts/FormLayout/FormLayout";
import {useFormik} from "formik";
import Button, {ButtonType} from "../../../../../ui/components/Button/Button";
import * as Yup from "yup";
import {Col, Container, Row} from "react-bootstrap";
import Input from "../../../../../ui/components/Input/Input";
import {useServerError} from "../../../../../../hooks/useServerError";
import apiAxios from "../../../../../../app/api/apiAxios";
import ResultCode from "../../../../../../data/enums/ResultCode";
import {toast} from "react-toastify";
import getServerErrorResourceName from "../../../../../../data/apiErrors/apiErrors";

const UserSyncForm = ({close, onSyncSuccess}) => {
    const {t} = useTranslation();
    const [serverError, setServerError] = useServerError(null);
    const [isLoading, setIsLoading] = useState(false);

    const form = useFormik({
        initialValues: {
            phone: ''
        },
        validationSchema: Yup.object({
            phone: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("user.form.phone"))),
        }),
        onSubmit: async (values, actions) => {
            try {
                setServerError(null);
                actions.setSubmitting(true);
                setIsLoading(prev => !prev);

                const response = await apiAxios.post(`${process.env.REACT_APP_BE_DOMAIN}/api/user/syncFromCrmByNumber`, {
                    phone: values.phone
                });

                if (response) {
                    if (response.status === 200 && response.data) {
                        if (response.data.resultCode === ResultCode.Success) {
                            const timeOut = setTimeout(() => {
                                clearTimeout(timeOut);
                                toast.success(t("user.sync.sync-label"));

                                if (onSyncSuccess) {
                                    actions.setSubmitting(false);
                                    setIsLoading(prev => !prev);

                                    onSyncSuccess();
                                }
                            }, 10000);
                        } else {
                            setServerError(t(getServerErrorResourceName({
                                model: 'user',
                                resultCode: response.data.resultCode,
                                description: response.data.description
                            })));
                            actions.setSubmitting(false);
                            setIsLoading(prev => !prev);
                        }
                    }
                }
            } catch (err) {
                if (err && err.message) {
                    setServerError(`${t(getServerErrorResourceName({
                        model: 'user',
                        resultCode: ResultCode.ServerError
                    }))} Message: (${err.message})`);
                }
                actions.setSubmitting(false);
                setIsLoading(prev => !prev);
            }
        }
    });

    return (
        <FormLayout isLoading={form.isSubmitting || isLoading}
                    header={(
                        <div className='header-form-layout'>
                            {t("user.sync.sync-from-crm-label")}
                        </div>
                    )}
                    footer={
                        <div className='footer-form-layout'>
                            <Button buttonType={ButtonType.Warning}
                                    width='100%'
                                    onClick={() => close()}
                                    disabled={form.isSubmitting}>
                                {t("btn.cancel")}
                            </Button>

                            <Button buttonType={ButtonType.Default}
                                    width='100%'
                                    form='sync-form'
                                    type='submit'
                                    disabled={form.isSubmitting}>
                                {t("user.btn.sync-from-crm-short")}
                            </Button>
                        </div>
                    }>
            <div className='default-form-layout-container'>
                {serverError &&
                    <div className='server-error-container'>
                        <div>
                            {serverError}
                        </div>
                    </div>
                }
                <Container fluid>
                    <form id='sync-form'
                          onSubmit={form.handleSubmit}>
                        <Row>
                            <Col>
                                <div className='input-container'>
                                    <label className='label required'>
                                        {t("user.form.phone")}
                                    </label>
                                    <Input name='phone'
                                           type='text'
                                           placeholder={t("user.form.phone")}
                                           disabled={form.isSubmitting}
                                           onChange={form.handleChange}
                                           autoComplete='off'
                                           value={form.values.phone}/>
                                    {form.touched.phone && form.errors.phone ?
                                        <div className='field-error'>{form.errors.phone}</div> : null}
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Container>
            </div>
        </FormLayout>
    );
};

export default UserSyncForm;