import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {refreshToken} from "../../../../features/authentication/authenticationSlice";
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";
import {DataManager, WebApiAdaptor} from "@syncfusion/ej2-data";
import {selectCurrentToken} from "../../../../features/authentication/authenticationSelectors";

const AuthRemoteDropDownListComponent = ({url, value, ...props}) => {
    const dispatch = useDispatch();
    const [dataSource, setDataSource] = useState(null);
    const token = useSelector(selectCurrentToken);
    let ref;

    useEffect(() => {
        if (url) {
            setDataSource(new DataManager({
                adaptor: new WebApiAdaptor(),
                url: `${process.env.REACT_APP_BE_DOMAIN}${url}`,
                headers: [
                    {
                        'Authorization': `bearer ${token}`
                    }
                ]
            }));
        }
    }, [url])

    const onActionFailure = (ev) => {
        if (ev.name && ev.error && ev.error.error && ev.name === 'actionFailure' && ev.error.error.status === 401) {
            dispatch(refreshToken());
        }
    }

    return (
        <DropDownListComponent {...props}
                               ref={rf => ref = rf}
                               value={value}
                               fields={{text: 'text', value: 'id'}}
                               dataSource={dataSource}
                               actionFailure={onActionFailure}>

        </DropDownListComponent>
    );
};

export default AuthRemoteDropDownListComponent;