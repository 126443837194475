import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import moment from "moment/moment";
import * as Yup from "yup";
import {Container, Row, Col} from "react-bootstrap";
import Target from "../../../ui/components/Target/Target";
import cl from './VideoCameraForm.module.css';
import {Query} from "@syncfusion/ej2-data";
import DropDownList from "../../../ui/components/DropDownList/DropDownList";
import Input from "../../../ui/components/Input/Input";
import {DateTimePickerComponent, Inject, MaskedDateTime} from "@syncfusion/ej2-react-calendars";
import {HtmlEditor, Link, Image, RichTextEditorComponent, Toolbar} from "@syncfusion/ej2-react-richtexteditor";
import ImageUploader from "../../../ui/components/ImageUploader/ImageUploader";



const VideoCameraForm = ({
                      id,
                      createdOn,
                      url,
                      name,
                      isBlocked,
                      targets,
                      photo,
                      onSubmit
                  }) => {

    const {t} = useTranslation();

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: id,
            createdOn: createdOn !== null ? moment.utc(createdOn).toDate() : null,
            isBlocked: isBlocked,
            url: url,
            name: name,
            targets: targets,
            photo: photo ?? {
                id: 0,
                icon: null,
                iconName: null,
                iconSize: null,
                photo: null,
                photoName: null,
                photoSize: null
            },
            cropperSettings: {width: 720, height: 320}
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required(t("errors.required-error").replace('{0}', t("videocamera.form.category"))),
        }),
        onSubmit: onSubmit
    });

    return (
        <Container fluid>
            <form id='page-form' onSubmit={form.handleSubmit}>
                <Row>
                    <Col>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label required'>
                                {t("videocamera.form.name")}
                            </label>
                            <Input name='name'
                                   type='text'
                                   placeholder={t("videocamera.form.name")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.name}/>
                        </div>
                    </Col>
                    <Col className={cl.checkboxesContainer}>
                        <div className='input-container checkbox'>
                            <label className='label'>
                                {t("videocamera.form.blocked")}
                            </label>
                            <Input name='isBlocked'
                                   type='checkbox'
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   checked={form.values.isBlocked}/>
                        </div>

                    </Col>
                </Row>
                <Row className={cl.formRow}>
                    <Col>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label required'>
                                {t("videocamera.form.url")}
                            </label>
                            <Input name='url'
                                   type='text'
                                   placeholder={t("videocamera.form.url")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.url}/>
                        </div>
                    </Col>

                </Row>


            </form>
            <Row className={cl.formRow}>

                <Col>
                    <Target targets={form.values.targets}
                            disabled={form.isSubmitting}
                            change={(items) => form.setFieldValue('targets', items)}/>
                </Col>
            </Row>

            <ImageUploader title={t("post.form.list-icon")}
                           image={
                               form.values.photo !== null && form.values.photo.icon !== null
                                   ? form.values.photo.icon
                                   : null
                           }
                           name={
                               form.values.photo !== null && form.values.photo.iconName !== null
                                   ? form.values.photo.iconName
                                   : null
                           }
                           size={
                               form.values.photo !== null && form.values.photo.iconSize !== null
                                   ? form.values.photo.iconSize
                                   : null
                           }
                           disabled={form.isSubmitting}
                           cropperSettings={form.values.cropperSettings}
                           change={ev => {
                               form.setFieldValue('photo.icon', ev.image);
                               form.setFieldValue('photo.iconName', ev.name);
                               form.setFieldValue('photo.iconSize', ev.size);
                           }}/>
        </Container>
    );
};

export default VideoCameraForm;
