import React from 'react';
import Dialog from "../../../../../ui/components/Dialog/Dialog";
import NewsGrabberSettingForm from "../NewsGrabberSettingForm/NewsGrabberSettingForm";

const NewsGrabberSettingDialog = ({open, onOverlayClick, close, ...rest}) => {
    return (
        <Dialog width='700px'
                height='350px'
                open={open}
                onOverlayClick={onOverlayClick}>
            <NewsGrabberSettingForm close={close} {...rest}/>
        </Dialog>
    );
};

export default NewsGrabberSettingDialog;