import {decode, encode} from "js-base64";
import instance from "../apiAxios";
import {logOut, setCredentials} from "../../../features/authentication/authenticationSlice";
import ResultCode from "../../../data/enums/ResultCode";

const setupInterceptors = (store) => {
    instance.interceptors.request.use(
        (config) => {
            if (localStorage.getItem('_d')) {
                const parsedData = JSON.parse(decode(localStorage.getItem('_d')));
                if (parsedData && (typeof parsedData === 'object')) {
                    config.headers["Authorization"] = `Bearer ${parsedData.token}`
                }
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    const {dispatch, getState} = store;
    instance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            const state = getState();

            if (err.response && err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    const response = await instance.post('/api/authentication/refreshToken', {
                        token: state.authentication.token,
                        refreshToken: state.authentication.refreshToken
                    });

                    if (response.status === 200 &&
                        response.data?.resultCode === ResultCode.Success &&
                        response.data.token &&
                        response.data.refreshToken) {

                        localStorage.setItem('_d', encode(JSON.stringify({
                            user: state.authentication.user,
                            refreshToken: response.data.refreshToken,
                            token: response.data.token,
                            expiry: response.data.validTo,
                        })));

                        dispatch(setCredentials({
                            user: state.authentication.user,
                            token: response.data.token,
                            refreshToken: response.data.refreshToken,
                            expiry: response.data.validTo
                        }));

                        return instance(originalConfig);
                    } else {
                        localStorage.removeItem('_d');

                        dispatch(logOut());
                    }

                } catch (e) {
                    return Promise.reject(e);
                }
            }

            return Promise.reject(err);
        }
    );
};

export default setupInterceptors;