import React from 'react';

class AppErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {hasError: false};
    }

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div style={{fontSize: '20px', color: 'red', fontWeight: 'bold'}}>
                        Something went wrong. Please try to refresh the page
                    </div>
                </div>);
        }

        return this.props.children;
    }
}

export default AppErrorBoundary;