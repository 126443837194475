import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import cl from './TargetHeader.module.css';

const TargetHeader = ({onAddButtonClick}) => {
    const {t} = useTranslation();

    return (
        <div className={cl.targetHeader}>
            <div>
                {t("locationTarget.label")}
            </div>
            <div>
                <FontAwesomeIcon title={t("btn.add")}
                                 onClick={onAddButtonClick}
                                 icon={faPlus}/>
            </div>
        </div>
    );
};

export default TargetHeader;