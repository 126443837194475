import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Query} from "@syncfusion/ej2-data";
import GridTab from "../../../ui/components/Grid/GridTab/GridTab";
import GridTabSection, {GridTabSectionType} from "../../../ui/components/Grid/GridTab/GridTabSection/GridTabSection";
import GridTabItem from "../../../ui/components/Grid/GridTab/GridTabItem/GridTabItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowDown, faFileExport, faRefresh} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../ui/components/Loader/Loader";
import GridComponent from "../../../ui/components/Grid/GridComponent";
import {ColumnDirective, ColumnsDirective, Filter, Inject, Page, Sort} from "@syncfusion/ej2-react-grids";
import UserStateFilter from "./filters/UserStateFilter";
import {useNavigate} from "react-router-dom";
import UsersExportDialog from "./UsersExportDialog/UsersExportDialog";
import UserSyncDialog from "./UserSyncDialog/UserSyncDialog";

const User = () => {
    const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
    const [isSyncDialogOpen, setIsSyncDialogOpen] = useState(false);
    const [query, setQuery] = useState(null);
    const [tab, setTab] = useState('0');
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [filterTrigger, setFilterTrigger] = useState(null);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const onTabItemClick = ({id, queryParameterName, queryKey}) => {
        if (queryParameterName && queryKey) {
            setTab(queryKey);
        } else if (id) {
            if (id === 'refresh-btn') {
                setRefreshTrigger(prev => prev + 1);
            }

            if (id === 'export-btn') {
                setIsExportDialogOpen(prev => !prev);
            }

            if (id === 'sync-btn') {
                setIsSyncDialogOpen(prev => !prev);
            }
        }
    }

    useEffect(() => {
        setQuery(prev => {
            let q = new Query();
            if (prev) {
                if (prev && prev.params && prev.params.length > 0) {
                    const params = prev.params.filter(item => item.key !== 'tab');
                    for (const item of params) {
                        q.addParams(item.key, item.value);
                    }
                }
                q.addParams('tab', tab);
            } else {
                const urlParams = new URLSearchParams(window.location.search);
                if (urlParams.get('tab')) {
                    q.addParams('tab', urlParams.get('tab'));
                } else {
                    q.addParams('tab', tab);
                }
            }

            return q;
        });
    }, [tab]);

    const onFilterChange = (ev, fieldName) => setFilterTrigger({
        ev,
        fieldName
    });

    const onRowDoubleClick = ev => {
        if (ev && ev.rowData) {
            navigate(`/users/user/${ev.rowData.id}`);
        }
    }

    const onSyncSuccess = () => {
        setIsSyncDialogOpen(prev => !prev);

        setRefreshTrigger(prev => prev + 1);
    };

    return (
        <>
            <UserSyncDialog open={isSyncDialogOpen}
                            close={() => setIsSyncDialogOpen(prev => !prev)}
                            onSyncSuccess={onSyncSuccess}/>
            <UsersExportDialog open={isExportDialogOpen}
                               close={() => setIsExportDialogOpen(prev => !prev)}/>
            <div className='list-container'>
                <GridTab onClick={onTabItemClick}>
                    <GridTabSection type={GridTabSectionType.Tab}
                                    showRightSeparator={true}
                                    queryParameterName='tab'>
                        <GridTabItem id='all-tab'
                                     text={t("shared.grid.all-tab")}
                                     queryKey='0'
                                     isDefaultActive={true}>

                        </GridTabItem>
                        <GridTabItem id='blocked-tab'
                                     text={t("shared.grid.blocked-tab")}
                                     queryKey='1'>

                        </GridTabItem>
                        <GridTabItem id='without-account-tab'
                                     text={t("user.grid.without-account-tab")}
                                     queryKey='2'>

                        </GridTabItem>
                        <GridTabItem id='with-account-request-tab'
                                     text={t("user.grid.with-account-request-tab")}
                                     queryKey='3'>

                        </GridTabItem>
                    </GridTabSection>
                    <GridTabSection type={GridTabSectionType.Button}>
                        <GridTabItem id='sync-btn'
                                     text={t("user.btn.sync-from-crm-short")}
                                     icon={<FontAwesomeIcon icon={faCloudArrowDown}/>}>

                        </GridTabItem>
                        <GridTabItem id='export-btn'
                                     text={t("btn.export")}
                                     icon={<FontAwesomeIcon icon={faFileExport}/>}>

                        </GridTabItem>
                        <GridTabItem id='refresh-btn'
                                     text={t("btn.refresh")}
                                     icon={<FontAwesomeIcon icon={faRefresh}/>}>

                        </GridTabItem>
                    </GridTabSection>
                </GridTab>
                {!query
                    ? <Loader/>
                    : <GridComponent id='grid'
                                     container='.list-container'
                                     url='/api/user/getUsers'
                                     query={query}
                                     filterStoreEntityName={'user'}
                                     refresh={refreshTrigger}
                                     onRowClick={onRowDoubleClick}
                                     filter={filterTrigger}>
                        <ColumnsDirective>
                            <ColumnDirective field='id'
                                             visible={false}
                                             textAlign="center"
                                             isPrimaryKey={true}
                                             type='number'/>
                            <ColumnDirective field='fullName'
                                             headerText={t("user.grid.name")}
                                             width='350'
                                             textAlign="center"/>
                            <ColumnDirective field='phone'
                                             headerText={t("user.grid.phone")}
                                             width='200'
                                             textAlign="center"/>
                            <ColumnDirective field='email'
                                             headerText={t("user.form.email")}
                                             width='200'
                                             textAlign="center"/>
                            <ColumnDirective field='isAllowCheckLists'
                                             headerText={t("user.form.is-allow-check-lists")}
                                             width='170'
                                             displayAsCheckBox={true}
                                             textAlign="center"
                                             filterTemplate={(props) => <UserStateFilter
                                                 onFilterChange={onFilterChange}
                                                 filterStoreEntityName={'user'} {...props}/>}/>
                            <ColumnDirective field='isAllowVote'
                                             headerText={t("user.form.is-allow-vote")}
                                             width='170'
                                             displayAsCheckBox={true}
                                             textAlign="center"
                                             filterTemplate={(props) => <UserStateFilter
                                                 onFilterChange={onFilterChange}
                                                 filterStoreEntityName={'user'} {...props}/>}/>
                            <ColumnDirective field='isBlocked'
                                             headerText={t("user.grid.blocked")}
                                             width='170'
                                             displayAsCheckBox={true}
                                             textAlign="center"
                                             filterTemplate={(props) => <UserStateFilter
                                                 onFilterChange={onFilterChange}
                                                 filterStoreEntityName={'user'} {...props}/>}/>
                        </ColumnsDirective>
                        <Inject services={[Page, Sort, Filter]}/>
                    </GridComponent>
                }
            </div>
        </>
    );
}

export default User;