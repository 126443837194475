import React from 'react';
import {Outlet} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectCurrentLoadingState} from "../../features/application/applicationSelectors";
import LoadingScreen from "../../components/ui/components/LoadingScreen/LoadingScreen";


const BaseLayout = () => {
    const isLoading = useSelector(selectCurrentLoadingState);

    if (isLoading) {
        return <LoadingScreen/>
    }

    return <Outlet/>
};

export default BaseLayout;