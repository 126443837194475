import React from 'react';
import {useTranslation} from "react-i18next";
import DropdownGridFilter from "../../../../ui/components/Grid/filters/DropdownGridFilter";

const PollPeriodFilter = ({onFilterChange, ...rest}) => {
    const {t} = useTranslation();
    const data = [
        {id: '0', text: t("poll.poll-period.one-time")},
        {id: '1', text: t("poll.poll-period.day")},
        {id: '2', text: t("poll.poll-period.week")},
        {id: '3', text: t("poll.poll-period.month")},
    ];

    return <DropdownGridFilter onChange={onFilterChange} data={data} {...rest} />
};

export default PollPeriodFilter;