import ResultCode from "../enums/ResultCode";

const errors = {
    login: [
        {
            resultCode: ResultCode.NotFound,
            description: 'user',
            resourceName: 'login.errors.user-not-found'
        },
        {
            resultCode: ResultCode.Blocked,
            description: 'user',
            resourceName: 'login.errors.user-blocked'
        },
        {
            resultCode: ResultCode.InvalidData,
            description: 'auth',
            resourceName: 'login.errors.user-not-found'
        },
        {
            resultCode: ResultCode.ServerError,
            resourceName: 'errors.server-error'
        }
    ],
    password: [
        {
            resultCode: ResultCode.ServerError,
            resourceName: 'errors.server-error'
        }
    ],
    admin: [
        {
            resultCode: ResultCode.ServerError,
            resourceName: 'errors.server-error'
        },
        {
            resultCode: ResultCode.NotAuthorized,
            resourceName: 'admin.errors.not-authorized'
        },
        {
            resultCode: ResultCode.NotFound,
            resourceName: 'admin.errors.not-found'
        },
        {
            resultCode: ResultCode.InvalidData,
            resourceName: 'admin.errors.invalid-data'
        },
        {
            resultCode: ResultCode.AlreadyExist,
            resourceName: 'admin.errors.login-already-exist',
            description: 'login',
        }
    ],
    analytics: [
        {
            resultCode: ResultCode.ServerError,
            resourceName: 'errors.server-error'
        },
        {
            resultCode: ResultCode.NotAuthorized,
            resourceName: 'analytics.errors.not-authorized',
        },
        {
            resultCode: ResultCode.InvalidData,
            resourceName: 'analytics.errors.invalid-data',
        }
    ],
    setting: [
        {
            resultCode: ResultCode.ServerError,
            resourceName: 'errors.server-error'
        },
        {
            resultCode: ResultCode.NotAuthorized,
            resourceName: 'setting.errors.not-authorized'
        },
        {
            resultCode: ResultCode.NotFound,
            resourceName: 'setting.errors.not-found'
        },
        {
            resultCode: ResultCode.AlreadyExist,
            resourceName: 'setting.errors.name-already-exist',
            description: 'name',
        }
    ],
    user: [
        {
            resultCode: ResultCode.ServerError,
            resourceName: 'errors.server-error'
        },
        {
            resultCode: ResultCode.NotAuthorized,
            resourceName: 'user.errors.not-authorized'
        },
        {
            resultCode: ResultCode.NotFound,
            resourceName: 'user.errors.not-found'
        },
        {
            resultCode: ResultCode.InvalidData,
            resourceName: 'user.sync.invalid-data-error',
            description: 'user'
        }
    ],
    banner: [
        {
            resultCode: ResultCode.ServerError,
            resourceName: 'errors.server-error'
        },
        {
            resultCode: ResultCode.NotAuthorized,
            resourceName: 'banner.errors.not-authorized'
        },
        {
            resultCode: ResultCode.NotFound,
            resourceName: 'banner.errors.not-found'
        },
        {
            resultCode: ResultCode.InvalidData,
            resourceName: 'banner.errors.invalid-data'
        },
        {
            resultCode: ResultCode.AlreadyExist,
            resourceName: 'banner.errors.already-exist',
            description: 'name'
        }
    ],
    postCategory: [
        {
            resultCode: ResultCode.ServerError,
            resourceName: 'errors.server-error'
        },
        {
            resultCode: ResultCode.NotAuthorized,
            resourceName: 'postCategory.errors.not-authorized'
        },
        {
            resultCode: ResultCode.NotFound,
            resourceName: 'postCategory.errors.not-found'
        },
        {
            resultCode: ResultCode.InvalidData,
            resourceName: 'postCategory.errors.invalid-data'
        },
        {
            resultCode: ResultCode.AlreadyExist,
            resourceName: 'postCategory.errors.already-exist',
            description: 'name'
        }
    ],
    postSource: [
        {
            resultCode: ResultCode.ServerError,
            resourceName: 'errors.server-error'
        },
        {
            resultCode: ResultCode.NotAuthorized,
            resourceName: 'postSource.errors.not-authorized'
        },
        {
            resultCode: ResultCode.NotFound,
            resourceName: 'postSource.errors.not-found'
        },
        {
            resultCode: ResultCode.InvalidData,
            resourceName: 'postSource.errors.invalid-data'
        },
        {
            resultCode: ResultCode.AlreadyExist,
            resourceName: 'postSource.errors.already-exist',
            description: 'name'
        }
    ],
    page: [
        {
            resultCode: ResultCode.ServerError,
            resourceName: 'errors.server-error'
        },
        {
            resultCode: ResultCode.NotAuthorized,
            resourceName: 'page.errors.not-authorized'
        },
        {
            resultCode: ResultCode.NotFound,
            resourceName: 'page.errors.not-found'
        },
        {
            resultCode: ResultCode.InvalidData,
            resourceName: 'page.errors.invalid-data'
        }
    ],
    post: [
        {
            resultCode: ResultCode.ServerError,
            resourceName: 'errors.server-error'
        },
        {
            resultCode: ResultCode.NotAuthorized,
            resourceName: 'post.errors.not-authorized'
        },
        {
            resultCode: ResultCode.NotFound,
            resourceName: 'post.errors.not-found'
        },
        {
            resultCode: ResultCode.InvalidData,
            resourceName: 'post.errors.invalid-data'
        }
    ],
    account: [
        {
            resultCode: ResultCode.ServerError,
            resourceName: 'errors.server-error'
        },
        {
            resultCode: ResultCode.NotAuthorized,
            resourceName: 'account.errors.not-authorized'
        },
        {
            resultCode: ResultCode.NotFound,
            resourceName: 'account.errors.not-found'
        }
    ],
    poll: [
        {
            resultCode: ResultCode.ServerError,
            resourceName: 'errors.server-error'
        },
        {
            resultCode: ResultCode.NotAuthorized,
            resourceName: 'poll.errors.not-authorized'
        },
        {
            resultCode: ResultCode.NotFound,
            resourceName: 'poll.errors.not-found'
        },
        {
            resultCode: ResultCode.AlreadyExist,
            resourceName: 'poll.errors.name-already-exist',
            description: 'name'
        }
    ]
};

export default ({model, resultCode, description}) => {
    return errors[model].find((item) => {
        if (description) {
            return item.resultCode === resultCode && item.description === description;
        } else {
            return item.resultCode === resultCode;
        }
    }).resourceName;
}