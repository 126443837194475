export default {
    NotAvailable: 0,
    Reading: 1,
    Editing: 2,
}

export const getPermissionLabelResourceName = (permissionType) => {
    switch (permissionType) {
        case 0:
            return "shared.permission.not-available";
        case 1:
            return "shared.permission.reading";
        case 2:
            return "shared.permission.editing";
        default:
            return "shared.permission.not-available";
    }
}