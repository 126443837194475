import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../../../ui/components/Dialog/Dialog";
import cl from './PollQuestionPointList.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrashCanArrowUp} from "@fortawesome/free-solid-svg-icons";
import {getPollTypeName} from "../../utils";
import NestedPollQuestionForm from "../../NestedPollQuestionForm/NestedPollQuestionForm";

function generatePointList(quantity, items) {
    if (quantity !== null) {
        if (!items || items.length === 0) {
            let repeat = quantity === 0 ? 5 : 10;
            let arr = [];

            for (let i = 0; i <= repeat - 1; i++) {
                arr.push({
                    id: null,
                    point: i + 1,
                    questionText: '',
                    multipleSelection: false,
                    isRequired: false,
                    pollId: null,
                    parentQuestionId: null,
                    pollQuestions: null,
                    type: null
                });
            }

            return arr;
        } else {
            if (quantity === 0 && items.length === 10) {
                return items.slice(0, 5);
            }

            if (quantity === 1 && items.length === 5) {
                let arr = items;

                for (let i = 0; i <= 4; i++) {
                    arr.push({
                        id: null,
                        point: Math.max(...arr.map(p => p.point)) + 1,
                        questionText: '',
                        multipleSelection: false,
                        isRequired: false,
                        pollId: null,
                        parentQuestionId: null,
                        pollQuestions: null,
                        type: null
                    });
                }
            }
        }
    }

    return items;
}

const PollQuestionPointList = ({quantity, items, disabled, change}) => {
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [item, setItem] = useState(null);
    const data = useRef(items);
    const [points, setPoints] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        if (items) {
            setIsLoading(prev => !prev);

            setPoints(items);

            setIsLoading(prev => !prev);
        }
    }, []);

    useEffect(() => {
        if (data.current) {
            data.current = null

            return;
        }

        setIsLoading(prev => !prev);

        setPoints(generatePointList(quantity, points));

        setIsLoading(prev => !prev);
    }, [quantity])

    useEffect(() => {
        if (change) {
            change(points);
        }
    }, [points]);

    const editItem = (item) => {
        if (!disabled) {
            setItem(item);
            setIsEditDialogOpen(prev => !prev)
        }
    };

    const onSave = (item) => {
        if (item) {
            setPoints([
                ...points.filter(p => p.point !== item.point),
                item
            ]);

            setIsEditDialogOpen(prev => !prev);
        }
    };

    const resetItem = (item) => {
        if (item) {
            const newItem = {
                id: null,
                point: item.point,
                questionText: '',
                multipleSelection: false,
                isRequired: false,
                pollId: null,
                parentQuestionId: null,
                pollQuestions: null,
                type: null,
            };

            setPoints([
                ...points.filter(p => p.point !== item.point),
                newItem
            ]);
        }
    };

    return (
        <>
            {item
                ?
                <Dialog open={isEditDialogOpen}
                        id={'poll-question-point-form'}
                        usePortalToDocumentBody={true}
                        width='850px'
                        height='550px'>
                    <NestedPollQuestionForm nestedQuestion={item}
                                            close={() => setIsEditDialogOpen(prev => !prev)}
                                            onSave={onSave}/>
                </Dialog>
                : null
            }
            <div className={cl.container}>
                {isLoading
                    ? <div className={cl.loaderContainer}>
                        <div className='loader'></div>
                    </div>
                    : null
                }
                {points !== null && points.length > 0
                    ? <table className={cl.table}>
                        <tbody>
                        <tr>
                            <th style={{width: '70px'}}>{t("poll.form.point")}</th>
                            <th style={{width: 'calc(100% - 300px)'}}>{t("poll.form.additional-question")}</th>
                            <th style={{width: '180px'}}>{t("poll.form.type")}</th>
                            <th style={{width: '50px'}}></th>
                            <th style={{width: '50px'}}></th>
                        </tr>
                        {points.sort((first, second) => first.point - second.point).map(p => {
                            return (
                                <tr key={p.point}>
                                    <td>
                                        {p.point}
                                    </td>
                                    <td>
                                        {p.questionText !== null
                                            ? p.questionText
                                            : null
                                        }
                                    </td>
                                    <td>
                                        {getPollTypeName(t, p.type)}
                                    </td>
                                    <td>
                                        <FontAwesomeIcon className={cl.edit} onClick={() => editItem(p)} icon={faEdit}/>
                                    </td>
                                    <td>
                                        <FontAwesomeIcon className={cl.reset} onClick={() => resetItem(p)}
                                                         icon={faTrashCanArrowUp}/>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    : null
                }
            </div>
        </>
    );
};

export default PollQuestionPointList;