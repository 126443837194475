import React from 'react';
import {useTranslation} from "react-i18next";

const ProblemUserProblemTemplate = (props) => {
    const {t} = useTranslation();
    let text;

    if (props.error && props.error === 'account') {
        text = t("analytics.errors.user-has-no-accounts");
    } else {
        if (props.registrationConfirmationStatus === 0) {
            text = t("analytics.errors.registration-confirmation-code-sent")
        } else if (props.registrationConfirmationStatus === 1) {
            if (props.errorDescription) {
                text = `${t("analytics.errors.registration-confirmation-sent-error")} (${props.errorDescription})`;
            } else {
                text = t("analytics.error.registration-confirmation-sent-error");
            }
        } else if (props.registrationConfirmationStatus === 2) {
            text = t("analytics.errors.registration-confirmation-not-correct-code")
        }
    }

    return (
        <div style={{color: 'red'}}>
            {text}
        </div>
    );
};

export default ProblemUserProblemTemplate;