import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSelectors";
import React, {useState} from "react";
import {Query} from "@syncfusion/ej2-data";
import {useTranslation} from "react-i18next";
import GridTab from "../../../ui/components/Grid/GridTab/GridTab";
import GridTabSection, {GridTabSectionType} from "../../../ui/components/Grid/GridTab/GridTabSection/GridTabSection";
import PermissionType from "../../../../data/enums/PermissionType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faRefresh} from "@fortawesome/free-solid-svg-icons";
import GridTabItem from "../../../ui/components/Grid/GridTab/GridTabItem/GridTabItem";
import Loader from "../../../ui/components/Loader/Loader";
import GridComponent from "../../../ui/components/Grid/GridComponent";
import {ColumnDirective, ColumnsDirective, Filter, Inject, Page, Sort} from "@syncfusion/ej2-react-grids";
import PostCategoryStateFilter from "./filters/PostCategoryStateFilter";
import PostCategoryMenuFilter from "./filters/PostCategoryMenuFilter";
import PostCategoryTypeFilter from "./filters/PostCategoryTypeFilter";
import PostCategoryMenuColumnTemplate from "./templates/PostCategoryMenuColumnTemplate";
import PostCategoryTypeColumnTemplate from "./templates/PostCategoryTypeColumnTemplate";
import {useNavigate} from "react-router-dom";

const PostCategory = () => {
    const user = useSelector(selectCurrentUser);
    const [query] = useState(new Query());
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [filterTrigger, setFilterTrigger] = useState(null);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const stateFilter = (props) => <PostCategoryStateFilter filterStoreEntityName={'category'} onFilterChange={onFilterChange} {...props} />;
    const menuFilter = (props) => <PostCategoryMenuFilter filterStoreEntityName={'category'} onFilterChange={onFilterChange} {...props}/>
    const typeFilter = (props) => <PostCategoryTypeFilter filterStoreEntityName={'category'} onFilterChange={onFilterChange} {...props}/>
    const menuColumnTemplate = (props) => (<div><PostCategoryMenuColumnTemplate {...props} /></div>);
    const typeColumnTemplate = (props) => (<div><PostCategoryTypeColumnTemplate {...props}/></div>);

    const onFilterChange = (ev, fieldName) => setFilterTrigger({
        ev,
        fieldName
    });

    const onTabItemClick = ({id}) => {
        if (id) {
            if (id === 'refresh-btn') {
                setRefreshTrigger(prev => prev + 1);
            }

            if (id === 'add-btn') {
                navigate(`/news/categories/category/`);
            }
        }
    }

    const onRowDoubleClick = ev => {
        if (ev && ev.rowData) {
            navigate(`/news/categories/category/${ev.rowData.id}`);
        }
    }

    return (
      <div className='list-container'>
          <GridTab onClick={onTabItemClick}>
              <GridTabSection type={GridTabSectionType.Button}>
                  {user.permission.newsSources === PermissionType.Editing
                      ? (
                          <GridTabItem id='add-btn'
                                       text={t("btn.add")}
                                       icon={<FontAwesomeIcon icon={faAdd}/>}>

                          </GridTabItem>
                      )
                      : null
                  }
                  <GridTabItem id='refresh-btn'
                               text={t("btn.refresh")}
                               icon={<FontAwesomeIcon icon={faRefresh}/>}>

                  </GridTabItem>
              </GridTabSection>
          </GridTab>
          {!query
              ? <Loader/>
              : <GridComponent id='grid'
                               container='.list-container'
                               url='/api/postCategory/getPostCategories'
                               query={query}
                               refresh={refreshTrigger}
                               filterStoreEntityName={'category'}
                               onRowClick={onRowDoubleClick}
                               filter={filterTrigger}>
                  <ColumnsDirective>
                      <ColumnDirective field='id'
                                       visible={false}
                                       textAlign="center"
                                       isPrimaryKey={true}
                                       type='number'/>
                      <ColumnDirective field='name'
                                       headerText={t("postCategory.grid.name")}
                                       width='200'
                                       textAlign="center"/>
                      <ColumnDirective field='postCategoryType'
                                       headerText={t("postCategory.grid.type")}
                                       width='150'
                                       textAlign="center"
                                       filterTemplate={typeFilter}
                                       template={typeColumnTemplate}/>
                      <ColumnDirective field='postCategoryMenu'
                                       headerText={t("postCategory.grid.menu")}
                                       width='150'
                                       textAlign="center"
                                       filterTemplate={menuFilter}
                                       template={menuColumnTemplate}/>
                      <ColumnDirective field='isBlocked'
                                       headerText={t("postCategory.grid.blocked")}
                                       width='150'
                                       displayAsCheckBox={true}
                                       textAlign="center"
                                       filterTemplate={stateFilter}/>
                  </ColumnsDirective>
                  <Inject services={[Page, Sort, Filter]}/>
              </GridComponent>
          }
      </div>
    );
}

export default PostCategory;