import React from 'react';
import moment from "moment/moment";

const UserLogDateTemplate = (props) => {
    if (!props[props.column.field]) {
        return null;
    }

    const date = moment(props[props.column.field] + '+00:00').local().format('DD.MM.YYYY HH:mm');

    return (
        <div>
            {date}
        </div>
    );
};

export default UserLogDateTemplate;