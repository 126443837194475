import React, {useState} from 'react';
import Dialog from "../../../../../ui/components/Dialog/Dialog";
import Button, {ButtonType} from "../../../../../ui/components/Button/Button";
import FormLayout from "../../../../../ui/layouts/FormLayout/FormLayout";
import {useTranslation} from "react-i18next";
import {useServerError} from "../../../../../../hooks/useServerError";
import cl from './SyncFromCrmConfirmationDialog.module.css';
import getServerErrorResourceName from "../../../../../../data/apiErrors/apiErrors";
import ResultCode from "../../../../../../data/enums/ResultCode";
import apiAxios from "../../../../../../app/api/apiAxios";
import {toast} from "react-toastify";

const SyncFromCrmConfirmationDialog = ({open, close, actionComplete, userId}) => {
    const {t} = useTranslation();
    const [serverError, setServerError] = useServerError(null);
    const [isLoading, setIsLoading] = useState(false);

    const submit = async () => {
        if (!userId) {
            setServerError("User id cannot be null");
        }
        else {

            try {
                setIsLoading(true);

                const response = await apiAxios.post(`${process.env.REACT_APP_BE_DOMAIN}/api/user/syncFromCrm`, {
                    id: userId
                });

                if (response) {
                    if (response.status === 200 && response.data) {
                        if (response.data.resultCode === ResultCode.Success) {
                            const timeOut = setTimeout(() => {
                                setIsLoading(false);

                                if (actionComplete) {
                                    toast.success(t("user.sync.sync-label"));

                                    actionComplete();
                                }

                                clearTimeout(timeOut);
                            }, 10000);
                        } else {
                            setServerError(t(getServerErrorResourceName({
                                model: 'user',
                                resultCode: response.data.resultCode,
                                description: response.data.description
                            })));

                            setIsLoading(false);
                        }
                    }
                }

            } catch (err) {
                if (err && err.message) {
                    setServerError(`${t(getServerErrorResourceName({
                        model: 'user',
                        resultCode: ResultCode.ServerError
                    }))} Message: (${err.message})`);
                }
                setIsLoading(false);
            }
        }
    }

    return (
        <Dialog width='400px'
                height='195px'
                open={open}>
            <FormLayout isLoading={isLoading}
                        header={(
                            <div className='header-form-layout'>
                                {t("user.sync.confirmation-label")}
                            </div>
                        )}
                        footer={
                            <div className='footer-form-layout'>
                                <Button buttonType={ButtonType.Warning}
                                        width='100%'
                                        onClick={() => close()}
                                        disabled={isLoading}>
                                    {t("btn.cancel")}
                                </Button>

                                <Button buttonType={ButtonType.Default}
                                        width='100%'
                                        onClick={submit}
                                        disabled={isLoading}>
                                    {t("user.btn.confirm")}
                                </Button>
                            </div>
                        }>
                <div className={cl.container}>
                    {serverError &&
                        <div className='server-error-container'>
                            <div>
                                {serverError}
                            </div>
                        </div>
                    }

                    <div className={cl.text}>
                        {t("user.sync.sync-from-confirmation-label")}
                    </div>
                </div>
            </FormLayout>
        </Dialog>
    );
};

export default SyncFromCrmConfirmationDialog;