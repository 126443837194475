import React, {useEffect, useState} from 'react';
import ActiveUsersChartSelectorSection from "./SelectorSection/ActiveUsersChartSelectorSection";
import ActiveUsersChartSection from "./ChartSection/ActiveUsersChartSection";
import moment from "moment/moment";
import apiAxios from "../../../../app/api/apiAxios";
import ProgressBarToast from "../../../ui/components/ProgressBarToast/ProgressBarToast";
import {useTranslation} from "react-i18next";
import {useDownloadFile} from "../../../../hooks/useDownloadFile";
import {toast} from "react-toastify";

const ActiveUsersChart = () => {
    const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [endDate, setEndDate] = useState(new Date());
    const [activityCode, setActivityCode] = useState('all');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [show, setShow] = useState(false);
    const {t} = useTranslation();

    const createDownloadFileRequest = () => {
        const date = JSON.stringify({
            sDate: `${moment(startDate).format("DD.MM.YYYY")} 00:00`,
            eDate: `${moment(endDate).format("DD.MM.YYYY")} 23:59`,
        });

        return apiAxios.get(`${process.env.REACT_APP_BE_DOMAIN}/api/analytics/getUsersActivityAnalyticsReport?createdOn=${date}&activityCode=${activityCode}`, {
            responseType: 'blob',
            onDownloadProgress(ev) {
                let progress = Math.round((ev.loaded / ev.total) * 100);
                if (progress) {
                    setPercentage(progress);
                }
            }
        });
    }

    const {download} = useDownloadFile({
        request: createDownloadFileRequest,
        beforeDownload: () => setShow(prev => !prev),
        afterDownload: () => {
            setShow(prev => !prev);

            const timeoutId = setTimeout(() => {
                setPercentage(0);

                clearTimeout(timeoutId);
            }, 1000);
        },
        actionFailure: (ev) => {
            if (ev && ev.response) {
                if (ev.response.status === 500) {
                    toast.error(t("errors.download-file-server-error"));
                } else if (ev.response.status === 404) {
                    toast.error(t("analytics.errors.download-file-error-not-found"));
                }
            }

            setPercentage(0);
            setShow(prev => !prev);
        },
        getFileName: () => {
            const dates = {
                sDate: `${moment(startDate).format("DD.MM.YYYY")}`,
                eDate: `${moment(endDate).format("DD.MM.YYYY")}`
            };
            return `Пользователи[Товарищество][${dates.sDate} - ${dates.eDate}].xlsx`
        }
    });

    const onIntervalChange = (ev) => {
        if (ev && ev.startDate && ev.endDate) {
            setStartDate(ev.startDate);

            setEndDate(ev.endDate);
        }
    }

    const onActivityCodeChange = (ev) => {
        if (ev && ev.itemData) {
            setActivityCode(ev.itemData.value);
        }
    }

    useEffect(() => {
        (async () => {
            setData([]);
            setIsLoading(true);

            const url = `${process.env.REACT_APP_BE_DOMAIN}/api/analytics/getUserActivityChartAnalytics`;
            const query = `?activityCode=${activityCode}&createdOn=${JSON.stringify({
                sDate: `${moment(startDate).format("DD.MM.YYYY")} 00:00`,
                eDate: `${moment(endDate).format("DD.MM.YYYY")} 23:59`,
            })}`;

            try {
                const response = await apiAxios.get(`${url}${query}`);

                if (response && response.status === 200 && response.data) {
                    setData(response.data);
                    setIsLoading(false);
                } else {
                    setData([]);
                    setIsLoading(false);
                }
            } catch (e) {
                setData([]);
                setIsLoading(false);
            }
        })();

    }, [activityCode, startDate, endDate])

    return (
        <div className='list-container'>
            <ProgressBarToast show={show} percentage={percentage}/>
            <ActiveUsersChartSelectorSection startDate={startDate}
                                             endDate={endDate}
                                             onActivityCodeChange={onActivityCodeChange}
                                             onDateChange={onIntervalChange}
                                             onExportBtnClick={download}
                                             isLoading={isLoading}/>
            <ActiveUsersChartSection startDate={startDate}
                                     endDate={endDate}
                                     activityCode={activityCode}
                                     data={data}
                                     isLoading={isLoading}/>
        </div>
    );
};

export default ActiveUsersChart;